import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, Input, Spin, List, Checkbox, Row, Col, Modal, message
} from 'antd'
import MyProficiencyForm from '../network/my-proficiency-form'
import RateExpertiseForm from '../network/rate-expertise-form'
import UserApi from '../../apis/user_api'

import { RESPONSE_STATUS_CODES, SERVICE_TYPE } from '../../utils/enum_config'
import { getActionByLogin } from '../../utils/auth'

const { Search } = Input

const userApi = new UserApi()

export default class AddSkills extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      visibleProficiency: false,
      visibleExpertise: false,
      data: [],
      SELECTED_GOAL: [],
      SELECTED_ABILITIES: [],
      SELECTED_SKILLS: null,
      loading: false,
      finalloading: false,
      ACTION_BY_ID: this.props.ACTION_BY_ID,
      ACTION_BY_TYPE: this.props.ACTION_BY_TYPE,
      SELECTED_INST_ID: this.props.SELECTED_INST_ID,
      SELECED_INST_ROLE_TYPE: this.props.SELECED_INST_ROLE_TYPE
    }

    this.next = this.next.bind(this)
    // this.selectGoal = this.selectGoal(this)
    // this.selectAbilities = this.selectAbilities(this)
  }

  componentDidMount = async () => {
    this.getExpertiseList('Skill', this.state.ACTION_BY_ID, '', 1, 1000)
  };

    getExpertiseList = async (EX_CODE, USERID, SEARCHVALUE, SKIP, OFFSET) => {
      this.setState({
        loading: true
      })
      const REQUEST = {
        expertise_category_code: EX_CODE,
        person_id: USERID,
        search_val: SEARCHVALUE,
        page_number: SKIP,
        page_size: OFFSET
      }
      const response = await await userApi.getExpertiseListApi(REQUEST)
      if (response) {
        if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
          this.setState({
            data: response.rows,
            loading: false
          })
        }
      }
    };

  onSearch = (e) => {
    this.getExpertiseList('Skill', this.state.ACTION_BY_ID, e.target.value, 1, 1000)
  }

  next () {
    this.setState({
      finalloading: true
    })
    this.showProficiency()
  }

  onChange = (e) => {
    this.setState({
      SELECTED_SKILLS: e.target.value
    })
  }

  showProficiency = () => {
    this.setState({
      visibleProficiency: true
    })
  }

  selectAbilities = (DATA) => {
    this.setState({
      SELECTED_ABILITIES: DATA.VALUE
    })
  }

  handleOk = () => {
    this.showExpertise()
  }

  handleCancel = () => {
    this.setState({
      visibleProficiency: false,
      finalloading: false
    })
  }

  selectGoal = (DATA) => {
    this.setState({
      SELECTED_GOAL: DATA.VALUE
    })
  }

  showExpertise = () => {
    this.setState({
      visibleExpertise: true
    })
  }

    handleOkExpertise = () => {
      this.addLanguage(this.state.SELECTED_INST_ID,
        this.state.ACTION_BY_ID,
        this.state.ACTION_BY_ID,
        this.state.SELECED_INST_ROLE_TYPE,
        this.prepareGoal(this.state.SELECTED_GOAL),
        this.prepareAbilities(this.state.SELECTED_ABILITIES),
        4,
        this.state.SELECTED_SKILLS
      )
    }

  handleCancelExpertise = () => {
    this.setState({
      visibleExpertise: false,
      finalloading: false
    })
  }

  prepareGoal (DATA) {
    const LIST_ITEM = []
    if (DATA) {
      if (DATA.length > 0) {
        DATA.forEach(element => {
          LIST_ITEM.push(element.expertise_goal_code)
        })
      }
    }
    return LIST_ITEM
  }

  prepareAbilities (DATA) {
    const LIST_ITEM = []
    if (DATA) {
      if (DATA.length > 0) {
        DATA.forEach(element => {
          LIST_ITEM.push(element.expertise_ability_code)
        })
      }
    }
    return LIST_ITEM
  }

    addLanguage = async (BUSNID, PERSONID, GIVENBYID, ROLETYPE, GOALS, ABILITIES, EX_CATEGORY, EX_CATEGORY_TYPE) => {
      const REQUEST = {
        id: null,
        business_id: getActionByLogin('B').id,
        person_id:getActionByLogin('U').id ,
        given_by_id: GIVENBYID,
        personType: ROLETYPE,
        goals: GOALS,
        abilities: ABILITIES,
        standard_expertise_category: EX_CATEGORY,
        standard_expertise_category_types: EX_CATEGORY_TYPE,
        is_selected: 'Y',
        self_rating: '0',
        average_rating: '0',
        number_of_ratings: '0'
      }
      // console.log(REQUEST)
      // return;
      const response = await await userApi.setExpertiseApi(REQUEST)
      if (response) {
        if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
          this.props.onSubmitForm(response.rows)
          this.setState({
            finalloading: false,
            visibleExpertise:false,
          })
        } else {
          message.error(response.message)
        }
      }
    }

    render () {
      return (
      <>
        <div>
          <Row gutter={16}>
            <Col span={20}>
              <Search placeholder="Search" onChange={this.onSearch} />
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              <Button
                disabled={this.state.SELECTED_SKILLS === null}
                type="link"
                style={{ marginRight: 8 }}
                onClick={this.handleOkExpertise}
                loading={this.state.finalloading}
              >
                Save
                        </Button>
            </Col>
          </Row>

          {
            this.state.loading
              ? <Spin style={{ paddingLeft: '50%', paddingTop: '10%' }} />
              : <List
                itemLayout="horizontal"
                dataSource={this.state.data}
                renderItem={item => (
                  <List.Item
                    actions={
                      [
                        <>
                          <Checkbox
                            value={item.id}
                            checked={this.state.SELECTED_SKILLS === item.id}
                            onChange={this.onChange}></Checkbox>
                        </>
                      ]}
                  >
                    <List.Item.Meta
                      title={item.expertise_type_code}
                      description={item.expertise_type_description}
                    />
                  </List.Item>
                )}
              />
          }

        </div>

        <Modal
          visible={this.state.visibleProficiency}
          closable={false}
          title="My Proficiency"
          onOk={this.handleOkExpertise}
          onCancel={this.handleCancel}
          okText='Submit'
        >
          <MyProficiencyForm
            RATING_TEXT={'Rate your Expertise'}
            EX_CAT_TYPE_ID={'Skill'}
            EX_CAT_NAME={null}
            EX_CAT_PID_VALUE={this.state.SELECTED_SKILLS}
            EX_CAT_ID={null}
            ACTION_BY_ID={this.state.ACTION_BY_ID}
            ACTION_BY_TYPE={this.state.ACTION_BY_TYPE}
            SUBJECT_ID={this.state.SELECTED_SKILLS}
            SUBJECT_TYPE={SERVICE_TYPE.SKILL}
            onSubmitForm={this.selectAbilities}
          />
        </Modal>

        <Modal
          visible={this.state.visibleExpertise}
          closable={false}
          title="Would you like to be"
          onOk={this.handleOkExpertise}
          onCancel={this.handleCancelExpertise}
          okText='Submit'
        >
          <RateExpertiseForm
            EX_CAT_TYPE_ID={this.state.SELECTED_SKILLS}
            EX_CAT_NAME={'Skill'}
            EX_CAT_PID_VALUE={null}
            EX_CAT_ID={null}
            ACTION_BY_ID={this.state.ACTION_BY_ID}
            ACTION_BY_TYPE={this.state.ACTION_BY_TYPE}
            SUBJECT_CLASS_ID={null}
            onSubmitForm={this.selectGoal}
          />
        </Modal>

      </>
      )
    }
}

AddSkills.protoTypes = {
  ACTION_BY_ID: PropTypes.any,
  ACTION_BY_TYPE: PropTypes.any,
  SELECTED_INST_ID: PropTypes.any,
  SELECED_INST_ROLE_TYPE: PropTypes.any
}
