import React from "react";
import { Spin } from "antd";
import { withGlobalSettingsWrapper } from "./GlobalSettingsWrapper";

const Loader = (props) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: props.themeItems.themeColors.black35,
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: "99999999999",
      }}
    >
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          top: parseInt((window.innerHeight / 3).toFixed()),
          left: parseInt((window.innerWidth / 2).toFixed()),
        }}
      >
        <Spin size="large" />
      </div>
    </div>
  );
};

export default withGlobalSettingsWrapper(Loader);
