import { Col, Image, Row, Typography } from "antd";

import Login from "../../../src/views/public/login";
import React from "react";
import { withGlobalSettingsWrapper } from "../common/GlobalSettingsWrapper";

const { Title } = Typography;

class Banner extends React.Component {
  render() {
    return (
      <>
        <Row
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "1280px",
            // display: 'flex'
          }}
        >
          <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }} style={{ padding: "64px 24px 24px" }}>
            <div style={{ borderRadius: "8px", textAlign: "left", padding: "24px" }}>
              <Title
                style={{ fontWeight: "400", color: this.props.themeItems.themeColors.black85, textAlign: "left" }}
                level={5}
              >
                Partner Workspace
              </Title>
              <Title
                style={{
                  fontWeight: "600",
                  fontSize: "48px",
                  color: this.props.themeItems.themeColors.black85,
                  textAlign: "left",
                  marginTop: "4px",
                }}
                level={1}
              >
                {/* Channel partners Collaboration and Training Platform */}
                Welcome {this.props.themeItems.companyAppName}
              </Title>
              {/* <Title style={{ fontWeight: '400', color: this.props.themeItems.themeColors.black85, textAlign: 'left' }}
                    level={4}
                  >
                    Digitising partner engagement</Title> */}
              <Title
                style={{
                  borderRadius: "8px",
                  fontWeight: "300",
                  color: this.props.themeItems.themeColors.black65,
                  textAlign: "left",
                }}
                level={5}
              >
                This is a <strong>partner workspace</strong> for {this.props.themeItems.companyAppName}, a
                super-simplified multi-lingual platform for channel partner{" "}
                <strong>collaboration, marketing, training and support</strong>.{" "}
              </Title>
              {/* The partner managers and the partners are connected seamlessly for all types of engagement in real time. */}
            </div>
            <div style={{ textAlign: "center", marginBottom: "60px" }}>
              <a
                href="https://play.google.com/store/apps/details?id=com.foop.foopworks"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  style={{
                    marginLeft: "auto",
                    marginRight: "12px",
                    display: "block",
                    marginTop: "24px",
                  }}
                  width={134}
                  preview={false}
                  loading="lazy"
                  src={`${process.env.PUBLIC_URL}/images/google-play-badge.png`}
                  alt="Foop-googleplay"
                />
              </a>
              <a href="https://apps.apple.com/us/app/foopworks/id1592677970" target="_blank" rel="noopener noreferrer">
                <Image
                  style={{
                    marginLeft: "12px",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "24px",
                  }}
                  width={120}
                  preview={false}
                  loading="lazy"
                  src={`${process.env.PUBLIC_URL}/images/App_Store_Badge.png`}
                  alt="Foop-appstore"
                />
              </a>
            </div>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }} style={{ maxWidth: "640px" }}>
            <div
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + "/images/home-background.png"})`,
                height: "600px",
                backgroundRepeat: "no-repeat",
                marginLeft: "12px",
                marginRight: "auto",
                paddingTop: "64px",
                display: "block",
                backgroundPosition: "bottom",
              }}
            >
              <Login />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default withGlobalSettingsWrapper(Banner);
