export const DOMAIN = process.env.REACT_APP_API_DOMAIN || "https://test.foop.com/";
export const BASIC_URL = `${DOMAIN}api/v1`;
export const BASIC_URL_V2 = `${DOMAIN}api/v2`;
export const BASIC_URL_V3 = `${DOMAIN}api/v3`;

export const REGISTRATION_MODULE = `${BASIC_URL}/rl`;

const PROFILE_MANAGEMENT_MODULE = `${BASIC_URL}/user`;
const BUSINESS_MANAGEMENT_MODULE = `${BASIC_URL}/business`;
const NETWORK_MODULE = `${BASIC_URL}/network`;
const RATING_REVIEW_MODULE = `${BASIC_URL}/rr`;
const STANDARD_DICTIONARIES_MODULE = `${BASIC_URL}/standard`;
const UTILITY_MODULE = `${BASIC_URL}/utility`;
const PRODUCTS_MODULE = `${BASIC_URL}/product`;
const REWARDS_MODULE = `${BASIC_URL}/reward`;
const INVITATION_MODULE = `${BASIC_URL}/invitation`;

const TEAM_MODULE = `${BUSINESS_MANAGEMENT_MODULE}/team`;
const BRANCH_MODULE = `${BUSINESS_MANAGEMENT_MODULE}/branch`;
const CAMPAIGN_MODULE = `${BUSINESS_MANAGEMENT_MODULE}/campaign`;

// v2
const BUSINESS_MANAGEMENT_MODULE_V2 = `${BASIC_URL_V2}/business`;
const UTILITY_MODULE_V2 = `${BASIC_URL_V2}/utility`;

// v3
const UTILITY_MODULE_V3 = `${BASIC_URL_V3}/utility`;

const BI_MODULE = `${BASIC_URL}/bi`;
const POST_MANAGEMENT_MODULE = `${BASIC_URL}/post`;
const DRIVE_MANAGEMENT_MODULE = `${BASIC_URL}/file`;
const HELPDESK_MODULE = `${BASIC_URL}/hd`;

export const CHAT_MODULE = process.env.REACT_APP_CHAT_DOMAIN || "https://test.chat.oho.works";
export const MAIL_DOMAIN = process.env.REACT_APP_MAIL_DOMAIN || "https://test.mail.oho.works/api/v1";
export const MEETING_MODULE = process.env.REACT_APP_MEET_DOMAIN || "https://test.meet.oho.works";
export const NEXGE_DOMAIN = process.env.REACT_APP_NEXGE_DOMAIN || "conf-signalling.nexge.com";
export const PARTNER_DOMAIN = process.env.REACT_APP_PARTNER_DOMAIN || "https://testpartner.foop.com";

class ApiConfig {
  verifyOtoUrl = `${REGISTRATION_MODULE}/`;

  forgotPasswordSendOtpUrl = `${REGISTRATION_MODULE}/forgot-password/`;

  createPasswordAfterOtpVerificationUrl = `${REGISTRATION_MODULE}/password-reset/`;

  resendOtpUrl = `${REGISTRATION_MODULE}/resend-otp/`;

  avatarUploadUrl = `${BASIC_URL}/avatar-upload/`;

  updateUserImageUrl = `${BASIC_URL}/file/update/`;

  updateUserBasicUrl = `${PROFILE_MANAGEMENT_MODULE}/profile/edit`;

  updateAddressUrl = `${PROFILE_MANAGEMENT_MODULE}/address/edit`;

  updateUserBasicUrlByAdmin = `${PROFILE_MANAGEMENT_MODULE}/profile/update`;

  registerUrl = `${BASIC_URL}/rl/register/`;

  loginUrl = `${REGISTRATION_MODULE}/login/`;

  logoutUrl = `${REGISTRATION_MODULE}/logout/`;

  meUrl = `${PROFILE_MANAGEMENT_MODULE}/profile/`;

  countriesUrl = `${REGISTRATION_MODULE}/countries/`;

  statesUrl = `${REGISTRATION_MODULE}/states/`;

  citiesUrl = `${REGISTRATION_MODULE}/cities/`;

  mediumsUrl = `${STANDARD_DICTIONARIES_MODULE}/mediums/list`;

  customUiUrl = `${BUSINESS_MANAGEMENT_MODULE}/ui/details`;

  mediumDetail = `${BUSINESS_MANAGEMENT_MODULE}/medium/detail`;

  businessMediumRegister = `${BUSINESS_MANAGEMENT_MODULE}/medium/register`;

  businessBasicDetails = `${BUSINESS_MANAGEMENT_MODULE}/detail`;

  businessContactDetails = `${BUSINESS_MANAGEMENT_MODULE}/contact/detail`;

  businessHourDetails = `${BUSINESS_MANAGEMENT_MODULE}/hours/detail`;

  businessLocationDetails = `${BUSINESS_MANAGEMENT_MODULE}/location/detail`;

  getFilteredLocationUrl = `${BUSINESS_MANAGEMENT_MODULE}/partner/addresss/entities`;

  registerBusinessBasic = `${BUSINESS_MANAGEMENT_MODULE}/basic/register`;

  editBusinessBasic = `${BUSINESS_MANAGEMENT_MODULE}/basic/edit`;

  registerBusinessLocation = `${BUSINESS_MANAGEMENT_MODULE}/location/register/`;

  registerBusinessPersonalise = `${BUSINESS_MANAGEMENT_MODULE}/personalise/register/`;

  registerBusinessContact = `${BUSINESS_MANAGEMENT_MODULE}/contact/register/`;

  editBusinessContact = `${BUSINESS_MANAGEMENT_MODULE}/contact/edit`;

  registerBusinessHour = `${BUSINESS_MANAGEMENT_MODULE}/hours/register`;

  editBusinessHour = `${BUSINESS_MANAGEMENT_MODULE}/hours/edit`;

  editBusinessLocation = `${BUSINESS_MANAGEMENT_MODULE}/location/edit`;

  businessMission = `${BUSINESS_MANAGEMENT_MODULE}/mission/register`;

  businessHistory = `${BUSINESS_MANAGEMENT_MODULE}/history/register`;

  addBusinessAuthority = `${BUSINESS_MANAGEMENT_MODULE}/authority/register`;

  businessPrincipalDetail = `${BUSINESS_MANAGEMENT_MODULE}/authority/principal/details`;

  editBusinessAuthority = `${BUSINESS_MANAGEMENT_MODULE}/authority/edit`;

  createBusinessCourseUrl = `${BUSINESS_MANAGEMENT_MODULE}/course/create`;

  updateBusinessCourseUrl = `${BUSINESS_MANAGEMENT_MODULE}/course/update`;

  getBusinessCourseList = `${BUSINESS_MANAGEMENT_MODULE}/course/list`;

  getCourseNameMasterUrl = `${STANDARD_DICTIONARIES_MODULE}/course/list`;

  getCourseTypeMasterUrl = `${STANDARD_DICTIONARIES_MODULE}/coursetype/list`;

  getProgramNameUrl = `${STANDARD_DICTIONARIES_MODULE}/program/list`;

  getDepartmentMasterUrl = `${STANDARD_DICTIONARIES_MODULE}/department/list`;

  getSubjectMasterUrl = `${STANDARD_DICTIONARIES_MODULE}/subject/list`;

  getCertificationMasterUrl = `${STANDARD_DICTIONARIES_MODULE}/certification/list`;

  getMediumOfEducationMasterUrl = `${STANDARD_DICTIONARIES_MODULE}/medium/list`;

  getClassMasterUrl = `${STANDARD_DICTIONARIES_MODULE}/class/list`;

  getStatusListApi = `${STANDARD_DICTIONARIES_MODULE}/dictionary/list`;

  getBusinessClassListUrl = `${BUSINESS_MANAGEMENT_MODULE}/class/list`;

  getBusinessCourseSubjectListUrl = `${BUSINESS_MANAGEMENT_MODULE}/subject/list`;

  getClassTypeUrl = `${STANDARD_DICTIONARIES_MODULE}/classtype/list`;

  getCourseListUrl = `${STANDARD_DICTIONARIES_MODULE}/course/list`;

  createBusinessClassUrl = `${BUSINESS_MANAGEMENT_MODULE}/class/create`;

  updateBusinessClassApi = `${BUSINESS_MANAGEMENT_MODULE}/class/update`;

  getSubjectCategoriesListUrl = `${STANDARD_DICTIONARIES_MODULE}/subjectcategories/list`;

  getCourseListYearListUrl = `${STANDARD_DICTIONARIES_MODULE}/courseyear/list`;

  createBusinessSubjectUrl = `${BUSINESS_MANAGEMENT_MODULE}/subject/create`;

  updateBusinessSubjectUrl = `${BUSINESS_MANAGEMENT_MODULE}/subject/update`;

  createBusinessAccreditationUrl = `${BUSINESS_MANAGEMENT_MODULE}/accreditation/create`;

  getBusinessAffiliationListUrl = `${BUSINESS_MANAGEMENT_MODULE}/accreditation/list`;

  getRecognitionTypeUrl = `${STANDARD_DICTIONARIES_MODULE}/recognitiontype/list`;

  getOrganizationTypeUrl = `${STANDARD_DICTIONARIES_MODULE}/organization/list`;

  updateBusinessAccreditationUrl = `${BUSINESS_MANAGEMENT_MODULE}/accreditation/update`;

  createBusinessClubApi = `${BUSINESS_MANAGEMENT_MODULE}/club/create`;

  updateBusinessClubUrl = `${BUSINESS_MANAGEMENT_MODULE}/club/update`;

  getClubsUrl = `${BUSINESS_MANAGEMENT_MODULE}/club/list`;

  getClubCategoryListUrl = `${STANDARD_DICTIONARIES_MODULE}/clubcategories/list`;

  getClubSubCategroyListApi = `${STANDARD_DICTIONARIES_MODULE}/clubsubcategories/list`;

  getGuruInchargeListApi = `${STANDARD_DICTIONARIES_MODULE}/clubsubcategories/list`;

  getFacilitiesTypeApi = `${STANDARD_DICTIONARIES_MODULE}/facilitytype/list`;

  createBusinessWorkFacilityUrl = `${BUSINESS_MANAGEMENT_MODULE}/facilities/create`;

  updateBusinessWorkFacilityUrl = `${BUSINESS_MANAGEMENT_MODULE}/facilities/update`;

  getBusinessWorkFacilityUrl = `${BUSINESS_MANAGEMENT_MODULE}/facilities/list`;

  getSportsListApi = `${BUSINESS_MANAGEMENT_MODULE}/sports/list`;

  getSportsCategoryListUrl = `${STANDARD_DICTIONARIES_MODULE}/sportscategories/list`;

  getSubSportsCategoryListUrl = `${STANDARD_DICTIONARIES_MODULE}/sportssubcategories/list`;

  createBusinessSportsUrl = `${BUSINESS_MANAGEMENT_MODULE}/sports/create`;

  updateBusinessSportsUrl = `${BUSINESS_MANAGEMENT_MODULE}/sports/update`;

  getGuruInchargeMasterListApi = `${BUSINESS_MANAGEMENT_MODULE}/user/list`;

  getBusinessAccountSettingsUrl = `${UTILITY_MODULE}/account/settings/view`;

  getBusinessPrivacySettingsUrl = `${UTILITY_MODULE}/privacy/settings/view`;

  getOhoLanguageMasterUrl = `${STANDARD_DICTIONARIES_MODULE}/applanguage/list`;

  getDateFormatMasterUrl = `${STANDARD_DICTIONARIES_MODULE}/dateformat/list`;

  getCurrencyMasterUrl = `${STANDARD_DICTIONARIES_MODULE}/currency/list`;

  updateBusinessAccountSettingsApi = `${UTILITY_MODULE}/account/settings/update`;

  updateBusinessPrivacySettingsUrl = `${UTILITY_MODULE}/privacy/settings/update`;

  getDashboardDetailsUrl = `${BI_MODULE}/dashboard/dashboard_details`;

  createDomainDetailsUrl = `${BUSINESS_MANAGEMENT_MODULE}/domain/create`;

  updateDomainDetailsUrl = `${BUSINESS_MANAGEMENT_MODULE}/domain/update`;

  getDomainDetailsUrl = `${BUSINESS_MANAGEMENT_MODULE}/domain/list`;

  getReviewListUrl = `${RATING_REVIEW_MODULE}/ratings/list`;

  getRatingSummaryUrl = `${RATING_REVIEW_MODULE}/ratings/summary`;

  getAbuseReportUrl = `${BI_MODULE}/views/abuse_report_listing`;

  updateReportStatusUrl = `${BI_MODULE}/views/abuse_report_action`;

  // getProgrammeListUrl = `${BUSINESS_MANAGEMENT_MODULE}/program/list`;
  getProgrammeListUrl = `${BUSINESS_MANAGEMENT_MODULE}/program/list`;

  getPartnerListUrl = `${BUSINESS_MANAGEMENT_MODULE_V2}/partner/list`;

  getPartnerEmployeesUrl = `${BUSINESS_MANAGEMENT_MODULE}/partneremployee/list`;

  getDepartmentListUrl = `${BUSINESS_MANAGEMENT_MODULE}/department/list`;

  updateBusinessProgrammeUrl = `${BUSINESS_MANAGEMENT_MODULE}/program/create`;

  updateBusinessDepartmentUrl = `${BUSINESS_MANAGEMENT_MODULE}/department/create`;

  getSubjectListUrl = `${BUSINESS_MANAGEMENT_MODULE}/subject/list`;

  updateSubjectUrl = `${BUSINESS_MANAGEMENT_MODULE}/subject/create`;

  getClassesListUrl = `${BUSINESS_MANAGEMENT_MODULE}/class/list`;

  getSectionListUrl = `${BUSINESS_MANAGEMENT_MODULE}/section/list`;

  getSectionStandardListUrl = `${STANDARD_DICTIONARIES_MODULE}/section/list`;

  updateBusinessSectionUrl = `${BUSINESS_MANAGEMENT_MODULE}/section/create`;

  getClassSectionListUrl = `${BUSINESS_MANAGEMENT_MODULE}/class/section/list`;

  /**
   * Rooms Module Url
   */
  createRoomUrl = `${BASIC_URL}/rooms/create`;

  searchMembersUrl = `${BASIC_URL}/rooms/member/select/list`;

  addMembersUrl = `${BASIC_URL}/rooms/member/add`;

  addMembersUrlV2 = `${BASIC_URL_V2}/rooms/member/add`;

  getRoomListUrl = `${BASIC_URL}/rooms/list`;

  getRoomDetailUrl = `${BASIC_URL}/rooms/view`;

  updateRoomUrl = `${BASIC_URL}/rooms/update`;

  getMemberListUrl = `${BASIC_URL}/rooms/member/list`;

  updateMemberStatusUrl = `${BASIC_URL}/rooms/membershipstatus/update`;

  updateRoomModeratorUrl = `${BASIC_URL}/rooms/memberrole/update`;

  getStandardUrl = `${BUSINESS_MANAGEMENT_MODULE}/user/list`;

  getTeamHierarchyUrl = `${TEAM_MODULE}/member/hierarchy/list`;

  selectTeamHierarchyUrl = `${TEAM_MODULE}/member/hierarchy/select/list`;

  addTeamHierarchyUrl = `${TEAM_MODULE}/member/hierarchy/add`;

  updateEmployeePermissionUrl = `${BUSINESS_MANAGEMENT_MODULE}/employee/permission/update`;

  // Upload Url
  uploadUrl = `${BASIC_URL}/file/upload/`;

  getViewProfileUrl = `${BUSINESS_MANAGEMENT_MODULE}/profile`;

  getCountsUrl = `${NETWORK_MODULE}/get-follower-count`;

  actionOnUrl = `${NETWORK_MODULE}/follow`;

  getFollowerUrl = `${NETWORK_MODULE}/get-followers-list`;

  getFolloweringUrl = `${NETWORK_MODULE}/get-following-list`;

  getIpAddressUrl = "https://api64.ipify.org?format=json";

  getNotificationListUrl = `${DOMAIN}notifications/getbellnotification`;

  getKeyWordUrl = `${STANDARD_DICTIONARIES_MODULE}/keyword/list`;

  getMentionUrl = `${UTILITY_MODULE}/mention/list`;

  /**
   * Invitation Module
   */
  getNotificationUpdateCountUrl = `${DOMAIN}notifications/updatebellnotificationcount`;

  getNotificationCountUrl = `${DOMAIN}notifications/getbellnotificationcount`;

  updateNotificationCountAndReadUrl = `${DOMAIN}notifications/updatebellnotificationcount`;

  setFcmIdUrl = `${NETWORK_MODULE}/contact/setfcmid`;

  /**
   * Feed and Post Management Urls
   */

  createPostUrl = `${POST_MANAGEMENT_MODULE}/create`;

  getPostListUrl = `${POST_MANAGEMENT_MODULE}/list`;

  getPostListPublicUrl = `${POST_MANAGEMENT_MODULE}/public/list`;

  getFaqPolicyListUrl = `${POST_MANAGEMENT_MODULE}/entity/list`;

  getPostDetailsUrl = `${POST_MANAGEMENT_MODULE}/view`;

  getPostDetailsPublicUrl = `${POST_MANAGEMENT_MODULE}/public/view`;

  updatePostUrl = `${POST_MANAGEMENT_MODULE}/update`;

  updateRecipientUrl = `${POST_MANAGEMENT_MODULE}/recipient/update`;

  createReportContentUrl = `${BI_MODULE}/reports/create`;

  createRatingUrl = `${RATING_REVIEW_MODULE}/ratings/create`;

  rateAsIssuerUrl = `${HELPDESK_MODULE}/ticket/rating/agent`;

  createCommentUrl = `${RATING_REVIEW_MODULE}/notes/create`;

  commentListUrl = `${RATING_REVIEW_MODULE}/notes/list`;

  answerListUrl = `${POST_MANAGEMENT_MODULE}/answer/list`;

  createAnswerUrl = `${POST_MANAGEMENT_MODULE}/answer/create`;

  getRecipienttypeListUrl = `${UTILITY_MODULE_V3}/recipienttype/list`;

  getEventRecipienttypeListUrl = `${UTILITY_MODULE_V3}/event/recipienttype/list`;

  getShareUrl = `${UTILITY_MODULE}/share/share_url`;

  getSuggestionUrl = `${UTILITY_MODULE}/suggestions/list`;

  refreshPostListUrl = `${POST_MANAGEMENT_MODULE}/refresh`;

  getHomePageContentUrl = `${UTILITY_MODULE}/home/`;

  getTopicsListUrl = `${STANDARD_DICTIONARIES_MODULE}/postsubtype/list`;

  /**
   * Invitation Module
   */

  inviteCreateUrl = `${BASIC_URL}/invitation/create`;

  inviteListUrl = `${BASIC_URL}/invitation/list`;

  getRequestListUrl = `${BUSINESS_MANAGEMENT_MODULE}/request/list`;

  updateRequestUrl = `${BUSINESS_MANAGEMENT_MODULE}/request/update`;

  /**
   * Request Call Back
   */

  dictionaryUrl = `${UTILITY_MODULE}/see_more/dictonary_list`;

  createRequestCallBackUrl = `${BI_MODULE}/call_back/create_mktg_all_enquiries`;

  getTermsConditionUrl = `${BASIC_URL}/pageutility/page/view`;

  /**
   * User Role Settings Api
   */

  AppyforBusinessAdminUrl = `${BUSINESS_MANAGEMENT_MODULE}/admin/apply_for_admin`;

  staffListUrl = `${BUSINESS_MANAGEMENT_MODULE}/admin/staff_list`;

  RolesListUrl = `${BUSINESS_MANAGEMENT_MODULE}/admin/drop_down_of_roles`;

  AssignRoleUrl = `${BUSINESS_MANAGEMENT_MODULE}/admin/assign_role_admin`;

  adminListUrl = `${BUSINESS_MANAGEMENT_MODULE}/admin/business_admin_list`;

  deleteRoleUrl = `${BUSINESS_MANAGEMENT_MODULE}/admin/delete_role_admin`;

  getUserRoleListApi = `${STANDARD_DICTIONARIES_MODULE}/persontype/list`;

  // Loyalty Settings Start
  getPartnerLoyaltyListApi = `${STANDARD_DICTIONARIES_MODULE}/loyaltytype/list`;

  updatePartnerLoyaltyListUrl = `${STANDARD_DICTIONARIES_MODULE}/loyaltytype/customize`;

  getLoyaltyProgramTypesUrl = `${STANDARD_DICTIONARIES_MODULE}/loyaltyprogramtype/list`;

  getLoyaltyRedemptionSettingsUrl = `${BUSINESS_MANAGEMENT_MODULE}/redemption/settings/view`;

  updateLoyaltyRedemptionSettingsUrl = `${BUSINESS_MANAGEMENT_MODULE}/redemption/settings/update`;

  createLoyaltyProgramUrl = `${BUSINESS_MANAGEMENT_MODULE}/loyalty/program/create`;

  editLoyaltyProgramUrl = `${BUSINESS_MANAGEMENT_MODULE}/loyalty/program/update`;

  getLoyaltyProgramsUrl = `${BUSINESS_MANAGEMENT_MODULE}/loyalty/program/list`;

  manageLoyaltyProgramUrl = `${BUSINESS_MANAGEMENT_MODULE}/loyalty/program/status/update`;

  // Loyalty Settings End

  getRechargeLedgerUrl = `${REWARDS_MODULE}/ledgerlist`;

  getBalanceDetailsUrl = `${REWARDS_MODULE}/balancedetails`;

  getMinimumRechargeUrl = `${BASIC_URL}/aaa/recharge/min/settings/view`;

  getSubscriptionDetailsUrl = `${BASIC_URL}/aaa/subscription/view`;

  getSocialMessageAmtUrl = `${BASIC_URL}/aaa/get/social/message/amount`;

  getApplicationSettingsListUrl = `${BASIC_URL}/aaa/application/settings/list`;

  getAccessDetailsUrl = `${BASIC_URL}/aaa/application/category/access/list`;

  listAppAccessUrl = `${BASIC_URL}/aaa/home/application/list`;

  manageAppMainUrl = `${BASIC_URL}/aaa/application/manage`;

  listCustomUsersUrl = `${BASIC_URL}/aaa/application/member/list`;

  addCustomUsersUrl = `${BASIC_URL}/aaa/application/member/add`;

  updateAppRoleUrl = `${BASIC_URL}/aaa/application/member/update/role`;

  removeAppPermissionUrl = `${BASIC_URL}/aaa/application/member/remove`;

  getAcademicYearsListUrl = `${STANDARD_DICTIONARIES_MODULE}/academicyear/list`;

  userRegisterUrl = `${BUSINESS_MANAGEMENT_MODULE}/user/register`;

  getBusinessListUrl = `${BUSINESS_MANAGEMENT_MODULE}/list`;

  getReferralDetailUrl = `${UTILITY_MODULE}/banner/referral_detail`;

  createReferralDetailUrl = `${UTILITY_MODULE}/banner/create_referral`;

  scratchCardAllocateUrl = `${BASIC_URL}/scratch/card/allocate`;

  openScratchCardUrl = `${BASIC_URL}/scratch/card/open`;

  /* Network Moduel */

  addEducationUrl = `${PROFILE_MANAGEMENT_MODULE}/add_education_work`;

  editEducationUrl = `${PROFILE_MANAGEMENT_MODULE}/edit_education`;

  getAbilitiesUrl = `${PROFILE_MANAGEMENT_MODULE}/expertise/standard_category_abilities`;

  getExpertiseUrl = `${PROFILE_MANAGEMENT_MODULE}/expertise/standard_expertise_category_goals_list`;

  getExpertiseListUrl = `${PROFILE_MANAGEMENT_MODULE}/expertise/expertise_list`;

  getSubjectExpertiseListUrl = `${PROFILE_MANAGEMENT_MODULE}/expertise/expertise_subject_list`;

  addSubjectUrl = `${PROFILE_MANAGEMENT_MODULE}/expertise/add_subject`;

  addClassUrl = `${PROFILE_MANAGEMENT_MODULE}/expertise/add_class`;

  setExpertiseUrl = `${PROFILE_MANAGEMENT_MODULE}/expertise/set_expertise`;

  getClassListUrl = `${PROFILE_MANAGEMENT_MODULE}/expertise/expertise_class_list`;

  getRatedListUrl = `${RATING_REVIEW_MODULE}/ratings/rated/list`;

  getEducationSeeMoreUrl = `${UTILITY_MODULE}/see_more/education_card_see_more_api`;

  getWorkSeeMoreUrl = `${UTILITY_MODULE}/see_more/work_card_see_more_api`;

  getSubjectSeeMoreUrl = `${UTILITY_MODULE}/see_more/subject_card_see_more_api`;

  getClassSeeMoreUrl = `${UTILITY_MODULE}/see_more/class_card_see_more_api`;

  getSeeMoreLanguageSkillsUrl = `${PROFILE_MANAGEMENT_MODULE}/expertise/get_user_expertise_list`;

  getTimelineListUrl = `${POST_MANAGEMENT_MODULE}/others/list`;

  getUserMediaListUrl = `${BASIC_URL}/file/media/list`;

  countryCodeListUrl = `${REGISTRATION_MODULE}/countrymobile/list/`;

  getPrivacyTypeListUrl = `${STANDARD_DICTIONARIES_MODULE}/roomprivacytype/list`;

  getRoomTopicsListUrl = `${STANDARD_DICTIONARIES_MODULE}/roomtopic/list`;

  getStandardTopicListUrl = `${STANDARD_DICTIONARIES_MODULE}/topic/list`;

  getStandardLearnerCategoryListUrl = `${STANDARD_DICTIONARIES_MODULE}/learner/category/list`;

  getStandardLessonListUrl = `${STANDARD_DICTIONARIES_MODULE}/lesson/list`;

  getPublicStandardLessonListUrl = `${STANDARD_DICTIONARIES_MODULE}/public/lesson/list`;

  addLessonUrl = `${STANDARD_DICTIONARIES_MODULE}/lesson/create`;

  getStandardChapterListUrl = `${STANDARD_DICTIONARIES_MODULE}/chapter/list`;

  getPublicChapterListUrl = `${STANDARD_DICTIONARIES_MODULE}/public/chapter/list`;

  createChapterUrl = `${STANDARD_DICTIONARIES_MODULE}/chapter/create`;

  getCohostListApi;

  getSearchHistoryUrl = `${BASIC_URL}/global/search/history`;

  getSearchUrl = `${BASIC_URL}/global/search`;

  changePasswordUrl = `${REGISTRATION_MODULE}/change-password/`;

  createChildDetailsUrl = `${BUSINESS_MANAGEMENT_MODULE}/child_details/create`;

  getBlockUserListUrl = `${NETWORK_MODULE}/get_block_list`;

  getConnectionListUrl = `${CHAT_MODULE}/api/v1/messenger/connections/listConnections`;

  getConversationListUrl = `${CHAT_MODULE}/api/v1/messenger/conversations/listConversations`;

  createConversationUrl = `${CHAT_MODULE}/api/v1/messenger/conversations/createConversation`;

  sendMessageUrl = `${CHAT_MODULE}/api/v1/messenger/conversations/createConversationMessage`;

  getMessageListUrl = `${CHAT_MODULE}/api/v1/messenger/conversations/listConversationMessages`;

  deleteMessageUrl = `${CHAT_MODULE}/api/v1/messenger/conversations/deleteConversationMessage`;

  getTotalUnreadMessageUrl = `${CHAT_MODULE}/api/v1/messenger/conversations/getTotalUnreadCount`;

  uploadMediaUrl = `${CHAT_MODULE}/api/v1/messenger/file/store/`;

  eventListUrl = `${BASIC_URL}/calendar/event/talk/list`;

  inviteUserUrl = `${BASIC_URL}/calendar/event/custom/invitee/add`;

  getUserListUrl = `${BASIC_URL}/utility/event/recipienttype/list`;

  updateAnswerUrl = `${POST_MANAGEMENT_MODULE}/answer/update`;

  createEventUrl = `${BASIC_URL}/calendar/event/create`;

  getInvitationLinkUrl = `${INVITATION_MODULE}/link`;

  sendInvitationLinkUrl = `${INVITATION_MODULE}/create`;

  inviteMembersListUrl = `${INVITATION_MODULE}/member/list`;

  addAccountDetailsUrl = `${BUSINESS_MANAGEMENT_MODULE}/accounting/register`;

  editAccountDetailsUrl = `${BUSINESS_MANAGEMENT_MODULE}/accounting/edit`;

  getDomainListUrl = `${MAIL_DOMAIN}/email/domain/list`;

  getEmailAccountListUrl = `${MAIL_DOMAIN}/email/user/list`;

  getGlobalUserListUrl = `${MAIL_DOMAIN}/email/user/global/list`;

  createEmailUserUrl = `${MAIL_DOMAIN}/email/user/create`;

  checkEmailExistUrl = `${MAIL_DOMAIN}/email/user/exists`;

  createDomainEmailUrl = `${MAIL_DOMAIN}/email/domain/create`;

  deleteUserUrl = `${MAIL_DOMAIN}/email/user/delete`;

  createFolderUrl = `${DRIVE_MANAGEMENT_MODULE}/folder/create`;

  renameFolderUrl = `${DRIVE_MANAGEMENT_MODULE}/folder/rename`;

  deleteFolderUrl = `${DRIVE_MANAGEMENT_MODULE}/folder/delete`;

  deleteFileUrl = `${DRIVE_MANAGEMENT_MODULE}/delete`;

  moveFolderUrl = `${DRIVE_MANAGEMENT_MODULE}/folder/move`;

  myListFolderUrl = `${DRIVE_MANAGEMENT_MODULE}/folder/my/list`;

  getShareWithMeListUrl = `${DRIVE_MANAGEMENT_MODULE}/folder/share/list`;

  shareListUrl = `${DRIVE_MANAGEMENT_MODULE}/folder/share/select/list`;

  uploadDriveFileUrl = `${DRIVE_MANAGEMENT_MODULE}/folder/upload`;

  getFilesDataInBinaryUrl = `${DRIVE_MANAGEMENT_MODULE}/folder/download`;

  getShareUserListUrl = `${DRIVE_MANAGEMENT_MODULE}/folder/share/user/list`;

  fileShareUrl = `${DRIVE_MANAGEMENT_MODULE}/folder/share/create/new`;

  fileUnshareUrl = `${DRIVE_MANAGEMENT_MODULE}/folder/share/create`;

  getQuoteStorageUrl = `${DRIVE_MANAGEMENT_MODULE}/folder/quota/get`;

  updatePartnerRoleUrl = `${BUSINESS_MANAGEMENT_MODULE}/partner/role/update`;

  updatePartnerLoyaltyUrl = `${BUSINESS_MANAGEMENT_MODULE}/partner/loyalty/update`;

  updatePartnerIdUrl = `${BUSINESS_MANAGEMENT_MODULE}/partner/id/update`;

  removePartnerUrl = `${BUSINESS_MANAGEMENT_MODULE}/partner/remove`;

  getPartnerManagerUrl = `${BUSINESS_MANAGEMENT_MODULE}/partner/employee/list`;

  updateAssignPartnerManagerUrl = `${BUSINESS_MANAGEMENT_MODULE}/partner/employee/assign`;

  getPartnerHierarchyUrl = `${BUSINESS_MANAGEMENT_MODULE}/partner/hierarchy/assigned/list`;

  getOtherHierarchyUrl = `${BUSINESS_MANAGEMENT_MODULE}/partner/hierarchy/select/list`;

  changeHierarchyUrl = `${BUSINESS_MANAGEMENT_MODULE}/partner/hierarchy/change`;

  addLowerPartnersUrl = `${BUSINESS_MANAGEMENT_MODULE}/partner/hierarchy/add`;

  listTeamPartnerManagersUrl = `${BUSINESS_MANAGEMENT_MODULE}/partner/employee/assign/list`;

  listTeamPartnersUrl = `${BUSINESS_MANAGEMENT_MODULE}/partner/select/list`;

  assignTeamPartnerUrl = `${BUSINESS_MANAGEMENT_MODULE_V2}/partner/employee/assign`;

  removeEmployeeUrl = `${BUSINESS_MANAGEMENT_MODULE}/employee/remove`;

  getEmployeeIdUrl = `${BUSINESS_MANAGEMENT_MODULE}/employee/id/get`;

  updateEmployeeIdUrl = `${BUSINESS_MANAGEMENT_MODULE}/employee/id/update`;

  // Helpdesk

  getHelpdeskSettingsUrl = `${HELPDESK_MODULE}/ticket/master/list`;

  setHelpdeskSettingsUrl = `${HELPDESK_MODULE}/ticket/master/addorupdate`;

  getHelpdeskAgentsListUrl = `${HELPDESK_MODULE}/agent/list`;

  getHelpdeskAgentCodeUrl = `${HELPDESK_MODULE}/agent/code/get`;

  addHelpdeskAgentUrl = `${HELPDESK_MODULE}/agent/add`;

  removeHelpdeskAgentUrl = `${HELPDESK_MODULE}/agent/remove`;

  getDashDataUrl = `${HELPDESK_MODULE}/dashboard`;

  createHdTicketUrl = `${HELPDESK_MODULE}/ticket/create`;

  fetchHdTicketDetailsUrl = `${HELPDESK_MODULE}/ticket/view`;

  getTicketsListUrl = `${HELPDESK_MODULE}/ticket/list`;

  getTicketMessagesUrl = `${HELPDESK_MODULE}/ticket/comments/list`;

  updateTicketStatusUrl = `${HELPDESK_MODULE}/ticket/comments/status/update`;

  getTotalUnreadTicketsUrl = `${HELPDESK_MODULE}/ticket/comments/unread/count`;

  getAgentsListUrl = `${HELPDESK_MODULE}/ticket/agent/list`;

  // Products URL list
  getMasterTaxUrl = `${PRODUCTS_MODULE}/master/tax/list`;

  getBusinessTaxUrl = `${PRODUCTS_MODULE}/entity/tax/list`;

  manageTaxUrl = `${PRODUCTS_MODULE}/entity/tax/add`;

  getMasterUomUrl = `${PRODUCTS_MODULE}/master/uom/list`;

  getBusinessUomUrl = `${PRODUCTS_MODULE}/entity/uom/list`;

  manageUomUrl = `${PRODUCTS_MODULE}/entity/uom/add`;

  generateAttributeCodeUrl = `${PRODUCTS_MODULE}/attribute/id/generate`;

  addCategoryUrl = `${PRODUCTS_MODULE}/entity/category/add`;

  listCategoryUrl = `${PRODUCTS_MODULE}/entity/category/list`;

  manageCategoryUrl = `${PRODUCTS_MODULE}/entity/category/remove`;

  addBrandUrl = `${PRODUCTS_MODULE}/entity/brand/add`;

  listBrandUrl = `${PRODUCTS_MODULE}/entity/brand/list`;

  manageBrandUrl = `${PRODUCTS_MODULE}/entity/brand/remove`;

  createProductUrl = `${PRODUCTS_MODULE}/create`;

  listProductUrl = `${PRODUCTS_MODULE}/list`;

  updateProductStatusUrl = `${PRODUCTS_MODULE}/status/update`;

  createCatalogUrl = `${PRODUCTS_MODULE}/catalog/create`;

  listCatalogUrl = `${PRODUCTS_MODULE}/catalog/list`;

  manageCatalogUrl = `${PRODUCTS_MODULE}/catalog/remove`;

  addBrandProductUrl = `${PRODUCTS_MODULE}/brand/add`;

  addCategoryProductUrl = `${PRODUCTS_MODULE}/category/add`;

  addCatalogProductUrl = `${PRODUCTS_MODULE}/catalog/map`;

  removeBrandProductUrl = `${PRODUCTS_MODULE}/brand/remove`;

  removeCategoryProductUrl = `${PRODUCTS_MODULE}/category/remove`;

  removeCatalogProductUrl = `${PRODUCTS_MODULE}/catalog/map/remove`;

  changeBrandUrl = `${PRODUCTS_MODULE}/brand/change`;

  changeCategoryUrl = `${PRODUCTS_MODULE}/category/change`;

  addCatalogAudienceUrl = `${PRODUCTS_MODULE}/catalog/audience/add`;

  removeCatalogAudienceUrl = `${PRODUCTS_MODULE}/catalog/audience/remove`;

  listCatalogAudienceUrl = `${PRODUCTS_MODULE}/catalog/audience/list`;

  // FAQ and Policy APIs
  lisFaqPolicyCategoryUrl = `${POST_MANAGEMENT_MODULE}/entity/category/master/list`;

  addFaqPolicyCategoryUrl = `${POST_MANAGEMENT_MODULE}/entity/category/master/add`;

  manageFaqPolicyCategoryUrl = `${POST_MANAGEMENT_MODULE}/entity/category/master/remove`;

  // Branches APIs
  getBranchListUrl = `${BRANCH_MODULE}/list`;

  registerBranchUrl = `${BRANCH_MODULE}/register`;

  editBranchUrl = `${BRANCH_MODULE}/edit`;

  setHeadOfficeUrl = `${BRANCH_MODULE}/headoffice/set`;

  setBranchHeadUrl = `${BRANCH_MODULE}/headoffice/set`;

  manageBranchEmployeesUrl = `${BRANCH_MODULE}/employee/map`;

  manageBranchPartnersUrl = `${BRANCH_MODULE}/partner/map`;

  changeEmployeeBranchUrl = `${BRANCH_MODULE}/employee/change`;

  changePartnerBranchUrl = `${BRANCH_MODULE}/partner/change`;

  // Campaigns APIs
  generateCampaignCodeUrl = `${CAMPAIGN_MODULE}/id/get`;

  createCampaignUrl = `${CAMPAIGN_MODULE}/create`;

  updateCampaignUrl = `${CAMPAIGN_MODULE}/update`;

  listCampaignUrl = `${CAMPAIGN_MODULE}/list`;

  listMyCampaignUrl = `${CAMPAIGN_MODULE}/my/list `;

  viewCampaignUrl = `${CAMPAIGN_MODULE}/my/view`;

  fetchCampaignStatsUrl = `${BI_MODULE}/campaign/receivers/count`;

  fetchCampaignLeaderboardUrl = `${BI_MODULE}/campaign/leaderboard`;

  fetchMyLeaderboardDataUrl = `${BI_MODULE}/campaign/my/leaderboard`;

  campaignInterestUrl = `${CAMPAIGN_MODULE}/receiver/response/update`;

  updateCampaignStatusUrl = `${CAMPAIGN_MODULE}/status/update`;

  listCampaignReceiversUrl = `${CAMPAIGN_MODULE}/receivers/list`;

  createSelectOptionsUrl = `${CAMPAIGN_MODULE}/entity/create`;

  listSelectOptionsUrl = `${CAMPAIGN_MODULE}/entity/list`;

  listCampaignReceiversTypeUrl = `${CAMPAIGN_MODULE}/receivertype/list`;

  fetchTargetsSheetUrl = `${BASIC_URL}/file/campaign/document/download`;

  uploadTargetsSheetUrl = `${CAMPAIGN_MODULE}/receivers/map`;
}

export default ApiConfig;
