import { Button, Card, Drawer, List, Typography } from "antd";
import { EditOutlined, PlusCircleFilled } from "@ant-design/icons";

import { RESPONSE_STATUS_CODES } from "../../utils/enum_config";
import React from "react";
import UserApi from "../../apis/user_api";
import WorkForm from "../../components/network/work-form";
import { getActionByLogin } from "../../utils/auth";
import { withGlobalSettingsWrapper } from "../common/GlobalSettingsWrapper";

const { Title } = Typography;

const userApi = new UserApi();

class WorkCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      visibleAdd: false,
      visibleEdit: false,
      visibleSeeMoreList: false,
      editData: null,
    };
  }

  showDrawerEdit = (element) => {
    if (element) {
      this.setState({
        visibleAdd: true,
        editData: this.prepareEdit(element),
      });
    } else {
      this.setState({
        visibleAdd: true,
      });
    }
  };

  prepareEdit(ITEM) {
    const REQUEST = {
      id: ITEM.id,
      business_id: ITEM.textSeven ? ITEM.textSeven : null,
      business_name: ITEM.textThree !== "None" ? ITEM.textThree : null,
      is_current: ITEM.is_current,
      start_date: ITEM.textFive !== "None" ? ITEM.textFive : null,
      end_date: ITEM.textSix !== "None" ? ITEM.textSix : null,
      description: ITEM.textFour !== "None" ? ITEM.textFour : null,
      add_type: "work",
      industry_type: ITEM.textNine !== "None" ? ITEM.textNine : null,
      employment_type: ITEM.textEight !== "None" ? ITEM.textEight : null,
      designation: ITEM.textOne !== "None" ? ITEM.textOne : null,
      location: ITEM.textTen !== "None" ? ITEM.textTen : null,
      person_id: null,
      class_name: null,
      grade: null,
      class_id: null,
      school_name: null,
      field_of_study: null,
    };
    return REQUEST;
  }

  onClose = () => {
    this.setState({
      visibleAdd: false,
    });
    window.location.reload();
  };

  showSeeMore = () => {
    this.setState({
      visibleSeeMoreList: true,
    });
    this.getSeeMoreList(this.props.VIEWID, this.props.VIEWTYPE, this.props.DETAILS.textSeven, 1, 100);
  };

  onSeeMoreClose = () => {
    this.setState({
      visibleSeeMoreList: false,
    });
  };

  getSeeMoreList = async (ACTIONBYID, ACTIONTYPE, INST_ID, SKIP, OFFSET) => {
    this.setState({
      loading: true,
    });
    const REQUEST = {
      person_id: ACTIONBYID,
      type: ACTIONTYPE,
      all_businesss_id: INST_ID,
      given_by_id: ACTIONBYID,
      page_number: SKIP,
      page_size: OFFSET,
    };
    const response = await userApi.getWorkSeeMoreApi(REQUEST);
    if (response) {
      if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
        this.setState({
          data: response.rows,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    }
  };

  getActionOnCard() {
    if (Number(this.props.VIEWID) === getActionByLogin("U").id) {
      return [
        <Button type="text" key="seeMoreBtn" onClick={() => this.showSeeMore()}>
          See More
        </Button>,
      ];
      // <Button type="text" onClick={() => this.showDrawerEdit()} icon={<PlusCircleFilled style={{ fontSize: 20, color: this.props.themeItems.themeColors.btnBlue }} />} />
    } else {
      if (this.props.DETAILS.isShowMore) {
        return [
          <Button type="text" key="detailsSeeMoreBtn" onClick={() => this.showSeeMore()}>
            See More
          </Button>,
        ];
      } else {
        return [];
      }
    }
  }

  getList = (DATA) => {
    if (DATA) {
      return (
        <List
          itemLayout="horizontal"
          loading={this.state.loading}
          dataSource={DATA}
          renderItem={(item) => (
            <List.Item
              actions={
                [
                  // Number(this.props.VIEWID) === getActionByLogin('U').id ? <EditOutlined onClick={() => this.showDrawerEdit(item)} /> : <></>
                ]
              }
            >
              <List.Item.Meta
                title={item.textOne !== "None" && item.textOne ? item.textOne : ""}
                description={item.textTwo !== "None" && item.textTwo ? item.textTwo : ""}
              />
              <div>{item.textThree !== "None" && item.textThree ? item.textThree : ""}</div>
            </List.Item>
          )}
        />
      );
    } else {
      return <></>;
    }
  };

  render() {
    return (
      <>
        <Card
          title={this.props.DETAILS ? <Title level={5}>{this.props.DETAILS.title}</Title> : ""}
          extra={this.getActionOnCard()}
        >
          <List
            dataSource={[this.props.DETAILS]}
            renderItem={(item, i) => (
              <List.Item key={i}>
                <List.Item.Meta
                  title={item.textOne !== "None" && item.textOne ? item.textOne : ""}
                  description={item.textThree !== "None" && item.textThree ? item.textThree : ""}
                />
              </List.Item>
            )}
          />
        </Card>

        <Drawer
          title="Add/Edit work"
          width={720}
          onClose={this.onClose}
          visible={this.state.visibleAdd}
          bodyStyle={{ paddingBottom: 80 }}
          maskClosable={false}
          destroyOnClose
        >
          <WorkForm onSubmitForm={this.onClose} editData={this.state.editData} />
        </Drawer>

        <Drawer
          title="Work"
          width={720}
          onClose={this.onSeeMoreClose}
          visible={this.state.visibleSeeMoreList}
          bodyStyle={{ paddingBottom: 80 }}
          maskClosable={false}
          destroyOnClose
        >
          {this.getList(this.state.data)}
        </Drawer>
      </>
    );
  }
}
export default withGlobalSettingsWrapper(WorkCard);
