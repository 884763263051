import { Button, Card, Col, Drawer, List, Row, Typography } from "antd";

import AboutCard from "./about-card";
import EditUserBasic from "../../components/network/edit-user-basic";
import { PlusCircleFilled } from "@ant-design/icons";
import { RESPONSE_STATUS_CODES } from "../../utils/enum_config";
import React from "react";
import UserApi from "../../apis/user_api";
import { getActionByLogin } from "../../utils/auth";
import moment from "moment";
import { withGlobalSettingsWrapper } from "../common/GlobalSettingsWrapper";

const { Paragraph, Title, Text } = Typography;

const userApi = new UserApi();

class Biocard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleEdit: false,
      editData: null,
    };
  }

  showDrawerEdit = () => {
    this.getUserDetailsApi();
  };

  onClose = () => {
    this.setState({
      visibleEdit: false,
    });
  };

  getUserDetailsApi = async () => {
    const response = await userApi.me();
    if (response) {
      if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
        this.setState({
          editData: response.rows,
          visibleEdit: true,
        });
      }
    }
  };

  getActionOnCard(ITEM) {
    if (Number(this.props.VIEWID) === getActionByLogin("U").id) {
      return (
        <Button
          type="text"
          onClick={() => this.showDrawerEdit()}
          icon={<PlusCircleFilled style={{ fontSize: 20, color: this.props.themeItems.themeColors.btnBlue }} />}
        />
      );
    } else {
      return [];
    }
  }

  getFormatedDate = (dateString) => {
    const newDate = moment(dateString).format("MMMM  Do, YYYY");
    return newDate;
  };

  render() {
    return (
      <>
        {/* <Card title= {this.props.DETAILS ? <Title level={5}>{this.props.DETAILS.title}</Title> : ''} extra={
          this.getActionOnCard(this.props.DETAILS)
        }>
          <Paragraph style>
           
          </Paragraph>
        </Card> */}

        {/* <Card
                    title={this.props.DETAILS ? <Title level={5}>{this.props.DETAILS.title}</Title> : ''}
                    extra={
                      this.getActionOnCard(this.props.DETAILS)}> */}
        <Card title={this.props.DETAILS ? <Title level={5}>{this.props.DETAILS.title}</Title> : ""}>
          <Row gutter={12}>
            <Col span={12}>
              <List
                dataSource={[this.props.DETAILS]}
                renderItem={(item, i) => (
                  <List.Item key={i}>
                    <List.Item.Meta title={item.textOne} description={item.textTwo || "-"} />
                  </List.Item>
                )}
              />
            </Col>
            <Col span={12}>
              <List
                dataSource={[this.props.DETAILS]}
                renderItem={(item, i) => (
                  <List.Item key={i}>
                    <List.Item.Meta title={item.textThree} description={item.textFour || "-"} />
                  </List.Item>
                )}
              />
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <List
                dataSource={[this.props.DETAILS]}
                renderItem={(item, i) => (
                  <List.Item key={i}>
                    <List.Item.Meta title={item.textFive} description={item.textSix || "-"} />
                  </List.Item>
                )}
              />
            </Col>
            <Col span={12}>
              <List
                dataSource={[this.props.DETAILS]}
                renderItem={(item, i) => (
                  <List.Item key={i}>
                    <List.Item.Meta
                      title={item.textSeven}
                      description={
                        item.textSeven === "Date of Joining" && item.textEight
                          ? this.getFormatedDate(item.textEight)
                          : item.textEight || "-"
                      }
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={24}>
              <Text>{this.props.DETAILS ? this.props.DETAILS.textNine : ""}</Text>
            </Col>
          </Row>
        </Card>

        <Drawer
          title="Basic"
          width={720}
          onClose={this.onClose}
          visible={this.state.visibleEdit}
          bodyStyle={{ paddingBottom: 80 }}
          maskClosable={false}
          destroyOnClose
        >
          <EditUserBasic onSubmitForm={this.onClose} editData={this.state.editData} />
        </Drawer>
      </>
    );
  }
}

export default withGlobalSettingsWrapper(Biocard);
