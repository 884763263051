import { Avatar, Button, Card, Col, Drawer, List, Modal, Row, Statistic, message } from "antd";
import { Link, withRouter } from "react-router-dom";
import { RESOLUTION_TYPE, RESPONSE_STATUS_CODES, SERVICE_TYPE } from "../../utils/enum_config";
import { getActionByLogin, setUser } from "../../utils/auth";

import BusinessApi from "../../apis/business_api";
import EditUserBasic from "../../components/network/edit-user-basic";
import { EventEmitter } from "../../events";
import FollowerList from "../functions/follower-list";
import React from "react";
import SocialFollow from "../functions/follow";
import UserApi from "../../apis/user_api";

const userApi = new UserApi();
const businessApi = new BusinessApi();

class Profilecard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      countListType: null,
      followerCount: 0,
      followingCount: 0,
      post_count: 0,
      cycleCount: 0,
      roomCount: 0,

      visibleEdit: false,
      editData: null,
    };

    this.history = props.history;
  }

  componentDidMount() {
    this.getCounts(this.props.VIEWTYPE, this.props.VIEWID);

    EventEmitter.subscribe("updateDetailCounts", () => {
      this.getCounts(this.props.VIEWTYPE, this.props.VIEWID);
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.VIEWID !== prevProps.VIEWID) {
      this.getCounts(this.props.VIEWTYPE, this.props.VIEWID);
    }
  }

  handleOk = () => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  getCounts = async (TYPE, ID) => {
    const REQUEST = { object_type: TYPE, object_id: ID };
    const response = await businessApi.getCountsApi(REQUEST);
    if (response) {
      if (response.rows) {
        this.setState({
          followerCount: response.rows.followers_count,
          followingCount: response.rows.following_count,
          cycleCount: response.rows.cycle_count,
          roomCount: response.rows.rooms_count,
          post_count: response.rows.post_count,
        });
      }
    }
  };

  openCountModal(FLAG) {
    if (FLAG === "F") {
      this.setState({
        visible: true,
        countListType: FLAG,
      });
    } else if (FLAG === "U") {
      this.setState({
        visible: true,
        countListType: FLAG,
      });
    }
  }

  onActionSubmit = () => {
    window.location.reload();
  };

  showDrawerEdit = () => {
    this.getUserDetailsApi();
  };

  getUserDetailsApi = async () => {
    const response = await userApi.me();
    if (response) {
      if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
        setUser(response.rows);
        this.setState({
          editData: response.rows,
          visibleEdit: true,
        });
      }
    }
  };

  onClose = () => {
    this.setState({
      visibleEdit: false,
    });
    window.location.reload();
  };

  getActionOnCard(ITEM) {
    if (Number(this.props.VIEWID) === getActionByLogin("U").id) {
      return (
        <Button type="primary" onClick={() => this.showDrawerEdit()}>
          Edit
        </Button>
      );
    } else {
      return [
        <SocialFollow
          key="followUser"
          buttonType="link"
          actionById={getActionByLogin("U").id}
          actionByType="person"
          actionOnId={this.props.VIEWID}
          actionOnType={this.props.VIEWTYPE}
          engageFlag={ITEM.is_following ? "U" : "F"}
          actionOnName={ITEM.name}
          actionSubmit={this.onActionSubmit}
        />,
        this.props.VIEWTYPE !== SERVICE_TYPE.BUSINESS && (
          <SocialFollow
            key="blockUser"
            buttonType="link"
            actionById={getActionByLogin("U").id}
            actionByType="person"
            actionOnId={this.props.VIEWID}
            actionOnType={this.props.VIEWTYPE}
            engageFlag={ITEM.is_blocked ? "N" : "B"}
            actionOnName={ITEM.name}
            actionSubmit={this.onActionSubmit}
          />
        ),
      ];
    }
  }

  render() {
    return (
      <>
        <Card>
          <List
            dataSource={[this.props.DETAILS]}
            renderItem={(item) => (
              <List.Item key={item.user_id} actions={this.getActionOnCard(item)}>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      size={72}
                      src={businessApi.getUrlForImage(item.image, RESOLUTION_TYPE.SIXFOUR, SERVICE_TYPE.PERSON)}
                    />
                  }
                  title={
                    <>
                      {item.name}
                      {item.is_user_verified ? (
                        <>
                          {" "}
                          <img src={`${process.env.PUBLIC_URL}/images/check.png`} alt="is_user_verified" width="15px" />
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  }
                  description={item.user_name}
                />
              </List.Item>
            )}
          />

          <Row gutter={16} justify="space-around">
            <Col
              style={{ cursor: "pointer", textAlign: "center" }}
              onClick={() => this.state.followingCount > 0 && this.openCountModal("U")}
            >
              <Statistic title="Following" value={this.state.followingCount} />
            </Col>
            <Col
              style={{ cursor: "pointer", textAlign: "center" }}
              onClick={() => this.state.followerCount > 0 && this.openCountModal("F")}
            >
              <Statistic title="Followers" value={this.state.followerCount} />
            </Col>
            {!this.props.match.path.split("/").includes("company") && (
              <>
                <Col
                  style={{ textAlign: "center", cursor: "pointer" }}
                  onClick={() => {
                    if (this.state.post_count > 0)
                      this.history.push(`/en-in/feed/general/activity/person/${this.props.VIEWID}`);
                    else message.warn("No posts to show!");
                  }}
                >
                  <Statistic title="Posts" value={this.state.post_count} />
                </Col>
                {/* <Col span={6}>
                  <Link to={"/en-in/rooms"}>
                    <Statistic title="Rooms" value={this.state.roomCount} />
                  </Link>
                </Col> */}
              </>
            )}
          </Row>
        </Card>

        <Modal
          visible={this.state.visible}
          title={this.state.countListType === "F" ? "Followers" : "Following"}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose
        >
          <FollowerList
            history={this.props.history}
            LIST_TYPE={this.state.countListType}
            OB_ID={this.props.VIEWID}
            OB_TYPE={this.props.VIEWTYPE}
          />
        </Modal>

        <Drawer
          title="Basic"
          width={720}
          onClose={this.onClose}
          visible={this.state.visibleEdit}
          bodyStyle={{ paddingBottom: 80 }}
          maskClosable={false}
          destroyOnClose
        >
          <EditUserBasic onSubmitForm={this.onClose} editData={this.state.editData} />
        </Drawer>
      </>
    );
  }
}
export default withRouter(Profilecard);
