import { Button, Card, Col, DatePicker, Form, Input, Row, Select, message } from "antd";

import MasterApi from "../../apis/master_api";
import PropTypes from "prop-types";
import { RESPONSE_STATUS_CODES } from "../../utils/enum_config";
import React from "react";
import UserApi from "../../apis/user_api";
import { getActionByLogin } from "../../utils/auth";
import moment from "moment";

const masterApi = new MasterApi();

const userApi = new UserApi();

const { TextArea } = Input;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";

export default class EditAddress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      countryList: [],
      stateList: [],
      address: "",
      country: "",
      state: "",
      city: "",
    };
  }

  componentDidMount() {
    this.handleGetCountries();
  }

  handleGetCountries = async () => {
    try {
      const response = await masterApi.countries();
      console.log(response);
      this.setState({ countryList: response.rows ? response.rows : [] });
    } catch (e) {
      console.log(e.message);
    }
  };
  handleGetStates = async (country) => {
    const REQUEST = { country };
    try {
      const response = await masterApi.states(REQUEST);
      this.setState({ stateList: response.rows });
      console.log(response.rows);
    } catch (e) {
      console.log(e.message);
    }
  };

  onChangeCountry = (value) => {
    console.log(value);
    this.setState({ country: value });
    this.handleGetStates(value);
  };
  onChangeStates = (value) => {
    console.log(value);
    this.setState({ state: value });
  };
  onChangeAddress = ({ currentTarget: input }) => {
    this.setState({ address: input.value });
    console.log(input.value);
  };
  onChangeCity = ({ currentTarget: input }) => {
    this.setState({ city: input.value });
  };

  handleSubmit = async () => {
    const REQUEST = {
      person_id: getActionByLogin("U").id,
      address: this.state.address,
      country: this.state.country,
      state: this.state.state,
      city: this.state.city,
    };

    try {
      const response = await userApi.updateAddressApi(REQUEST);
    } catch (e) {
      console.log(e.message);
    }
  };

  render() {
    return (
      <>
        <Form layout="vertical" name="basicform" onFinish={this.handleSubmit} hideRequiredMark>
          <Card>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Address" rules={[{ required: true, message: "Please confirm your address" }]}>
                  <Input placeholder="Address" onChange={this.onChangeAddress} value={this.state.address} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Country"
                  rules={[
                    {
                      required: true,
                      message: "Please select your country!",
                    },
                  ]}
                >
                  <Select value={this.state.country} onChange={this.onChangeCountry}>
                    {this.state.countryList.map((cn, index) => (
                      <Option key={index} value={cn[0]}>
                        {cn[1]}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="State"
                  rules={[
                    {
                      required: true,
                      message: "Please select your state!",
                    },
                  ]}
                >
                  <Select value={this.state.state} onChange={this.onChangeStates}>
                    {this.state.stateList.map((cn, index) => (
                      <Option key={index} value={cn.code}>
                        {cn.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="City/Area" rules={[{ required: true, message: "Please confirm your city" }]}>
                  <Input placeholder="City" onChange={this.onChangeCity} value={this.state.city} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Form.Item>
            <Button loading={this.state.loading} style={{ width: "100%" }} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

EditAddress.protoTypes = {
  editData: PropTypes.any,
};
