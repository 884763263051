import { getLoginKey, openNotification } from "./auth";

class NetworkUtil {
  async post(url, body, isAuthorized, headerData, resType) {
    let json;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${getLoginKey()}`,
    };

    if (!isAuthorized) {
      headers = { "Content-Type": "application/json" };
    }

    if (headerData) {
      headerData.app_type = "FOOPWORKS";
      headers.devicedetails = JSON.stringify(headerData);
    }

    const requestOptions = {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    };
    resType && (requestOptions.responseType = resType);
    try {
      const response = await fetch(url, requestOptions);
      json = await response.json();

      if (json.detail) {
        // console.log(json.detail)

        openNotification();

        localStorage.removeItem("USER_TOKEN");
        return (window.location = "/login");
      }
    } catch (error) {
      throw error;
    }
    return json;
  }
}
export default NetworkUtil;
