import { Button, Image, Menu, Row, Tooltip, Typography } from "antd";
import { build as appBuild, version as appVersion } from "../../../package.json";

import { DOMAIN } from "../../utils/api_config";
import { Link } from "react-router-dom";
import React from "react";
import { withGlobalSettingsWrapper } from "../common/GlobalSettingsWrapper";

// import styled from 'styled-components'

const { Text } = Typography;

class PublicNavbar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Link to="/">
          <Row style={{ float: "left" }}>
            <Image
              height={46}
              preview={false}
              style={{ marginTop: "8px", float: "left" }}
              src={
                this.props.themeItems.themesSet
                  ? DOMAIN + this.props.themeItems.appImage
                  : `${process.env.PUBLIC_URL}/logonavbar.svg`
              }
              alt={`${this.props.themeItems.companyAppName}_logo`}
            />
            <Text style={{ margin: "0 0px 0 6px", fontSize: 10, cursor: "pointer" }}>
              <Tooltip title={appBuild > 0 && `Build(${appBuild})`}>Beta v{appVersion}</Tooltip>
            </Text>
          </Row>
        </Link>
        <Menu theme="light" mode="horizontal" defaultSelectedKeys={["1"]} style={{ justifyContent: "right" }}>
          {/* <Menu.Item key="1">
            <a
              href="https://www.foop.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text>foop.com</Text>
            </a>
          </Menu.Item> */}
          {/* <Menu.Item key="2">
            <Link to="/login" key="4">
              Sign In
            </Link>
          </Menu.Item> */}
          <Menu.Item key="3">
            <Link to="/callback" key="5">
              <Button type="primary">Contact Foop</Button>
            </Link>
          </Menu.Item>
        </Menu>
      </>
    );
  }
}
export default withGlobalSettingsWrapper(PublicNavbar);
