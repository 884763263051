import { Button, Modal, message } from "antd";
import React, { useState } from "react";

import BusinessApi from "../../apis/business_api";
// import PropTypes from 'prop-types';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getActionByLogin } from "../../utils/auth";

const { confirm } = Modal;

const businessApi = new BusinessApi();

/**
 *
 * @param {actionById , actionByType , actionOnId , actionOnType , actionOnName , engageFlag  , buttonType* }
 */
export default function SocialFollow(props) {
  const [loading, setLoading] = useState(false);

  // const menu = (
  //   <Menu>
  //     <Menu.Item key="1" onClick={() => actionOnFlag(props.actionById, props.actionByType, props.actionOnId, props.actionOnType, props.engageFlag)} loading={loading}>Unfollow</Menu.Item>
  //   </Menu>
  // )

  function getWarningText(FLAG, ACTION_ON_NAME) {
    if (FLAG === "U") {
      return {
        title: `Unfollow ${ACTION_ON_NAME}?`,
        desciption:
          "When you unfollow you will not get regular updates for the user. However , some of the contents may be visible to your feed page Foop does not inform that you have unfollowed them . But you will not be in users Followers list",
      };
    }
    if (FLAG === "B") {
      return {
        title: `Block ${ACTION_ON_NAME}?`,
        desciption:
          "Blocked users won't be able to find you , your profile , postings, timelines and Anything that is related to you . Foop does not inform that you have blocked them",
      };
    }
    if (FLAG === "M") {
      return {
        title: `Remove ${ACTION_ON_NAME}?`,
        desciption:
          "When you remove users from followers list they won't be getting regular updates from you . They still can refollow you . Foop does not inform that you have removed them but you will not be in users following list",
      };
    }
    return { title: "Are you sure", desciption: "" };
  }

  async function actionOnFlag(actionById, actionByType, actionOnId, actionOnType, engageFlag) {
    if (engageFlag === "F" || engageFlag === "N") {
      setLoading(true);
      const REQUEST = {
        action_by_object_id: actionById,
        action_by_object_type: actionByType,
        action_on_object_id: actionOnId,
        action_on_object_type: actionOnType,
        engage_flag: engageFlag,
        action_details: "",
      };
      const response = await businessApi.actionOnApi(REQUEST);
      if (response) {
        if (response.statusCode === "S10001") {
          engageFlag = "U";
          props.actionSubmit(true);
        }
        setLoading(false);
      }
    } else {
      confirm({
        title: getWarningText(engageFlag, props.actionOnName).title,
        icon: <ExclamationCircleOutlined />,
        content: getWarningText(engageFlag, props.actionOnName).desciption,
        onOk: async () => {
          setLoading(true);
          const REQUEST = {
            action_by_object_id: actionById,
            action_by_object_type: actionByType,
            action_on_object_id: actionOnId,
            action_on_object_type: actionOnType,
            engage_flag: engageFlag,
            action_details: "",
          };
          const response = await businessApi.actionOnApi(REQUEST);
          if (response) {
            if (response.statusCode === "S10001") {
              message.success(response.message);
              if (engageFlag === "U") {
                engageFlag = "F";
                props.actionSubmit(false);
              } else {
                props.actionSubmit(true);
              }
            } else {
              message.error(response.message);
            }
            setLoading(false);
          }
        },
        onCancel() {
          console.log("cancel");
        },
      });
    }
  }

  function getButtonText(FLAG) {
    if (FLAG === "F") {
      if (props.actionOnId === getActionByLogin("U").id || props.actionOnId === getActionByLogin("B").id) {
        return <></>;
      } else {
        return (
          <Button
            type={props.buttonType}
            style={props.btnStyle}
            icon={props.btnIcon}
            onClick={() =>
              actionOnFlag(props.actionById, props.actionByType, props.actionOnId, props.actionOnType, props.engageFlag)
            }
            loading={loading}
          >
            Follow {props.buttonForListItem && props.actionOnName}
          </Button>
        );
      }
    }
    if (FLAG === "U") {
      return (
        <Button
          type={props.buttonType}
          style={props.btnStyle}
          icon={props.btnIcon}
          onClick={() =>
            actionOnFlag(props.actionById, props.actionByType, props.actionOnId, props.actionOnType, props.engageFlag)
          }
          loading={loading}
        >
          Un-follow {props.buttonForListItem && props.actionOnName}
        </Button>
      );
    }
    if (FLAG === "B" && props.actionById !== props.actionOnId) {
      return (
        <Button
          style={props.btnStyle}
          icon={props.btnIcon}
          type={props.buttonType}
          onClick={() =>
            actionOnFlag(props.actionById, props.actionByType, props.actionOnId, props.actionOnType, props.engageFlag)
          }
          loading={loading}
        >
          Block {props.buttonForListItem && props.actionOnName}
        </Button>
      );
    }
    if (FLAG === "R") {
      return (
        <Button
          style={props.btnStyle}
          icon={props.btnIcon}
          type={props.buttonType}
          onClick={() =>
            actionOnFlag(props.actionById, props.actionByType, props.actionOnId, props.actionOnType, props.engageFlag)
          }
          loading={loading}
        >
          Restrict
        </Button>
      );
    }
    if (FLAG === "M") {
      return (
        <Button
          style={props.btnStyle}
          icon={props.btnIcon}
          type={props.buttonType}
          onClick={() =>
            actionOnFlag(props.actionById, props.actionByType, props.actionOnId, props.actionOnType, props.engageFlag)
          }
          loading={loading}
        >
          Remove
        </Button>
      );
    }
    if (FLAG === "N" && props.actionById !== props.actionOnId) {
      return (
        <Button
          style={props.btnStyle}
          icon={props.btnIcon}
          type={props.buttonType}
          onClick={() =>
            actionOnFlag(props.actionById, props.actionByType, props.actionOnId, props.actionOnType, props.engageFlag)
          }
          loading={loading}
        >
          Unblock
        </Button>
      );
    }
    return <></>;
  }

  return <>{getButtonText(props.engageFlag)}</>;
}
