import React from 'react'
import { Row, Col, Form, Input, Button, DatePicker, Checkbox, message } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import UserApi from '../../apis/user_api'
import { RESPONSE_STATUS_CODES } from '../../utils/enum_config'
import { getActionByLogin, getUser } from '../../utils/auth'

const dateFormat = 'YYYY-MM-DD'
const userApi = new UserApi()

const { TextArea } = Input

export default class EducationForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      id: null,
      business_name: null,
      start_date: null,
      end_date: null,
      class_name: null,
      grade: null,
      description: null,
      is_current: false,
      business_id: null,
      industry_type: null,
      class_id: null,
      location: null,
      employment_type: null,
      add_type: null,
      person_id: null,
      school_name: null,
      field_of_study: null,
      designation: null
    }
  }

  componentDidMount () {
    if (this.props.editData) {
      this.setState({
        id: this.props.editData ? this.props.editData.id : null,
        business_name: this.props.editData ? this.props.editData.business_name : null,
        start_date: this.props.editData ? this.props.editData.start_date : null,
        end_date: this.props.editData ? this.props.editData.end_date : null,
        class_name: this.props.editData ? this.props.editData.class_name : null,
        grade: this.props.editData ? this.props.editData.grade : null,
        description: this.props.editData ? this.props.editData.description : null,
        is_current: this.props.editData ? this.props.editData.is_current : null,
        person_id: this.props.editData ? this.props.editData.person_id : null,
        add_type: this.props.editData ? this.props.editData.add_type : null
      })
    }
  };

  handleBusinessName = (e) => {
    this.setState({
      business_name: e.target.value
    })
  };

  handleStartDate = (date, dateString) => {
    this.setState({
      start_date: dateString
    })
  };

  handleEndDate = (date, dateString) => {
    this.setState({
      end_date: dateString
    })
  };

  handleEndDate = (date, dateString) => {
    this.setState({
      end_date: dateString
    })
  };

  handleGrade = (e) => {
    this.setState({
      grade: e.target.value
    })
  };

  handleClass = (e) => {
    this.setState({
      class_name: e.target.value
    })
  };

  handleGender = (e) => {
    this.setState({
      gender: e
    })
  };

  handleDescription = (e) => {
    this.setState({
      description: e.target.value
    })
  };

  handleStillStudying = (e) => {
    this.setState({
      is_current: e.target.checked
    })
  };

  handleSubmit = async () => {
    this.setState({
      loading: true
    })
    const REQUEST = {
      id: getUser().id,
      // business_name: this.state.business_name,
      is_current: this.state.is_current,
      start_date: this.state.start_date,
      end_date: this.state.is_current ? null : this.state.end_date,
      class_name: this.state.class_name,
      description: this.state.description,
      grade: this.state.grade,
      person_id: String(getActionByLogin('U').id),
      add_type: 'education',
      business_id: null,
      industry_type: null,
      class_id: null,
      location: null,
      employment_type: null,
      institution_name: this.state.business_name,
      field_of_study: null,
      designation: null,
      school_name:null,
    }
    // console.log(REQUEST);
    // return
    if (this.props.editData) {
      const response = await userApi.editEducationApi(REQUEST)
      if (response) {
        if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
          this.setState({
            loading: false
          })
          this.props.onSubmitForm()
        } else {
          this.setState({
            loading: false
          })
          message.error(response.message)
        }
      } else {
        this.setState({
          loading: false
        })
      }
    } else {
      const response = await userApi.addEducationApi(REQUEST)
      if (response) {
        if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
          this.setState({
            loading: false
          })
          this.props.onSubmitForm()
        } else {
          this.setState({
            loading: false
          })
          message.error(response.message)
        }
      } else {
        this.setState({
          loading: false
        })
      }
    }
  };

  render () {
    return (
      <>
        <Form layout="vertical"
          name="register"
          onFinish={this.handleSubmit}
          hideRequiredMark>
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                // name="University/School/Business"
                label="University/School/Business"
                rules={[
                  { required: true, message: 'Please confirm your University/School/Business' }
                ]}
              >
                <Input
                  placeholder="University/School/Business"
                  onChange={this.handleBusinessName}
                  value={this.state.business_name}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                // name="Start Date"
                label="Start Date"
              >
                <DatePicker
                  onChange={this.handleStartDate}
                  value={this.state.start_date ? moment(this.state.start_date, dateFormat) : null}
                  disabledDate={(d) =>
                    !d || d.isAfter(moment())
                  }
                  format="YYYY-MM-DD"
                  defaultPickerValue={moment().subtract(4, 'years')}
                  placeholder="yyyy-mm-dd"
                />
              </Form.Item>
            </Col>
            {
              this.state.is_current ? <></>
                : <Col span={8}>
                  <Form.Item
                    // name="End Date"
                    label="End Date"
                  >
                    <DatePicker
                      onChange={this.handleEndDate}
                      value={this.state.end_date ? moment(this.state.end_date, dateFormat) : null}
                      disabledDate={(d) =>
                        !d || d.isBefore(moment(this.state.start_date))
                      }
                      format="YYYY-MM-DD"
                      placeholder="yyyy-mm-dd"
                    />
                  </Form.Item>
                </Col>
            }

            <Col span={8}>
              <Form.Item
              // name="Still Studying"
              >
                <Checkbox checked={this.state.is_current} onChange={this.handleStillStudying} value={this.state.is_current} >Still studying</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                // name="Class/Degree"
                label="Class/Degree"
              >
                <Input
                  placeholder="Class/Degree"
                  onChange={this.handleClass}
                  value={this.state.class_name}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                // name="Rank/Grade/CGPA (Optional)"
                label="Rank/Grade/CGPA (Optional)"
              >
                <Input
                  placeholder="University/School/Business"
                  onChange={this.handleGrade}
                  value={this.state.grade}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                // name="Describe about your education"
                label="Describe about your education"
              >
                <TextArea showCount maxLength={100} onChange={this.handleDescription} value={this.state.description} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button
              loading={this.state.loading}
              style={{ width: '100%' }}
              type="primary"
              htmlType="submit"
            >
              Submit
                        </Button>
          </Form.Item>
        </Form>
      </>
    )
  }
}

EducationForm.protoTypes = { editData: PropTypes.any }
