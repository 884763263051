import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography, Spin, List, Checkbox, Row, Col
} from 'antd'

import RatingCard from '../../components/cards/rating'
import UserApi from '../../apis/user_api'

import { RESPONSE_STATUS_CODES } from '../../utils/enum_config'

const { Text } = Typography

const userApi = new UserApi()

export default class MyProficiencyForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      RATING_TEXT: this.props.RATING_TEXT,
      EX_CAT_TYPE_ID: this.props.EX_CAT_TYPE_ID,
      EX_CAT_NAME: this.props.EX_CAT_NAME,
      EX_CAT_PID_VALUE: this.props.EX_CAT_PID_VALUE,
      EX_CAT_ID: this.props.EX_CAT_ID,
      SUBJECT_ID: this.props.SUBJECT_ID,
      SUBJECT_TYPE: this.props.SUBJECT_TYPE,
      ACTION_BY_ID: this.props.ACTION_BY_ID,
      ACTION_BY_TYPE: this.props.ACTION_BY_TYPE,
      data: [],
      SELECTED_PROFICIENCY: [],
      loading: false
    }

    this.next = this.next.bind(this)
  }

    componentDidMount = async () => {
      this.getAbilitiesList(
        this.state.EX_CAT_TYPE_ID,
        this.state.EX_CAT_NAME,
        this.state.EX_CAT_PID_VALUE,
        this.state.EX_CAT_ID,
        this.state.SUBJECT_ID, this.state.ACTION_BY_ID, 1, 1000)
    };

    getAbilitiesList = async (TYPE_ID, NAME, PID_VALE, ID, CLASSID, USERID, SKIP, OFFSET) => {
      this.setState({
        loading: true
      })
      const REQUEST =
        {
          id: null,
          standard_expertise_category_types_id: TYPE_ID,
          standard_expertise_category_name: NAME,
          standard_expertise_category_pid_val: PID_VALE,
          standard_expertise_category_id: ID,
          subject_class_id: CLASSID,
          person_id: USERID,
          given_by_id: USERID,
          endorse_content_id: null,
          page_number: SKIP,
          page_size: OFFSET
        }
      const response = await await userApi.getAbilitiesApi(REQUEST)
      if (response) {
        if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
          this.setState({
            data: response.rows,
            loading: false
          })
        }
      }
    };

    next () {
      console.log('Dataaa' + JSON.stringify(this.state.SELECTED_PROFICIENCY))
    }

    onChange = (e) => {
      if (e.target.checked) {
        this.state.SELECTED_PROFICIENCY.push(e.target.value)
        this.setState({
          SELECTED_PROFICIENCY: this.state.SELECTED_PROFICIENCY
        })
      } else {
        const index = this.state.SELECTED_PROFICIENCY.indexOf(e.target.value)
        this.state.SELECTED_PROFICIENCY.splice(index, 1)
        this.setState({
          SELECTED_PROFICIENCY: this.state.SELECTED_PROFICIENCY
        })
      }
      this.props.onSubmitForm({ KEY: null, VALUE: this.state.SELECTED_PROFICIENCY })
    }

    handleRating = (e) => {
      this.props.onSubmitForm({ KEY: null, VALUE: this.state.SELECTED_PROFICIENCY })
    }

    render () {
      return (
            <>
                <div>
                    {
                        this.state.loading
                          ? <Spin style={{ paddingLeft: '50%', paddingTop: '10%' }} />
                          : <List
                                itemLayout="horizontal"
                                dataSource={this.state.data}
                                renderItem={item => (
                                    <List.Item
                                        actions={
                                            [
                                                <>
                                                    <Checkbox value={item} onChange={this.onChange}></Checkbox>
                                                </>
                                            ]}
                                    >
                                        <List.Item.Meta
                                            title={item.expertise_ability_code}
                                            description={''}
                                        />
                                    </List.Item>
                                )}
                            />
                    }

                    <Row gutter={16}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Text>{this.state.RATING_TEXT}</Text>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <RatingCard
                                NOTE_ID={null}
                                IS_BASIC_UI={true}
                                CONTEXT_ID={this.state.ACTION_BY_ID}
                                CONTEXT_TYPE={this.state.ACTION_BY_TYPE}
                                SUBJECT_ID={this.state.SUBJECT_ID}
                                SUBJECT_TYPE={this.state.SUBJECT_TYPE}
                                ACTION_BY_ID={this.state.ACTION_BY_ID}
                                ACTION_BY_TYPE={this.state.ACTION_BY_TYPE}
                                DEFAULT_RATING={null}
                                IS_RATING_STATUS={null}
                                onSubmitRating={this.handleRating}
                            />
                        </Col>
                    </Row>

                </div>
            </>
      )
    }
}

MyProficiencyForm.protoTypes = {
  RATING_TEXT: PropTypes.any,
  EX_CAT_TYPE_ID: PropTypes.any,
  EX_CAT_NAME: PropTypes.any,
  EX_CAT_PID_VALUE: PropTypes.any,
  EX_CAT_ID: PropTypes.any,
  SUBJECT_ID: PropTypes.any,
  SUBJECT_TYPE: PropTypes.any,
  onSubmitForm: PropTypes.any
}
