import {
  Button,
  Card,
  Col,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Input,
  Menu,
  Modal,
  Row,
  Tag,
  Typography,
  message,
} from "antd";
import { DeleteOutlined, EditOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { getActionByLogin, getBusinessId } from "../../utils/auth";

import BusinessApi from "../../apis/business_api";
import ManageBizTable from "../manage_business/ManageBizTable";
import { RESPONSE_STATUS_CODES } from "../../utils/enum_config";
import React from "react";
import UserApi from "../../apis/user_api";
import { withGlobalSettingsWrapper } from "../common/GlobalSettingsWrapper";

const { Title, Paragraph } = Typography;

const userApi = new UserApi();
const businessApi = new BusinessApi();
const { Search } = Input;
class CourseCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      page_number: 1,

      searchVal: null,

      selectedItem: null,
      selectedOption: null,

      data: [],
      totalDept: 10,

      department_name: null,
      department_code: null,
      department_description: null,
    };
  }

  delete = (ITEM) => {
    this.setState({ selectedItem: ITEM, selectedOption: "deleted_departments" });

    Modal.confirm({
      title: `Delete ${ITEM.department_name}?`,
      content: <>Are you sure you want to delete department {ITEM.department_name}?</>,
      onOk: () => this.handleSubmit(ITEM, "deleted_departments"),
      onCancel: this.setState({ selectedItem: null, selectedOption: null }),
      maskClosable: false,
    });
  };

  handleSearchChange = (e) => {
    const searchVal = e.target.value.replace(/^\s/, "");
    this.setState({ searchVal });

    if (!searchVal && this.state.searchVal) {
      this.getDepartmentList(null);
    }
  };

  getDeptMoreMenu(ITEM) {
    // console.log(ITEM)
    return (
      <Menu>
        <Menu.Item key={`edit${ITEM.department_code}`}>
          <Button
            icon={<EditOutlined />}
            type="text"
            onClick={() =>
              this.setState({ selectedItem: ITEM, visibleAdd: true, selectedOption: "updated_departments" })
            }
          >
            Edit
          </Button>
        </Menu.Item>
        <Menu.Item key={`delete${ITEM.department_code}`}>
          <Button icon={<DeleteOutlined />} type="text" onClick={() => this.delete(ITEM)}>
            Delete
          </Button>
        </Menu.Item>
      </Menu>
    );
  }

  columns = [
    // {
    //   title: "Department Code",
    //   dataIndex: "department_code",
    // },
    {
      title: "Department Name",
      dataIndex: "department_name",
      ellipsis: true,
      render: (text) => (!text?.trim() ? <>-</> : <>{text}</>),
    },
    {
      title: "Department Description",
      dataIndex: "department_description",
      ellipsis: true,
      render: (text) => (!text?.trim() ? <>-</> : <>{text}</>),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (record) => (
        <Dropdown key={record.department_code} overlay={this.getDeptMoreMenu(record)}>
          <Button type="text" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <MoreOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  componentDidMount() {
    this.getDepartmentList();
  }

  getDepartmentList = async (SEARCHVALUE = this.state.searchVal, page_number = 1) => {
    this.setState({
      loading: true,
      page_number,
    });
    const REQUEST = {
      business_id: getBusinessId(),
      search_val: SEARCHVALUE,
      page_number,
      page_size: 10,
    };
    const response = await businessApi.getDepartmentListApi(REQUEST);
    if (response) {
      if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
        const newResponse = response.rows.map((el) => {
          return { ...el, key: el.department_code };
        });
        this.setState({
          data: newResponse,
          loading: false,
          totalDept: response.total,
        });
      }
    }
  };

  getCourseTag(DATA) {
    if (DATA) {
      return DATA.map((item) => (
        <>
          <Tag>
            <Button type="text">{item ? item.course : ""}</Button>
          </Tag>
        </>
      ));
    }
    return <></>;
  }

  getDepartmentTag(DATA) {
    if (DATA) {
      return DATA.map((item) => (
        <>
          <Tag>
            <Button type="text">{item ? item.department : ""}</Button>
          </Tag>
        </>
      ));
    }
    return <></>;
  }

  showDrawerEdit = (element) => {
    this.setState({
      visibleAdd: true,
      selectedOption: "added_departments",
    });
  };

  handleSubmit = async (formValues, option) => {
    this.setState({
      loading: true,
    });

    const REQUEST = {
      business_id: getBusinessId(),
      added_departments: [],
      updated_departments: [],
      deleted_departments: [],
    };

    REQUEST[this.state.selectedOption || option].push({
      department_name: formValues.department_name,
      // department_code: formValues.department_code,
      department_code: formValues.department_name.toLowerCase().replaceAll(" ", ""),
      department_description: formValues.department_description,
    });

    const response = await userApi.updateBusinessDepartmentApi(REQUEST);
    if (response) {
      if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
        this.setState({
          loading: false,
          visibleAdd: false,
          selectedItem: null,
          selectedOption: null,
        });
        this.getDepartmentList(this.state.searchVal, this.state.page_number);
      } else {
        this.setState({
          loading: false,
        });
        message.error(response.message);
      }
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  // handleDepartmentName = (e) => {
  //   this.setState({
  //     department_name: e.target.value,
  //   });
  // };

  // handleDepartmentCode = (e) => {
  //   this.setState({
  //     department_code: e.target.value,
  //   });
  // };

  // handleDepartmentDes = (e) => {
  //   this.setState({
  //     department_description: e.target.value,
  //   });
  // };

  onClose = () => {
    this.setState({
      visibleAdd: false,
      selectedItem: null,
      selectedOption: null,
    });
    this.getDepartmentList();
  };

  render() {
    return (
      <>
        <Card
          title={this.props.DETAILS ? <Title level={5}>{this.props.DETAILS.title}</Title> : ""}
          extra={
            !this.props.DETAILS && [
              <div key="departmentActions" style={{ display: "flex", alignItems: "center" }}>
                <Button
                  type="primary"
                  key="plusDeptBtn"
                  onClick={() => this.showDrawerEdit(null)}
                  icon={<PlusOutlined />}
                  style={{
                    backgroundColor: this.props.themeItems.themeColors.btnBlue,
                    borderColor: "transparent",
                    marginRight: "8px",
                  }}
                >
                  Create
                </Button>
                <Search
                  key="searchElement"
                  style={{ padding: "2px", width: 250 }}
                  placeholder="Search"
                  allowClear
                  value={this.state.searchVal}
                  onChange={this.handleSearchChange}
                  onSearch={(val) => this.getDepartmentList(val)}
                />
              </div>,
            ]
          }
        >
          {this.props.DETAILS ? (
            this.props.DETAILS.subRow.length ? (
              <Paragraph>
                {this.props.DETAILS.cardName === "DepartmentCard"
                  ? this.getDepartmentTag(this.props.DETAILS.subRow)
                  : this.getCourseTag(this.props.DETAILS.subRow)}
              </Paragraph>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, no departments to show!" />
            )
          ) : (
            <>
              <ManageBizTable
                tableData={this.state.data}
                currPageNumber={this.state.page_number}
                getTableData={(number) => this.getDepartmentList(this.state.searchVal, number)}
                subHeight={this.state.totalDept > 10 ? 340 : 280}
                paginationTotal={this.state.totalDept}
                columns={this.columns}
              />
            </>
          )}
        </Card>

        <Drawer
          title={this.state.selectedItem ? "Update Department" : "Add Department"}
          width={720}
          onClose={this.onClose}
          visible={this.state.visibleAdd}
          bodyStyle={{ paddingBottom: 80 }}
          maskClosable={false}
          destroyOnClose
        >
          <Form layout="vertical" name="register" onFinish={this.handleSubmit} hideRequiredMark>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="department_name"
                  label="Enter the Department Name"
                  rules={[
                    { required: true, message: "Please Enter the Department Name" },
                    {
                      // pattern: /^(?=[a-zA-Z0-9 _-]{4,30}$)(?!.*[-_]{2})[^-_].*[^-_]$/,
                      pattern: /^(?=[a-zA-Z0-9 _-]$)(?!.*[-_]{2})[^-_].*[^-_]$/,
                      message: "Department name can be alphabets, numbers or -_ ",
                    },
                  ]}
                  initialValue={this.state.selectedItem && this.state.selectedItem.department_name}
                >
                  <Input placeholder="Enter the Department Name" />
                </Form.Item>
              </Col>
            </Row>

            {/* <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="department_code"
                  label="Enter Department Code"
                  rules={[
                    { required: true, message: "Please Enter the Department Code" },
                    {
                      pattern: /^[a-zA-Z0-9 ]{4,26}$/,
                      message: "Department code can be alphabets or numbers and should be 4-26 characters long",
                    },
                  ]}
                  initialValue={this.state.selectedItem && this.state.selectedItem.department_code}
                >
                  <Input disabled={this.state.selectedItem} placeholder="Enter Department Code" />
                </Form.Item>
              </Col>
            </Row> */}

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="department_description"
                  label="Enter Department Description"
                  initialValue={this.state.selectedItem && this.state.selectedItem.department_description}
                >
                  <Input placeholder="Enter Department Description" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button loading={this.state.loading} style={{ width: "100%" }} type="primary" htmlType="submit">
                {this.state.selectedItem ? "Update" : "Add"}
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </>
    );
  }
}

export default withGlobalSettingsWrapper(CourseCard);
