import { DEVICE_CODE, FCM_ID } from "../utils/auth";
import { build as appBuild, version as appVersion } from "../../package.json";
import { browserName, browserVersion, deviceType, getUA, osName, osVersion } from "react-device-detect";

import ApiConfig from "../utils/api_config";
import NetworkUtil from "../utils/network_util";

const networkUtil = new NetworkUtil();
const apiConfig = new ApiConfig();

class UserApi {
  async login(data) {
    let result = null;
    // let ipAddress = '';

    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(this.showPostion);
    // }

    // const response = await this.getIpAddressApi();
    // if (response) {
    //   console.log(`Dataa${JSON.stringify(response)}`);
    //   ipAddress = response;
    // }

    // console.log(`Device Information   ${JSON.stringify(this.deviceInfo())}`);
    const headers = this.deviceInfo();

    localStorage.setItem(DEVICE_CODE, headers.machine_code);
    localStorage.setItem("deviceDetails", JSON.stringify(headers));

    headers.fcm_id = localStorage.getItem(FCM_ID) || "";

    try {
      result = await networkUtil.post(apiConfig.loginUrl, data, false, headers);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async logout() {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.logoutUrl, {}, true, {
        ...JSON.parse(localStorage.getItem("deviceDetails")),
        fcm_id: localStorage.getItem(FCM_ID),
      });
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async resendOtp(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.resendOtpUrl, { ...data, app_type: "FOOPWORKS" }, false);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async forgotPasswordSendOtp(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.forgotPasswordSendOtpUrl, { ...data, app_type: "FOOPWORKS" }, false);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async register(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.registerUrl, { ...data, app_type: "FOOPWORKS" }, false);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async createPassword(data) {
    let result = null;
    try {
      result = await networkUtil.post(
        apiConfig.createPasswordAfterOtpVerificationUrl,
        { ...data, app_type: "FOOPWORKS" },
        false
      );
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async me() {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.meUrl, { person_id: null }, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async other(id) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.meUrl, { person_id: id }, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async uploadAvatar(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.avatarUploadUrl, data, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getIpAddressApi() {
    let json = null;

    try {
      const response = await fetch(apiConfig.getIpAddressUrl);
      json = await response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }

    return json;
  }

  deviceInfo() {
    const uniqueDeviceCode = new Date().getTime().toString();

    const REQUEST = {
      // fcm_id: '',
      device_info: deviceType,
      device_type: deviceType,
      device_vendor: deviceType,
      mac_id: uniqueDeviceCode,
      browser_type: browserName,
      browser_version: browserVersion,
      os_type: osName,
      os_version: osVersion,
      manufacturer: getUA,
      location: "",
      gps_info: "info",
      machine_code: uniqueDeviceCode,
      application_type: "web",
    };

    return REQUEST;
  }

  async updateUserImageApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateUserImageUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  showPostion(pos) {
    const crd = pos.coords;
    // console.log(`Print${crd}`);
  }

  async updateUserBasicApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateUserBasicUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateUserBasicByAdminApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateUserBasicUrlByAdmin, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async addEducationApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.addEducationUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async editEducationApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.editEducationUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async setExpertiseApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.setExpertiseUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getAbilitiesApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getAbilitiesUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getExpertiseApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getExpertiseUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getExpertiseListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getExpertiseListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getSubjectExpertiseListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getSubjectExpertiseListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async addSubjectApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.addSubjectUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async addClassApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.addClassUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getClassListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getClassListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getEducationSeeMoreApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getEducationSeeMoreUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getWorkSeeMoreApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getWorkSeeMoreUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getSubjectSeeMoreApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getSubjectSeeMoreUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getClassSeeMoreApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getClassSeeMoreUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getSeeMoreLanguageSkillsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getSeeMoreLanguageSkillsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async changePasswordApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.changePasswordUrl, { ...REQUEST, app_type: "FOOPWORKS" }, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getBlockUserListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getBlockUserListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async actionOnApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.actionOnUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getInvitationLinkApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getInvitationLinkUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async sendInvitationLinkApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.sendInvitationLinkUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async addAccountDetailsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.addAccountDetailsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async editAccountDetailsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.editAccountDetailsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateBusinessDepartmentApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateBusinessDepartmentUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getDomainListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getDomainListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getEmailAccountListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getEmailAccountListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getGlobalUserListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getGlobalUserListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async createEmailUserApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createEmailUserUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async checkEmailExistApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.checkEmailExistUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async createDomainEmailApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createDomainEmailUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async deleteUserApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.deleteUserUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }
  async updateAddressApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateAddressUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  getProjectContent(project) {
    let result = null;
    result = {
      projectTitle: "foopworks",
      projectDescription: "Business professional email",
      projectIcon: `${process.env.PUBLIC_URL}/logonavbar.svg`,
      projectDomain: "works.foop.com",
      projectUrl: "https://works.foop.com",
      projectVersion: appVersion,
      build: appBuild,
      primaryColor: "",
      secondaryColor: "",
      projectEmail: "works.foop",
    };
    return result;
  }
}

export default UserApi;
