import ApiConfig from "../utils/api_config";
import NetworkUtil from "../utils/network_util";

const networkUtil = new NetworkUtil();
const apiConfig = new ApiConfig();

class MasterApi {
  async upload(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.uploadUrl, data, true);
    } catch (error) {
      console.log(error);
    }
    return result;
  }

  async countries() {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.countriesUrl, {}, false);
    } catch (error) {
      console.log(error);
    }
    return result;
  }

  async states(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.statesUrl, { ...data, app_type: "FOOPWORKS" }, false);
    } catch (error) {
      console.log(error);
    }
    return result;
  }

  async cities(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.citiesUrl, { ...data, app_type: "FOOPWORKS" }, false);
    } catch (error) {
      console.log(error);
    }
    return result;
  }

  async dictionaryApi(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.dictionaryUrl, data, false);
    } catch (error) {
      console.log(error);
    }
    return result;
  }

  async dictionarStandardApi(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getStatusListApi, data, false);
    } catch (error) {
      console.log(error);
    }
    return result;
  }

  async createRequestCallBackApi(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createRequestCallBackUrl, data, false);
    } catch (error) {
      console.log(error);
    }
    return result;
  }

  async getTermsConditionApi(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getTermsConditionUrl, data, false);
    } catch (error) {
      console.log(error);
    }
    return result;
  }

  async countryCodeListApi(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.countryCodeListUrl, { ...data, app_type: "FOOPWORKS" }, false);
    } catch (error) {
      console.log(error);
    }
    return result;
  }
}

export default MasterApi;
