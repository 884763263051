import { Colors } from "../assets/colors";
import { createContext } from "react";

const GlobalSettingsContext = createContext({
  themeItems: {
    themeColors: Colors,
    appImage: `${process.env.PUBLIC_URL}/logonavbar.svg`,
    companyAppName: "Foopworks",
    themesSet: false,
    getThemeData: () => {},
  },
  appAccess: {
    appSettings: [],
    checkAppAccess: () => {},
    refetchAppSettings: () => {},
  },
});

export default GlobalSettingsContext;
