import { Avatar, notification } from "antd";

import AboutCard from "../components/profile/about-card";
import AccountDetails from "../components/profile/accounting-details";
import { BASIC_URL } from "./api_config";
import Biocard from "../components/profile/bio-card";
import BranchCard from "../components/profile/branch-card";
import ContactInfoCard from "../components/profile/contact-info-card";
import CourseCard from "../components/profile/course-card";
import Education from "../components/profile/education-card";
import Language from "../components/profile/languages-card";
import Profilecard from "../components/profile/profile-card";
// import RatingAndReviewCard from "../components/profile/rate-card";
import React from "react";
import { SERVICE_TYPE } from "./enum_config";
import WorkCard from "../components/profile/work-card";
import getSymbolFromCurrency from "currency-symbol-map";

class ApiResponseCode {
  SUCCESS_CODE = "S10001";

  openMessage = (text, statusCode) => {
    if (statusCode === "S10001") {
      const args = {
        message: "Success!",
        description: text,
        duration: 4,
        placement: "bottomLeft",
      };
      // message.success(text);
      // notification.open(args);
      notification.success(args);
    } else if (statusCode === "S10003") {
    } else {
      // message.error(text);
      const args = {
        message: "Error!",
        description: text,
        duration: 4,
        placement: "bottomLeft",
      };
      // message.success(text);
      // notification.open(args);
      notification.error(args);
    }
  };

  getEntityText(personType) {
    if (personType === "T") {
      return "Staff";
    }
    if (personType === "S") {
      return "Student";
    }
    if (personType === "P") {
      return "Parents";
    }
    if (personType === "L") {
      return "Alumni";
    }
    return "";
  }

  getSectionBox(item, viewId, viewType, refetchAction, manageBranchChange) {
    if (item.cardName === "profileNameCard") {
      return <Profilecard DETAILS={item} VIEWID={viewId} VIEWTYPE={viewType} />;
    }
    if (item.cardName === "ContactInfoCard") {
      return <ContactInfoCard DETAILS={item} VIEWID={viewId} VIEWTYPE={viewType} />;
    }
    if (item.cardName === "Biocard") {
      return <Biocard DETAILS={item} VIEWID={viewId} VIEWTYPE={viewType} />;
    }
    if (item.cardName === "LanguageCard") {
      return <Language DETAILS={item} VIEWID={viewId} VIEWTYPE={viewType} />;
    }
    if (item.cardName === "EducationCard") {
      return <Education DETAILS={item} VIEWID={viewId} VIEWTYPE={viewType} />;
    }
    // if (item.cardName === 'RatingAndReviewCard') {
    //   return <RatingAndReviewCard DETAILS={item} VIEWID={viewId} VIEWTYPE={viewType} />
    // }
    if (item.cardName === "WorkCard") {
      return <WorkCard DETAILS={item} VIEWID={viewId} VIEWTYPE={viewType} />;
    }
    if (item.cardName === "aboutCard") {
      return <AboutCard DETAILS={item} VIEWID={viewId} VIEWTYPE={viewType} />;
    }
    if (item.cardName === "historyCard") {
      return <AboutCard DETAILS={item} VIEWID={viewId} VIEWTYPE={viewType} />;
    }
    if (item.cardName === "CourseCard") {
      return <CourseCard DETAILS={item} VIEWID={viewId} VIEWTYPE={viewType} />;
    }
    if (item.cardName === "DepartmentCard") {
      return <CourseCard DETAILS={item} VIEWID={viewId} VIEWTYPE={viewType} />;
    }
    if (item.cardName === "AccountingInfoCard") {
      return <AccountDetails DETAILS={item} VIEWID={viewId} VIEWTYPE={viewType} />;
    }
    if (item.cardName === "branchcard") {
      return (
        <BranchCard
          getAvatar={this.getAvatar}
          manageBranchChange={manageBranchChange}
          DETAILS={item}
          VIEWID={viewId}
          VIEWTYPE={viewType}
        />
      );
    }
    return null;
  }

  getActionStatusWithType(data, actionType) {
    let ACTION;
    if (data) {
      if (data.length > 0) {
        ACTION = data.find((item) => item.type === actionType);
        return ACTION.value;
      }
      return false;
    }
    return false;
  }

  getUrlForImage(url, resolution, service_type) {
    let imageName = "";
    if (url) {
      const s = url.split("/");
      imageName = s[s.length - 1];
    }

    return (
      BASIC_URL +
      "/file/media/read?" +
      `name=${imageName}` +
      `&resolution=${resolution}` +
      `&service_type=${service_type}`
    );
  }

  getAvatar = (
    themeColors,
    resolution,
    imgType,
    imgAvatar = null,
    nameVal = null,
    specialName = null,
    specialAvatarBgColor = null,
    specialAvatarFontColor
  ) =>
    imgAvatar || (!nameVal && !specialName) ? (
      <Avatar
        style={{
          border: `1px solid ${specialAvatarBgColor || themeColors.primary}`,
          ...(specialAvatarFontColor && { color: specialAvatarFontColor }),
        }}
        src={this.getUrlForImage(imgAvatar, resolution, imgType)}
      />
    ) : (
      <Avatar
        style={{
          border: `1px solid ${specialAvatarBgColor || themeColors.primary}`,
          backgroundColor: specialAvatarBgColor || themeColors.primary,
          color: specialAvatarFontColor || "#fff",
        }}
      >
        {specialName || nameVal?.trim()[0]?.toUpperCase()}
      </Avatar>
    );

  formatMoney = (value, currency = "", decimals = 2) => {
    const formatList =
      currency === "INR"
        ? [
            { num: 1e7, den: "Cr" },
            { num: 1e5, den: "L" },
            { num: 1e3, den: "T" },
          ]
        : [
            { num: 1e12, den: "Tr" },
            { num: 1e9, den: "B" },
            { num: 1e6, den: "M" },
            { num: 1e3, den: "K" },
          ];
    let finalValue = Math.floor(value).toFixed(decimals);
    formatList.forEach((el) => {
      if (finalValue > el.num) {
        finalValue = (finalValue / el.num).toFixed(2) + el.den;
        return;
      }
    });

    return (currency ? getSymbolFromCurrency(currency) + " " : "") + finalValue;
  };

  navigateToProfile(ID, NAME, TYPE, history) {
    if (ID) {
      if (TYPE === SERVICE_TYPE.PERSON) {
        history.push(`/en-in/network/${ID}`);
      } else {
        history.push(`/company/${NAME}/profile/${ID}`);
      }
    }
  }
}

export default ApiResponseCode;
