import React from 'react'
import { Row, Col, Form, Input, Select, Button, DatePicker, Checkbox, message, AutoComplete } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import MasterApi from '../../apis/master_api'
import BusinessApi from '../../apis/business_api'
import UserApi from '../../apis/user_api'
import { RESPONSE_STATUS_CODES } from '../../utils/enum_config'
import { getActionByLogin } from '../../utils/auth'

const dateFormat = 'YYYY-MM-DD'
const userApi = new UserApi()
const masterApi = new MasterApi()
const businessApi = new BusinessApi()

const { TextArea } = Input

export default class WorkForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      id: null,
      business_id: null,
      industry_type: null,
      business_name: null,
      class_id: null,
      location: null,
      employment_type: null,
      add_type: null,
      is_current: false,
      person_id: null,
      school_name: null,
      start_date: null,
      end_date: null,
      class_name: null,
      field_of_study: null,
      grade: null,
      description: null,
      designation: null,

      employeeTypeList: [],
      industryTypeList: [],
      BUSINESS_LIST: []
    }
  }

  componentDidMount = () => {
    this.getEmployeeTypes('EMPLOYMENTTYPE', 1, 115, '')
    this.getIndustryTypes('INDUSTRYTYPE', 1, 115, '')
    if (this.props.editData) {
      this.setState({
        id: this.props.editData ? this.props.editData.id : null,
        business_name: this.props.editData ? this.props.editData.business_name : null,
        start_date: this.props.editData ? this.props.editData.start_date : null,
        end_date: this.props.editData ? this.props.editData.end_date : null,
        class_name: this.props.editData ? this.props.editData.class_name : null,
        grade: this.props.editData ? this.props.editData.grade : null,
        description: this.props.editData ? this.props.editData.description : null,
        is_current: this.props.editData ? this.props.editData.is_current : null,
        person_id: this.props.editData ? this.props.editData.person_id : null,
        add_type: this.props.editData ? this.props.editData.add_type : null,
        industry_type: this.props.editData ? this.props.editData.industry_type : null,
        employment_type: this.props.editData ? this.props.editData.employment_type : null,
        location: this.props.editData ? this.props.editData.location : null,
        designation: this.props.editData ? this.props.editData.designation : null
      })
    }
  };

  getEmployeeTypes = async (TYPE, SKIP, OFFSET, SEARCHVAL) => {
    const REQUEST = {
      dictonary_type_id: TYPE,
      page_number: SKIP,
      page_size: OFFSET,
      search_val: SEARCHVAL
    }
    const response = await masterApi.dictionaryApi(REQUEST)
    if (response) {
      if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
        this.setState({
          employeeTypeList: response.rows
        })
      }
    }
  };

  getIndustryTypes = async (TYPE, SKIP, OFFSET, SEARCHVAL) => {
    const REQUEST = {
      dictonary_type_id: TYPE,
      page_number: SKIP,
      page_size: OFFSET,
      search_val: SEARCHVAL
    }
    const response = await masterApi.dictionaryApi(REQUEST)
    if (response) {
      if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
        this.setState({
          industryTypeList: response.rows
        })
      }
    }
  };

  handleSchoolName = (data) => {
    this.setState({
      business_name: data
    })
  };

  handleStartDate = (date, dateString) => {
    this.setState({
      start_date: dateString
    })
  };

  handleEndDate = (date, dateString) => {
    this.setState({
      end_date: dateString
    })
  };

  handleEndDate = (date, dateString) => {
    this.setState({
      end_date: dateString
    })
  };

  handleDesignation = (e) => {
    this.setState({
      designation: e.target.value
    })
  };

  handleEmployement = (e) => {
    this.setState({
      employment_type: e
    })
  };

  handleIndustry = (e) => {
    this.setState({
      industry_type: e
    })
  };

  handleLocation = (e) => {
    this.setState({
      location: e.target.value
    })
  };

  handleDescription = (e) => {
    this.setState({
      description: e.target.value
    })
  };

  handleStillStudying = (e) => {
    this.setState({
      is_current: e.target.checked
    })
  };

  handleSubmit = async () => {
    if (this.state.business_name) {
      this.setState({
        loading: true
      })
      const REQUEST = {
        id: this.state.id,
        business_name: this.state.business_name,
        is_current: this.state.is_current,
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        class_name: this.state.class_name,
        description: this.state.description,
        grade: this.state.grade,
        industry_type: this.state.industry_type,
        employment_type: this.state.employment_type,
        location: this.state.location,
        designation: this.state.designation,
        add_type: 'work',
        person_id: String(getActionByLogin('U').id),
        business_id:null,
        class_id: null,
        school_name: null,
        field_of_study: null
      }
      // console.log(REQUEST)
      // console.log(this.props.editData);
      // return
      if (this.props.editData) {
        const response = await userApi.editEducationApi(REQUEST)
        if (response) {
          if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
            this.setState({
              loading: false
            })
            this.props.onSubmitForm()
          } else {
            this.setState({
              loading: false
            })
            message.error(response.message)
          }
        } else {
          this.setState({
            loading: false
          })
        }
      } else {
        const response = await userApi.addEducationApi(REQUEST)
        if (response) {
          if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
            this.setState({
              loading: false
            })
            this.props.onSubmitForm()
          } else {
            this.setState({
              loading: false
            })
            message.error(response.message)
          }
        } else {
          this.setState({
            loading: false
          })
        }
      }
    } else {
      message.error('Please confirm your School/College/Company Name')
    }
  };

  onSearch = (searchText) => {
    this.fetchData(searchText, 1, 5)
  };

  fetchData = async (SEARCHVALUE, SKIP, OFFSET) => {
    const REQUEST = {
      searchVal: SEARCHVALUE,
      page_number: SKIP,
      page_size: OFFSET
    }
    const response = await businessApi.getBusinessListApi(REQUEST)
    if (response) {
      if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
        const DATA = []
        for (let i = 0; i < response.rows.length; i++) {
          DATA.push({ value: response.rows[i].name })
        }
        console.log(DATA)
        this.setState({
          BUSINESS_LIST: DATA
        })
      }
    }
  };

  onSelect = (data) => {
    this.setState({
      business_name: data
    })
  };

  render () {
    return (
      <>
        <Form layout="vertical"
          name="register"
          onFinish={this.handleSubmit}
          hideRequiredMark>
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                // name="School/College/Company Name"
                label="School/College/Company Name"
                rules={[
                  { required: true, message: 'Please confirm your School/College/Company Name' }
                ]}
              >
                <AutoComplete
                  options={this.state.BUSINESS_LIST}
                  onSelect={this.onSelect}
                  onSearch={this.onSearch}
                  onChange={this.handleSchoolName}
                  placeholder="School/College/Company Name"
                  value={this.state.business_name}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                // name="Start Date"
                label="Start Date"
              >
                <DatePicker
                  onChange={this.handleStartDate}
                  value={this.state.start_date ? moment(this.state.start_date, dateFormat) : null}
                  disabledDate={(d) =>
                    !d || d.isAfter(moment())
                  }
                  format="YYYY-MM-DD"
                  placeholder="yyyy-mm-dd"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                // name="End Date"
                label="End Date"
              >
                <DatePicker
                  onChange={this.handleEndDate}
                  value={this.state.end_date ? moment(this.state.end_date, dateFormat) : null}
                  disabledDate={(d) =>
                    !d || d.isBefore(moment(this.state.start_date))
                  }
                  format="YYYY-MM-DD"
                  placeholder="yyyy-mm-dd"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
              // name="Still Studying"
              >
                <Checkbox onChange={this.handleStillStudying} value={this.state.is_current} >Still studying</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                // name="Designation/Title"
                label="Designation/Title"
              >
                <Input
                  placeholder="Designation/Title"
                  onChange={this.handleDesignation}
                  value={this.state.designation}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                // name="Employment Type"
                label="Employment Type"
              >
                <Select
                  placeholder="Employment Type"
                  value={this.state.employment_type}
                  onChange={this.handleEmployement}
                >
                  {this.state.employeeTypeList.map((item) => (
                    <Select.Option
                      key={item.id}
                      value={item.description}
                    >
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                // name="Industry"
                label="Industry"
              >
                <Select
                  placeholder="Industry Type"
                  value={this.state.industry_type}
                  onChange={this.handleIndustry}
                >
                  {this.state.industryTypeList.map((item) => (
                    <Select.Option
                      key={item.id}
                      value={item.description}
                    >
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                // name="Location"
                label="Location"
              >
                <Input
                  placeholder="Location"
                  onChange={this.handleLocation}
                  value={this.state.location}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Describe about your work"
              >
                <TextArea showCount maxLength={100} onChange={this.handleDescription} value={this.state.description} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button
              loading={this.state.loading}
              style={{ width: '100%' }}
              type="primary"
              htmlType="submit"
            >
              Submit
                        </Button>
          </Form.Item>
        </Form>
      </>
    )
  }
}

WorkForm.protoTypes = { editData: PropTypes.any }
