import { Col, Image, Layout, Row, Space, Tooltip, Typography } from "antd";
import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import { build as appBuild, version as appVersion } from "../../../package.json";
import { isLogin, isUserData } from "../../utils/auth";

import { Link } from "react-router-dom";
import React from "react";

const { Footer } = Layout;
const { Title, Text, Paragraph } = Typography;

class Footercustom extends React.Component {
  componentDidMount() {
    window.addEventListener("storage", () => {
      if (isLogin() && isUserData()) {
        window.location.href = "/";
      }
    });
  }

  render() {
    return (
      <>
        <Footer
          style={{
            backgroundColor: "#eae8e8",
            // maxHeight: '320px',
            padding: "60px 24px 24px 24px",
          }}
        >
          <div style={{ maxWidth: "1256px", marginLeft: "auto", marginRight: "auto" }}>
            <Row gutter={16}>
              <Col span={6} xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 0 }}>
                <a href="https://www.foop.com" target="_blank" rel="noopener noreferrer">
                  <Image
                    width={100}
                    preview={false}
                    style={{ margin: "0 6px 0 0px" }}
                    src={`${process.env.PUBLIC_URL}/logonavbar.svg`}
                    alt="foop-logo"
                  />
                </a>
                <Paragraph>support@foop.com</Paragraph>
              </Col>
              <Col span={6} xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 0 }}>
                <Title level={5}>General</Title>
                <Space direction="vertical">
                  <a href="https://works.foop.com/register" target="_blank" rel="noopener noreferrer">
                    <Text>Sign up</Text>
                  </a>
                  <a href="https://www.foop.com/about_us" target="_blank" rel="noopener noreferrer">
                    <Text>About</Text>
                  </a>
                  <a href="https://www.foop.com/support" target="_blank" rel="noopener noreferrer">
                    <Text>Support</Text>
                  </a>
                  <a href="https://www.foop.com/callback" target="_blank" rel="noopener noreferrer">
                    <Text>Contact us</Text>
                  </a>
                </Space>
              </Col>
              <Col span={6} xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 0 }}>
                <Title level={5}>Policies</Title>
                <Space direction="vertical">
                  <a href="https://www.foop.com/terms-conditions" target="_blank" rel="noopener noreferrer">
                    <Text>Terms of Use</Text>
                  </a>
                  <a href="https://www.foop.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                    <Text>Privacy</Text>
                  </a>
                  <a href="https://www.foop.com/cookies-policy" target="_blank" rel="noopener noreferrer">
                    <Text>Cookies</Text>
                  </a>
                </Space>
              </Col>
              <Col span={6} xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 0 }}>
                <Title level={5}>Social</Title>
                <Space direction="vertical">
                  <a href="https://www.facebook.com/fooppartner" target="_blank" rel="noopener noreferrer">
                    <FacebookOutlined /> <Text>Facebook</Text>
                  </a>
                  <a href="https://www.linkedin.com/company/foop-in/" target="_blank" rel="noopener noreferrer">
                    <LinkedinOutlined /> <Text>Linkedin</Text>
                  </a>
                  <a href="https://www.instagram.com/foop/" target="_blank" rel="noopener noreferrer">
                    <InstagramOutlined /> <Text>Instagram</Text>
                  </a>
                  <a href="https://twitter.com/foopnetree?lang=en" target="_blank" rel="noopener noreferrer">
                    <TwitterOutlined /> <Text>Twitter</Text>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCRHOp2HlUmpAOZ-vm4P7LeQ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <YoutubeOutlined /> <Text>Youtube</Text>
                  </a>
                </Space>
              </Col>
            </Row>
          </div>
        </Footer>
        <Footer style={{ backgroundColor: "#e0dede" }}>
          Copyright ©2020-21, All rights reserved | by
          <Link to="/">
            <strong> foop</strong>
          </Link>
          {/* <Text> Netree E-Services Pvt Ltd</Text> */}
          <span style={{ float: "right", cursor: "pointer" }}>
            <Text>
              <Tooltip title={appBuild > 0 && `Build(${appBuild})`}>Version {appVersion}</Tooltip>
            </Text>
          </span>
        </Footer>
      </>
    );
  }
}

export default Footercustom;
