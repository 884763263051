import React from 'react'
import PropTypes from 'prop-types'
import { Spin, List, Checkbox } from 'antd'

import UserApi from '../../apis/user_api'
import { RESPONSE_STATUS_CODES } from '../../utils/enum_config'

const userApi = new UserApi()

export default class RateExpertiseForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      EX_CAT_TYPE_ID: this.props.EX_CAT_TYPE_ID,
      EX_CAT_NAME: this.props.EX_CAT_NAME,
      EX_CAT_PID_VALUE: this.props.EX_CAT_PID_VALUE,
      EX_CAT_ID: this.props.EX_CAT_ID,
      SUBJECT_CLASS_ID: this.props.SUBJECT_CLASS_ID,
      ACTION_BY_ID: this.props.ACTION_BY_ID,
      ACTION_BY_TYPE: this.props.ACTION_BY_TYPE,
      data: [],
      SELECTED_EXPERTISE: [],
      loading: false
    }

    this.next = this.next.bind(this)
  }

    componentDidMount = async () => {
      this.getExpertise(null, this.state.EX_CAT_NAME, null, null, null, this.state.ACTION_BY_ID, 1, 1000)
    };

    getExpertise = async (TYPE_ID, NAME, PID_VALE, ID, CLASSID, USERID, SKIP, OFFSET) => {
      this.setState({
        loading: true
      })
      const REQUEST =
        {
          id: null,
          standard_expertise_category_types_id: TYPE_ID,
          standard_expertise_category_name: NAME,
          standard_expertise_category_pid_val: PID_VALE,
          standard_expertise_category_id: NAME === 'Subject' ? '6' : null,
          subject_class_id: NAME === 'Subject' ? CLASSID : null,
          person_id: USERID,
          given_by_id: USERID,
          endorse_content_id: null,
          page_number: SKIP,
          page_size: OFFSET
        }
      const response = await await userApi.getExpertiseApi(REQUEST)
      if (response) {
        if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
          this.setState({
            data: response.rows,
            loading: false
          })
        }
      }
    };

    next () {
      console.log('Dataaa' + JSON.stringify(this.state.SELECTED_EXPERTISE))
    }

    onChange = (e) => {
      if (e.target.checked) {
        this.state.SELECTED_EXPERTISE.push(e.target.value)
        this.setState({
          SELECTED_EXPERTISE: this.state.SELECTED_EXPERTISE
        })
      } else {
        const index = this.state.SELECTED_EXPERTISE.indexOf(e.target.value)
        this.state.SELECTED_EXPERTISE.splice(index, 1)
        this.setState({
          SELECTED_EXPERTISE: this.state.SELECTED_EXPERTISE
        })
      }
      this.props.onSubmitForm({ KEY: null, VALUE: this.state.SELECTED_EXPERTISE })
    }

    render () {
      return (
            <>
                <div>
                    {
                        this.state.loading
                          ? <Spin style={{ paddingLeft: '50%', paddingTop: '10%' }} />
                          : <List
                                itemLayout="horizontal"
                                dataSource={this.state.data}
                                renderItem={item => (
                                    <List.Item
                                        actions={
                                            [
                                                <>
                                                    <Checkbox value={item} onChange={this.onChange}></Checkbox>
                                                </>
                                            ]}
                                    >
                                        <List.Item.Meta
                                            title={item.expertise_goal_code}
                                            description={''}
                                        />
                                    </List.Item>
                                )}
                            />
                    }

                </div>
            </>
      )
    }
}

RateExpertiseForm.protoTypes = {
  EX_CAT_TYPE_ID: PropTypes.any,
  EX_CAT_NAME: PropTypes.any,
  EX_CAT_PID_VALUE: PropTypes.any,
  EX_CAT_ID: PropTypes.any,
  SUBJECT_CLASS_ID: PropTypes.any
}
