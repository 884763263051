import { Redirect, Route } from "react-router-dom";

import FooterCustom from "../components/cards/footer";
import HeaderHelmet from "../components/common/HeaderHelmet";
import { Layout } from "antd";
import PublicNavbar from "../components/navbar/public_navbar";
import React from "react";
import { isLogin } from "../utils/auth";

const { Header } = Layout;

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLogin() && !rest.allowedAfterLogin ? (
        <Redirect to="/" />
      ) : (
        <>
          {!rest.disableHeader && <HeaderHelmet {...rest} />}
          <Layout>
            {!rest.hidePublicNavbar && (
              <Header
                style={{
                  backgroundColor: "#fff",
                  position: "fixed",
                  zIndex: 1,
                  width: "100%",
                }}
              >
                <PublicNavbar />
              </Header>
            )}
            <Layout
              style={{
                minHeight: rest.hidePublicNavbar ? "100vh" : `calc(100vh - 64px)`,
                overflowX: "hidden",
                marginTop: !rest.hidePublicNavbar && "64px",
              }}
            >
              <Component {...props} />
            </Layout>
            <FooterCustom />
          </Layout>
        </>
      )
    }
  />
);

export default PublicRoute;
