import { Button, Card, List, Typography } from "antd";
import { RESOLUTION_TYPE, SERVICE_TYPE } from "../../utils/enum_config";
import React, { Component } from "react";

import { getActionByLogin } from "../../utils/auth";
import { withGlobalSettingsWrapper } from "../common/GlobalSettingsWrapper";

class BranchCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMyCard: getActionByLogin("U").id == this.props.VIEWID,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.VIEWID !== this.props.VIEWID) {
      this.setState({ isMyCard: getActionByLogin("U").id == this.props.VIEWID });
    }
  }

  render() {
    return (
      <>
        <Card
          title={this.props.DETAILS ? <Typography.Title level={5}>{this.props.DETAILS.title}</Typography.Title> : ""}
        >
          {/* Selected branch item */}
          <List.Item
            style={{
              cursor: this.state.isMyCard && "pointer",
              padding: "12px",
            }}
            className="removeActionMargin"
            actions={[
              this.state.isMyCard && (
                <Button
                  key={`changeItem${this.props.DETAILS.branch_id}`}
                  style={{ paddingRight: 0, paddingLeft: 12 }}
                  type="link"
                  onClick={() => this.props.manageBranchChange(this.props.DETAILS)}
                >
                  Change
                </Button>
              ),
            ]}
          >
            <List.Item.Meta
              onClick={() => this.state.isMyCard && this.props.manageBranchChange(this.props.DETAILS)}
              style={{ alignItems: "center" }}
              avatar={this.props.getAvatar(
                this.props.themeItems.themeColors,
                RESOLUTION_TYPE.SIXFOUR,
                SERVICE_TYPE.BUSINESS,
                this.props.DETAILS.image,
                this.props.DETAILS.name
              )}
              title={
                <div style={{ width: "450px" }} className="wrapTextEllipsis">
                  {this.props.DETAILS?.name}
                </div>
              }
              description={
                <div style={{ width: "450px" }} className="wrapTextEllipsis">
                  {this.props.DETAILS?.description}
                </div>
              }
            />
          </List.Item>
        </Card>
      </>
    );
  }
}

export default withGlobalSettingsWrapper(BranchCard);
