export const Colors = {
  primary: "#00243d",
  primary85: "#00243dD9",
  primary65: "#00243dA6",
  primary35: "#00243d59",
  primary10: "#00243d1A",

  white: "#ffffff",

  black: "#000000",
  black85: "#000000D9",
  black65: "#000000A6",
  black35: "#00000059",
  black10: "#0000001A",

  greyback: "#f3f2f2",

  blogtag: "#FD7272",
  noticetag: "#82589F",
  qandatag: "#FC427B",
  newstag: "#353b48",
  polltag: "#B33771",
  notag: "#1B9CFC",
  lessontag: "#FA9D45",

  btnBlue: "#0084FF",
  linkColor: "#1A73E8",

  appColorTeal: "#1FC498",
};
