import { Button, Card, Col, Form, Image, Input, Row, Select, Tabs, Typography, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Link, withRouter } from "react-router-dom";
import { login, logout, setUser, setVirtualToken } from "../../utils/auth";

import ApiResponseCode from "../../utils/api_response_code";
import { DOMAIN } from "../../utils/api_config";
import React from "react";
import { withGlobalSettingsWrapper } from "../../components/common/GlobalSettingsWrapper";

// import SocialButton from '../../components/functions/sociallogin';

const { Title } = Typography;
const { TabPane } = Tabs;

const error = (content) => {
  message.error(content);
};

const apiResponseCode = new ApiResponseCode();
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      mobile: null,
      password: null,
      token: null,
      error: null,
      isLoading: false,
      countriesCodeList: [],

      mobile_country_code: null,
    };

    this.history = props.history;
  }

  componentDidMount() {
    this.getCountriesCodeList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.history = this.props.history;
    }
  }

  getCountriesCodeList = async () => {
    const { default: MasterApi } = await import("../../apis/master_api");
    const masterApi = new MasterApi();
    const response = await masterApi.countryCodeListApi();
    if (response) {
      if (response.statusCode === apiResponseCode.SUCCESS_CODE) {
        this.setState({
          countriesCodeList: response.rows,
        });

        await import("reqwest")
          .then((module) => module.default)
          .then((reqwest) =>
            reqwest({
              url: "https://ipapi.co/json/",
              type: "json",
              method: "get",
              success: (res) => {
                this.setState({ mobile_country_code: res.country_calling_code });
              },
            })
          );
      }
    }
  };

  handleMobile = (e) => {
    const { value } = e.target;
    const reg = /^\d*$/;
    if ((!isNaN(value) && reg.test(value)) || value === "") {
      this.setState({ mobile: value });
      return e && value;
    }
    return e && this.state.mobile;
  };

  handleSubmit = async (values) => {
    const data = {
      ...values,
      mobile_country_code: values.mobile_country_code && values.mobile_country_code.replace("+", ""),
      app_type: "FOOPWORKS",
    };

    this.setState({ isLoading: true });

    const { default: UserApi } = await import("../../apis/user_api");
    const userApi = new UserApi();
    const loginResult = await userApi.login(data);
    if (loginResult) {
      // console.log(loginResult)
      if (loginResult.statusCode === apiResponseCode.SUCCESS_CODE) {
        login(loginResult.rows.token);
        this.setState({
          token: loginResult.rows.token,
        });
        const userResult = await userApi.me();
        if (!userResult.rows.first_name) {
          setVirtualToken(loginResult.rows.token);
          logout();
          return (window.location = "/register-details");
        }
        if (userResult.rows.businesses.length === 0 || userResult.businesses === null) {
          setVirtualToken(loginResult.rows.token);
          logout();
          return (window.location = "/connect-business");
        }
        if (userResult.statusCode === apiResponseCode.SUCCESS_CODE) {
          setUser(userResult.rows);
          this.navigateToHomePage();
        } else {
          this.setState({
            isLoading: false,
          });
          alert(userResult.message);
        }
      } else {
        this.setState({
          error: loginResult.message,
          isLoading: false,
        });
        error(loginResult.message);
      }
    } else {
      this.setState({
        error: "",
        isLoading: false,
      });
    }
  };

  navigateToHomePage() {
    const lastLoaded = localStorage.getItem("lastLoaded");
    if (lastLoaded) {
      window.location.replace(lastLoaded);
      localStorage.removeItem("lastLoaded");
    } else {
      window.location.replace("/en-in/feeds");
    }
  }

  render() {
    return (
      <>
        <Row style={{ justifyContent: "center" }}>
          <Col
            style={{
              padding: "32px 12px",
            }}
          >
            <Card style={{ minWidth: "400px", borderRadius: "12px" }}>
              {this.history.location.pathname === "/login" && (
                <div style={{ textAlign: "center" }}>
                  <Link to="/home">
                    <Image
                      width={100}
                      preview={false}
                      style={{ margin: "0 6px 0 0px" }}
                      src={
                        this.props.themeItems.themesSet
                          ? DOMAIN + this.props.themeItems.appImage
                          : `${process.env.PUBLIC_URL}/logonavbar.svg`
                      }
                      alt={`${this.props.themeItems.companyAppName}_logo`}
                    />
                  </Link>
                  <span>
                    <Title level={3}>Sign In</Title>
                  </span>
                </div>
              )}
              <Tabs type="card">
                <TabPane
                  tab={
                    <span>
                      <MailOutlined />
                      With Email
                    </span>
                  }
                  key="1"
                >
                  <div>
                    <span>
                      <Title level={3}>Email Sign In</Title>
                      {/* <p className="redmessage">{this.state.error}</p> */}
                    </span>
                  </div>
                  <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={this.handleSubmit}
                    layout="vertical"
                  >
                    <Form.Item
                      name="username"
                      label="Email"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please input your email-ID!",
                        },
                      ]}
                    >
                      <Input
                        prefix={<MailOutlined className="site-form-item-icon" />}
                        placeholder="abc@xyz.com"
                        value={this.state.email}
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Password!",
                        },
                      ]}
                    >
                      <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                        value={this.state.password}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                      />
                    </Form.Item>
                    <Form.Item style={{ textAlign: "right" }}>
                      <Link to="/forgot-password/email">Forgot password?</Link>
                    </Form.Item>

                    <Form.Item style={{ textAlign: "center" }}>
                      <Button
                        loading={this.state.isLoading}
                        style={{ width: "100%" }}
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                      >
                        Log in
                      </Button>
                      You do not have an account?
                      <Link className="already_acc" to="/register">
                        {" "}
                        Sign Up
                      </Link>
                    </Form.Item>
                    {/* <Row gutter={16}>
                    <Col span={12} margin="auto">
                      <SocialButton
                        style={{ display: 'block', margin: 'auto', padding: '0px 24px', backgroundColor: '#3b5998', color: this.props.themeItems.themeColors.white, borderRadius: 4, border: '0px' }}
                        provider="facebook"
                        appId="YOUR_APP_ID"
                        onLoginSuccess={handleSocialLogin}
                        onLoginFailure={handleSocialLoginFailure}
                      >
                        <FacebookOutlined />
{' '}
Login with Facebook
                      </SocialButton>
                    </Col>
                    <Col span={12}>
                      <SocialButton
                        style={{ display: 'block', margin: 'auto', padding: '0px 24px', backgroundColor: '#4285F4', color: this.props.themeItems.themeColors.white, borderRadius: 4, border: '0px' }}
                        provider="google"
                        appId="YOUR_APP_ID"
                        onLoginSuccess={handleSocialLogin}
                        onLoginFailure={handleSocialLoginFailure}
                      >
                        <GoogleOutlined />
{' '}
Login with Google
                      </SocialButton>
                    </Col>
                  </Row> */}
                  </Form>
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <PhoneOutlined />
                      With Phone
                    </span>
                  }
                  key="2"
                >
                  <div>
                    <span>
                      <Title level={3}>Phone Sign In</Title>
                      {/* <p className="redmessage">{this.state.error}</p> */}
                    </span>
                  </div>
                  <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={this.handleSubmit}
                    layout="vertical"
                  >
                    <Form.Item
                      name="mobile"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Number!",
                        },
                        {
                          len: 10,
                          message: "Please enter all 10 digits!",
                        },
                      ]}
                      getValueFromEvent={this.handleMobile}
                      label="Mobile Number"
                    >
                      <Input
                        placeholder="9999999999"
                        min={1}
                        maxLength={10}
                        addonBefore={
                          <Form.Item name="mobile_country_code" noStyle initialValue={this.state.mobile_country_code}>
                            <Select
                              style={{ width: 80 }}
                              showSearch
                              filterOption={(input, option) =>
                                option.children.replace("+", "").indexOf(input.replace("+", "")) >= 0
                              }
                            >
                              {this.state.countriesCodeList.map((ITEM) => (
                                <Select.Option key={ITEM.code} value={ITEM.dial_code}>
                                  {ITEM.dial_code}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        }
                        type="text"
                        value={this.state.mobile}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Password!",
                        },
                      ]}
                    >
                      <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                        value={this.state.password}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                      />
                    </Form.Item>
                    <Form.Item style={{ textAlign: "right" }}>
                      <Link to="/forgot-password/mobile">Forgot password?</Link>
                    </Form.Item>

                    <Form.Item style={{ textAlign: "center" }}>
                      <Button
                        loading={this.state.isLoading}
                        style={{ width: "100%" }}
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                      >
                        Log in
                      </Button>
                      You do not have an account?
                      <Link className="already_acc" to="/register">
                        {" "}
                        Sign Up
                      </Link>
                    </Form.Item>
                    {/* <Row gutter={16}>
                    <Col span={12} margin="auto">
                      <SocialButton
                        style={{ display: 'block', margin: 'auto', padding: '0px 24px', backgroundColor: '#3b5998', color: this.props.themeItems.themeColors.white, borderRadius: 4, border: '0px' }}
                        provider="facebook"
                        appId="YOUR_APP_ID"
                        onLoginSuccess={handleSocialLogin}
                        onLoginFailure={handleSocialLoginFailure}
                      >
                        <FacebookOutlined />
{' '}
Login with Facebook
                      </SocialButton>
                    </Col>
                    <Col span={12}>
                      <SocialButton
                        style={{ display: 'block', margin: 'auto', padding: '0px 24px', backgroundColor: '#4285F4', color: this.props.themeItems.themeColors.white, borderRadius: 4, border: '0px' }}
                        provider="google"
                        appId="YOUR_APP_ID"
                        onLoginSuccess={handleSocialLogin}
                        onLoginFailure={handleSocialLoginFailure}
                      >
                        <GoogleOutlined />
{' '}
Login with Google
                      </SocialButton>
                    </Col>
                  </Row> */}
                  </Form>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(withGlobalSettingsWrapper(Login));
