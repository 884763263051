/*  Props for ManageBizTable:
    tableData= table data
    getTableData = function that fetches table data
    subHeight = height to subtract
    rowsSelected = send selected rows data to parent
    columns = columns for the table
    paginationTotal = total number of records 
    rowKey = string to uniquely identify record
*/

import React, { Component } from "react";

import withLazyWrapper from "components/common/withLazyWrapper";

const Table = withLazyWrapper(
  () => import("antd"),
  (module) => module.Table,
  null
);

export default class ManageBizTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: false,
        showQuickJumper: true,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.paginationTotal !== this.props.paginationTotal) {
      this.setState({ pagination: { ...this.state.pagination, total: this.props.paginationTotal } });
    }
    if (prevProps.currPageNumber !== this.props.currPageNumber) {
      this.setState({ pagination: { ...this.state.pagination, current: this.props.currPageNumber } });
    }
  }

  render() {
    return (
      <Table
        dataSource={this.props.tableData}
        loading={this.props.loading}
        rowKey={this.props.rowKey}
        pagination={this.props.paginationTotal <= 10 ? false : this.state.pagination}
        onChange={(pagination) => {
          pagination.current !== this.state.pagination.current && this.props.getTableData(pagination.current);
          this.setState({ pagination });
        }}
        scroll={{
          y: window.innerHeight - this.props.subHeight,
        }}
        rowSelection={this.props.rowsSelected}
        columns={this.props.columns}
      />
    );
  }
}
