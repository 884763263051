import "./app.less";

import { APP_CODES, RESPONSE_STATUS_CODES } from "./utils/enum_config";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import {
  FCM_ID,
  TOKEN_KEY,
  VAPITKEY,
  clearCacheAndHardReload,
  getActionByLogin,
  getUser,
  isLogin,
  logout,
  setUser,
  setVirtualToken,
} from "./utils/auth";

import { Colors } from "./assets/colors";
import GlobalSettingsContext from "./utils/global_settings_context";
import IndexHome from "./views/public/index_home";
import { PARTNER_DOMAIN } from "./utils/api_config";
import PublicRoute from "./routes/public_route";
import React from "react";
import { message } from "antd";
import withLazyWrapper from "components/common/withLazyWrapper";

const PrivateRoute = withLazyWrapper(
  () => import(/* webpackPrefetch : true */ "./routes/private_route"),
  (module) => module.default,
  null
);
const NoMatchPage = withLazyWrapper(
  () => import("./views/NoMatchPage/NoMatchPage"),
  (module) => module.default,
  null
);
const AccessDenied = withLazyWrapper(
  () => import("./views/AccessDenied/AccessDenied"),
  (module) => module.default,
  null
);

const Aboutus = withLazyWrapper(() => import("./views/public/about_us"));
const Callback = withLazyWrapper(() => import("./views/public/request_callback"));
const ConnectBusiness = withLazyWrapper(() => import("./views/public/connectBusiness"));
const CookiesPolicy = withLazyWrapper(() => import("./views/public/cookies-policy"));
const CreatePassword = withLazyWrapper(() => import("./views/forgot-password/create_password"));
const DnsSetupInstructions = withLazyWrapper(() => import("./views/public/dns_setup"));
const IndexFeatures = withLazyWrapper(() => import(/* webpackPrefetch : true */ "./views/public/features"));
const IndexPricing = withLazyWrapper(() => import("./views/public/pricing"));
const JoinProfilePage = withLazyWrapper(() => import("./views/public/join_profile"));
const Login = withLazyWrapper(() => import(/* webpackPrefetch : true */ "./views/public/login"));
const PolicyConditions = withLazyWrapper(() => import("./views/public/policy-conditions"));
const Register = withLazyWrapper(() => import("./views/public/register"));
const RegisterDetails = withLazyWrapper(() => import("./views/public/registerDetails"));
const SendOtp = withLazyWrapper(() => import("./views/forgot-password/send_otp"));
const Support = withLazyWrapper(() => import("./views/public/support"));
const TermsConditions = withLazyWrapper(() => import("./views/public/terms-condition"));
const Useraccountsetting = withLazyWrapper(() => import("./views/settings-user/user-account-settings"));
const Userpasswordsetting = withLazyWrapper(() => import("./views/settings-user/password-settings"));
const VerifyOtp = withLazyWrapper(() => import("./views/otp/verify_otp"));
const USER_MANUAL_MODULE = withLazyWrapper(() => import("./modules/user-manual/controller/UserManual"));

const CreateBlogPage = withLazyWrapper(() => import("./views/blog/create-blogs"));
const CirclePage = withLazyWrapper(
  () => import(/* webpackPrefetch : true */ "./views/feed/feed_page"),
  (module) => module.default,
  null
);
const GlobalSearchPage = withLazyWrapper(() => import("./views/global-search/global-search-page"));
const UserActivityView = withLazyWrapper(() => import(/* webpackPrefetch : true */ "./views/feed/activityRoutesView"));
const TEAM_MODULE = withLazyWrapper(() => import("./views/network/network"));
const PARTNERS_MODULE = withLazyWrapper(() => import("./views/partners/partners-page"));
const ROOM_MODULE = withLazyWrapper(() => import("./views/rooms/rooms-page"));
const CAMPAIGNS_MODULE = withLazyWrapper(() => import(/* webpackPrefetch : true */ "./modules/campaigns/"));
const CHAT_MODULE = withLazyWrapper(() => import("./modules/chat/view/chat-page"));
const MEETING_MODULE = withLazyWrapper(() => import(/* webpackPrefetch : true */ "./modules/meeting/view/meeting"));
const DRIVE_MODULE = withLazyWrapper(() => import("./modules/drive/view/drive"));
const HELPDESK_MODULE = withLazyWrapper(() => import(/* webpackPrefetch : true */ "./modules/helpdesk/view/helpdesk"));
const LESSON_MODULE = withLazyWrapper(() => import("./modules/lesson/view/lesson"));
const BUSINESS_MODULE = withLazyWrapper(() =>
  import(/* webpackPrefetch : true */ "./views/manage_business/manage_business")
);

export default class App extends React.Component {
  constructor(props) {
    if (!getUser()) {
      const pathSplit = window.location.pathname.split("/");
      const bizSlug = new URLSearchParams(window.location.search).get("b");
      if (pathSplit?.includes("feed") && bizSlug) {
        const postId = window.location.pathname.split("/").at(-2);
        postId && window.location.replace(`${PARTNER_DOMAIN}/post/${bizSlug}/${postId}`);
        return;
      }
      if (pathSplit?.includes("campaigns") && bizSlug) {
        const campaignId = window.location.pathname.split("view/")[1].split("?")[0];
        campaignId && window.location.replace(`${PARTNER_DOMAIN}/campaigns/${bizSlug}/${campaignId}`);
        return;
      }
    }

    clearCacheAndHardReload();
    super(props);

    this.state = {
      themeColors: Colors,
      // eslint-disable-next-line no-undef
      appImage: `${process.env.PUBLIC_URL}/logonavbar.svg`,
      companyAppName: "Foopworks",
      themesSet: false,
      themesLoaded: false,

      appSettings: [],
      appSettingsLoading: false,
      // disablePage: false,
    };

    if (
      !isLogin() &&
      !["/", "/login", "/register", "register-details", "connect-business"].includes(
        window.location.pathname.split("/").filter(Boolean).pop()
      )
    ) {
      localStorage.setItem("lastLoaded", window.location.pathname);
    }
  }

  async componentDidMount() {
    if (process.env.NODE_ENV !== "development") {
      console.log = function () {};
    }

    if (!("Notification" in window)) {
      message.error("This browser does not support desktop notification");
    } else if (Notification.permission !== "granted") {
      const { default: firebase } = await import("./firebase");
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          firebase
            .messaging()
            .getToken({ vapidKey: VAPITKEY })
            .then(
              (token) => {
                localStorage.setItem(FCM_ID, token);
              },
              (err) => message.warn("Notifications have not been allowed...")
            );
        }
      });
    }

    if (getUser()) {
      localStorage.removeItem("lastLoaded");
      if (getUser().id) {
        this.refreshUser();
        this.loadAppSettings();
      }
    } else {
      this.getThemeData();
    }
  }

  loadAppSettings = async () => {
    this.setState({ appSettingsLoading: true });

    const { default: CompanyApi } = await import("./apis/business_api");
    const companyApi = new CompanyApi();
    const payload = {
      business_id: getActionByLogin("B").id,
      code: "",
      app_type: "FOOPWORKS",
      order_by: "frequent",
    };

    try {
      const appSettingsResponse = await companyApi.listAppAccessApi(payload);
      if (appSettingsResponse?.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
        this.setState({ appSettings: appSettingsResponse.rows });
      } else {
        message.error(appSettingsResponse?.message || "Problem retrieving access details...");
      }
    } catch (e) {
      console.log(e);
      message.error("Problem retrieving access details....");
    }
    this.setState({ appSettingsLoading: false });
  };

  checkAppAccess = (appCode) => {
    const appItem = this.state.appSettings?.find((el) => el.code === appCode);
    if (appItem) {
      const { is_available, role_code } = appItem;
      return { is_available, isAppAdmin: role_code === "admin", role_code };
    } else {
      return null;
    }
  };

  applyTheme = async (themeData) => {
    if (themeData) {
      this.setState({ themesSet: true });
    } else {
      this.getThemeData();
      return;
    }

    if (themeData.primary_color) {
      const newPrimary = themeData.primary_color.replace(/#(..)(......)/, "#$2$1");
      this.setState({
        themeColors: {
          ...this.state.themeColors,
          primary: newPrimary,
          primary10: newPrimary.slice(0, 7) + "D9",
          primary35: newPrimary.slice(0, 7) + "A6",
          primary65: newPrimary.slice(0, 7) + "59",
          primary85: newPrimary.slice(0, 7) + "1A",
        },
      });

      await import("antd/lib/config-provider/index")
        .then((module) => module.default)
        .then((ConfigProvider) =>
          ConfigProvider.config({
            theme: {
              primaryColor: newPrimary,
              linkColor: newPrimary,
            },
          })
        );
    }

    if (themeData.logo) {
      this.setState({
        appImage: themeData.logo,
      });
    }
    if (themeData.title) {
      this.setState({
        companyAppName: themeData.title,
      });
    }
    this.setState({ themesLoaded: true });
  };

  getThemeData = async () => {
    const { default: CompanyApi } = await import("./apis/business_api");
    const companyApi = new CompanyApi();
    const themeResponse = await companyApi.getCustomUiApi({
      business_domain: window.location.href.split("/").filter(Boolean)[1],
    });

    if (themeResponse) {
      if (themeResponse.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
        this.applyTheme(themeResponse.rows);
      } else {
        this.setState({
          // disablePage: true,
          themesLoaded: true,
        });
      }
    } else {
      message.error("Problem loading company theme...");
    }
  };

  refreshUser = async () => {
    try {
      const { default: UserApi } = await import("./apis/user_api");
      const userApi = new UserApi();
      const userResult = await userApi.me();
      if (userResult.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
        if (
          !["register-details", "connect-business"].includes(window.location.pathname.split("/").filter(Boolean).pop())
        ) {
          const token = localStorage.getItem(TOKEN_KEY);
          if (!userResult.rows.first_name) {
            setVirtualToken(token);
            logout();
            window.location.replace("/register-details");
            return;
          } else if (!userResult.rows.businesses.length > 0) {
            setVirtualToken(token);
            logout();
            window.location.replace("/connect-business");
            return;
          }
        }

        const businessDetails = userResult.rows.businesses[0];
        const themeDet = {
          logo: businessDetails.custom_logo,
          primary_color: businessDetails.custom_primary_color,
          title: businessDetails.name,
        };
        this.applyTheme(themeDet);
        setUser(userResult.rows);
      } else {
        message.error("Session timed out!");
        logout();
      }
    } catch (e) {
      this.setState({ themesLoaded: true });
      message.error("Connection issue. Please try after some time!");
      console.log(e.message);
    }
  };

  render() {
    return (
      <BrowserRouter>
        <GlobalSettingsContext.Provider
          value={{
            themeItems: {
              themeColors: this.state.themeColors,
              companyAppName: this.state.companyAppName,
              appImage: this.state.appImage,
              themesSet: this.state.themesSet,
              getThemeData: this.getThemeData,
            },
            appAccess: {
              appSettings: this.state.appSettings,
              checkAppAccess: this.checkAppAccess,
              refetchAppSettings: this.loadAppSettings,
            },
          }}
        >
          <Switch>
            <Route exact path="/">
              <Redirect to={isLogin() ? "/en-in/feeds/" : "/home"} />
            </Route>

            <PublicRoute component={IndexHome} path="/home" exact />
            <PublicRoute hidePublicNavbar={true} component={Login} path="/login" title="Login" exact />
            <PublicRoute hidePublicNavbar={true} component={Register} path="/register" title="Register" exact />
            <PublicRoute
              hidePublicNavbar={true}
              component={VerifyOtp}
              path="/verify-otp/:otpUse/"
              title="Verify OTP"
              exact
            />
            <PublicRoute
              hidePublicNavbar={true}
              component={SendOtp}
              path="/forgot-password/:idType"
              title="Forgot Password"
              exact
            />
            <PublicRoute
              hidePublicNavbar={true}
              component={CreatePassword}
              path="/password-reset"
              title="Reset Password"
              exact
            />

            <PublicRoute
              allowedAfterLogin={true}
              component={RegisterDetails}
              path="/register-details"
              title="Register Details"
              exact
            />
            <PublicRoute
              allowedAfterLogin={true}
              component={ConnectBusiness}
              path="/connect-business"
              title="Register Business"
              exact
            />

            <PublicRoute allowedAfterLogin={true} component={IndexFeatures} path="/features" title="Features" exact />
            <PublicRoute allowedAfterLogin={true} component={IndexPricing} path="/pricing" title="Pricing" exact />
            <PublicRoute allowedAfterLogin={true} component={Aboutus} path="/about_us" title="About Us" exact />
            <PublicRoute allowedAfterLogin={true} component={Callback} path="/callback" title="Callback" exact />
            <PublicRoute allowedAfterLogin={true} component={Support} path="/support" title="Support" exact />
            <PublicRoute
              allowedAfterLogin={true}
              component={PolicyConditions}
              path="/privacy-policy"
              title="Policy Conditions"
              exact
            />
            <PublicRoute
              allowedAfterLogin={true}
              component={CookiesPolicy}
              path="/cookies-policy"
              title="Cookies Policy"
              exact
            />
            <PublicRoute
              allowedAfterLogin={true}
              component={TermsConditions}
              path="/terms-conditions"
              title="Terms and Conditions"
              exact
            />
            <PublicRoute
              allowedAfterLogin={true}
              component={DnsSetupInstructions}
              path="/dns-setup"
              title="Set up MX records for foop email"
              exact
            />

            <PublicRoute
              hidePublicNavbar={true}
              allowedAfterLogin={true}
              disableHeader={true}
              component={USER_MANUAL_MODULE}
              path={`/en-in/user-manual/:companyName/:manualId?`}
              title="User Manual"
            />

            <PrivateRoute component={UserActivityView} path="/en-in/feed/:postType/" title="Activity" />
            <PrivateRoute component={CirclePage} path="/en-in/feeds/" exact title="Circle" />
            <PrivateRoute component={CreateBlogPage} path="/en-in/create-blog" exact title="Creat Blog" />
            <PrivateRoute component={GlobalSearchPage} path="/en-in/search/:searchType" title="Search" />
            <PrivateRoute
              component={Useraccountsetting}
              path="/en-in/accounts-settings"
              exact
              title="User Account Settings"
            />
            <PrivateRoute
              component={Userpasswordsetting}
              path="/en-in/password-settings"
              exact
              title="User Password Settings"
            />
            <PrivateRoute component={JoinProfilePage} path="/join" exact title="Join" />
            <PrivateRoute
              component={TEAM_MODULE}
              path="/en-in/network"
              title="Coworkers"
              appSettingsLoading={this.state.appSettingsLoading}
              checkAppAccess={this.checkAppAccess}
              appCode={APP_CODES.COWORKER}
            />
            <PrivateRoute
              component={ROOM_MODULE}
              path="/en-in/rooms"
              title="Rooms"
              appSettingsLoading={this.state.appSettingsLoading}
              checkAppAccess={this.checkAppAccess}
              appCode={APP_CODES.ROOM}
            />
            <PrivateRoute
              component={PARTNERS_MODULE}
              path="/en-in/partners"
              title="Partners"
              appSettingsLoading={this.state.appSettingsLoading}
              checkAppAccess={this.checkAppAccess}
              appCode={APP_CODES.CHANNELPARTNER}
            />
            {/* <PrivateRoute component={companyProfile} path="/company/:name/" title="" /> */}
            <PrivateRoute
              component={BUSINESS_MODULE}
              path="/en-in/manage-business"
              title="Manage Business"
              appSettingsLoading={this.state.appSettingsLoading}
              checkAppAccess={this.checkAppAccess}
              appCode={APP_CODES.SETTINGS}
            />
            <PrivateRoute
              component={CHAT_MODULE}
              path="/en-in/chat"
              title="Messenger"
              appSettingsLoading={this.state.appSettingsLoading}
              checkAppAccess={this.checkAppAccess}
              appCode={APP_CODES.CHAT}
            />
            <PrivateRoute
              component={MEETING_MODULE}
              path="/meeting"
              title="Meeting"
              appSettingsLoading={this.state.appSettingsLoading}
              checkAppAccess={this.checkAppAccess}
              appCode={APP_CODES.MEETING}
            />
            <PrivateRoute
              component={DRIVE_MODULE}
              path="/en-in/drive"
              title="Brand Drive"
              appSettingsLoading={this.state.appSettingsLoading}
              checkAppAccess={this.checkAppAccess}
              appCode={APP_CODES.DRIVE}
            />
            <PrivateRoute
              component={HELPDESK_MODULE}
              path="/en-in/helpdesk"
              title="Helpdesk"
              appSettingsLoading={this.state.appSettingsLoading}
              checkAppAccess={this.checkAppAccess}
              appCode={APP_CODES.HELPDESK}
            />
            <PrivateRoute
              component={LESSON_MODULE}
              path="/en-in/lesson"
              title="Courses"
              appSettingsLoading={this.state.appSettingsLoading}
              checkAppAccess={this.checkAppAccess}
              appCode={APP_CODES.LESSON}
            />
            <PrivateRoute
              component={CAMPAIGNS_MODULE}
              path="/en-in/campaigns"
              title="Campaigns"
              appSettingsLoading={this.state.appSettingsLoading}
              checkAppAccess={this.checkAppAccess}
              appCode={APP_CODES.CAMPAIGNS}
            />

            <PublicRoute
              hidePublicNavbar={true}
              allowedAfterLogin={true}
              component={AccessDenied}
              title="Access Denied"
              path="/access-denied"
            />
            <PublicRoute hidePublicNavbar={true} allowedAfterLogin={true} component={NoMatchPage} title="Not Found" />
          </Switch>
        </GlobalSettingsContext.Provider>
      </BrowserRouter>
    );
  }
}
