import { Avatar, List, Spin, message } from "antd";
import { RESOLUTION_TYPE, SERVICE_TYPE } from "../../utils/enum_config";
import { getActionByLogin, getLoginKey } from "../../utils/auth";

import ApiConfig from "../../utils/api_config";
import ApiResponseCode from "../../utils/api_response_code";
import BusinessApi from "../../apis/business_api";
import InfiniteScroll from "react-infinite-scroller";
import React from "react";
import reqwest from "reqwest";

const apiConfig = new ApiConfig();

const GET_FOLLOWER_LIST = apiConfig.getFollowerUrl;

const GET_FOLLOWERING_LIST = apiConfig.getFolloweringUrl;

const businessApi = new BusinessApi();

export default class FollowerList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: false,
      hasMore: true,
      // skip: 1,
      // offset: 5,
      totalRecords: null,
    };
  }

  componentDidMount() {
    this.history = this.props.history;

    this.fetchData((res) => {
      if (res.statusCode === "S10001") {
        this.setState({
          data: res.rows,
          totalRecords: res.total,
        });
      } else {
        message.error(res.message);
      }
    });
  }

  viewDetails = (item) => {
    if (item) {
      businessApi.navigateToProfile(item.id, item.name, item.list_type, this.history);
    }
  };

  fetchData = (callback) => {
    const REQUEST = {
      object_id: this.props.OB_ID,
      object_type: this.props.OB_TYPE,
      page_number: 1,
      page_size: 50,
      owner_type: getActionByLogin("U").type,
      owner_id: getActionByLogin("U").id,
    };
    reqwest({
      url: this.props.LIST_TYPE === "F" ? GET_FOLLOWER_LIST : GET_FOLLOWERING_LIST,
      type: "json",
      method: "post",
      data: JSON.stringify(REQUEST),
      contentType: "application/json",
      headers: {
        Authorization: `Token ${getLoginKey()}`,
      },
      success: (res) => {
        callback(res);
      },
    });
  };

  handleInfiniteOnLoad = () => {
    let { data } = this.state;
    this.setState({
      loading: true,
    });
    if (data.length === this.state.totalRecords) {
      message.warning("All data loaded");
      this.setState({
        hasMore: false,
        loading: false,
      });
      return;
    }
    this.fetchData((res) => {
      if (res.statusCode === "S10001") {
        data = data.concat(res.rows);
        this.setState({
          data,
          loading: false,
        });
      } else {
        message.error(res.message);
      }
    });
  };

  render() {
    return (
      <div className="infinite-container">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={this.handleInfiniteOnLoad}
          hasMore={!this.state.loading && this.state.hasMore}
          useWindow={false}
        >
          <List
            dataSource={this.state.data}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  style={{ cursor: "pointer" }}
                  onClick={() => this.viewDetails(item)}
                  avatar={
                    <Avatar
                      src={businessApi.getUrlForImage(item.image_url, RESOLUTION_TYPE.SIXFOUR, SERVICE_TYPE.PERSON)}
                    />
                  }
                  title={item.name}
                  description={item.business_role}
                />
              </List.Item>
            )}
          >
            {this.state.loading && this.state.hasMore && (
              <div className="loading-container">
                <Spin />
              </div>
            )}
          </List>
        </InfiniteScroll>
      </div>
    );
  }
}
