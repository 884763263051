import { Button, Card, Col, Drawer, Form, Input, List, Row, Select, Typography, message } from "antd";

import { RESPONSE_STATUS_CODES } from "../../utils/enum_config";
import React from "react";
import UserApi from "../../apis/user_api";
import { getActionByLogin } from "../../utils/auth";

const { Title } = Typography;
const userApi = new UserApi();

export default class AccountDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      loading: false,
      visible: false,
      isAdd: true,
      fin_start_month: null,
      fin_end_month: null,
      tax_start_month: null,
      // tax_end_month: null,
      gst: null,
      pan: null,
      cin: null,
      tan: null,
    };
  }

  onClose = () => {
    this.setState({
      visible: false,
    });
    window.location.reload();
  };

  show = (status) => {
    if (status) {
      this.setState({
        visible: true,
        isAdd: status,
      });
    } else {
      this.setState({
        visible: true,
        isAdd: status,
        fin_start_month: this.props.DETAILS.textTwo,
        fin_end_month: this.props.DETAILS.textFour,
        tax_start_month: this.props.DETAILS.textFour,
        // tax_end_month: this.props.DETAILS.textFour,
        gst: this.props.DETAILS.textSix,
        pan: this.props.DETAILS.textEight,
        cin: this.props.DETAILS.textTen,
        tan: this.props.DETAILS.textTwelve,
      });
    }
  };

  getActionOnCard() {
    if (Number(this.props.VIEWID) === getActionByLogin("B").id) {
      if (this.props.DETAILS.textSix) {
        return [
          <Button key="actionEditBtn" type="text" onClick={() => this.show(false)}>
            Edit
          </Button>,
        ];
      } else {
        return [
          <Button key="actionAddBtn" type="text" onClick={() => this.show(true)}>
            Add
          </Button>,
        ];
      }
    } else {
      return [];
    }
  }

  handleStartFy = (e) => {
    this.setState({
      fin_start_month: e,
    });
  };

  handleEndFy = (e) => {
    this.setState({
      fin_end_month: e,
    });
  };

  handleStartTy = (e) => {
    this.setState({
      tax_start_month: e,
    });
  };

  // handleEndTy = (e) => {
  //   this.setState({
  //     tax_end_month: e
  //   })
  // };

  handleGst = (e) => {
    this.setState({
      gst: e.target.value,
    });
  };

  handlePan = (e) => {
    this.setState({
      pan: e.target.value,
    });
  };

  handleCin = (e) => {
    this.setState({
      cin: e.target.value,
    });
  };

  handleTan = (e) => {
    this.setState({
      tan: e.target.value,
    });
  };

  handleSubmit = async () => {
    this.setState({
      loading: true,
    });
    const REQUEST = {
      id: this.state.isAdd ? null : this.props.DETAILS.id,
      business_id: getActionByLogin("B").id,
      country: "India",
      gst: this.state.gst,
      pan: this.state.pan,
      cin: this.state.cin,
      tan: this.state.tan,
      fin_start_month: this.state.fin_start_month,
      fin_end_month: this.state.fin_end_month,
      tax_start_month: this.state.tax_start_month,
      // tax_end_month: this.state.tax_end_month
    };

    if (this.state.isAdd) {
      const response = await userApi.addAccountDetailsApi(REQUEST);
      if (response) {
        if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
          this.props.refetchAccountingDetails && this.props.refetchAccountingDetails();

          this.setState({
            loading: false,
            visible: false,
          });

          message.success(response.message);
        } else {
          this.setState({
            loading: false,
          });
          message.error(response.message);
        }
      } else {
        this.setState({
          loading: false,
        });
      }
    } else {
      const response = await userApi.editAccountDetailsApi(REQUEST);
      if (response) {
        if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
          this.props.refetchAccountingDetails && this.props.refetchAccountingDetails();
          this.setState({
            loading: false,
            visible: false,
          });
          message.success(response.message);
        } else {
          this.setState({
            loading: false,
          });
          message.error(response.message);
        }
      } else {
        this.setState({
          loading: false,
        });
      }
    }
  };

  render() {
    return (
      <>
        <Card
          title={this.props.DETAILS ? <Title level={5}>{this.props.DETAILS.title}</Title> : ""}
          extra={this.getActionOnCard()}
        >
          {/* <Row gutter={12}>
                        <Col span={12}>
                            <List
                                dataSource={[this.props.DETAILS]}
                                renderItem={(item, i) => (
                                    <List.Item key={i}>
                                        <List.Item.Meta
                                            title={item.textOne}
                                            description={item.textTwo}
                                        />

                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col span={12}>
                            <List
                                dataSource={[this.props.DETAILS]}
                                renderItem={(item, i) => (
                                    <List.Item key={i}>
                                        <List.Item.Meta
                                            title={item.textThree}
                                            description={item.textFour}
                                        />

                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row> */}

          <Row gutter={12}>
            <Col span={12}>
              <List
                dataSource={[this.props.DETAILS]}
                renderItem={(item, i) => (
                  <List.Item key={i}>
                    <List.Item.Meta title={item.textOne} description={item.textTwo || "-"} />
                  </List.Item>
                )}
              />
            </Col>
            <Col span={12}>
              <List
                dataSource={[this.props.DETAILS]}
                renderItem={(item, i) => (
                  <List.Item key={i}>
                    <List.Item.Meta title={item.textFive} description={item.textSix || "-"} />
                  </List.Item>
                )}
              />
            </Col>
            <Col span={12}>
              <List
                dataSource={[this.props.DETAILS]}
                renderItem={(item, i) => (
                  <List.Item key={i}>
                    <List.Item.Meta title={item.textSeven} description={item.textEight || "-"} />
                  </List.Item>
                )}
              />
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <List
                dataSource={[this.props.DETAILS]}
                renderItem={(item, i) => (
                  <List.Item key={i}>
                    <List.Item.Meta title={item.textNine} description={item.textTen || "-"} />
                  </List.Item>
                )}
              />
            </Col>
            <Col span={12}>
              <List
                dataSource={[this.props.DETAILS]}
                renderItem={(item, i) => (
                  <List.Item key={i}>
                    <List.Item.Meta title={item.textEleven} description={item.textTwelve || "-"} />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Card>

        <Drawer
          title="Add/Edit Account Details"
          width={720}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          maskClosable={false}
          destroyOnClose
        >
          <Form layout="vertical" name="accountDetails" onFinish={this.handleSubmit} hideRequiredMark>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="1st month of financial year">
                  <Select style={{ width: "100%" }} value={this.state.fin_start_month} onChange={this.handleStartFy}>
                    {this.state.monthNames.map((item) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Last month of financial year">
                  <Select style={{ width: "100%" }} value={this.state.fin_end_month} onChange={this.handleEndFy}>
                    {this.state.monthNames.map((item) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              {/* <Col span={12}>
                                <Form.Item
                                    label="1st month of Tax year"
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        value={this.state.tax_start_month}
                                        onChange={this.handleStartTy}
                                    >
                                        {this.state.monthNames.map((item) => (
                                            <Select.Option key={item} value={item}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col> */}
              {/* <Col span={12}>
                                <Form.Item
                                    label="Last month of tax year"
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        value={this.state.tax_end_month}
                                        onChange={this.handleEndTy}
                                    >
                                        {this.state.monthNames.map((item) => (
                                            <Select.Option key={item} value={item}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col> */}
            </Row>

            <Row gutter={12}>
              <Col span={24}>
                <Form.Item label="GST">
                  <Input onChange={this.handleGst} value={this.state.gst} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item label="PAN" rules={[{ required: true, message: "Please confirm your User Name" }]}>
                  <Input onChange={this.handlePan} value={this.state.pan} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col span={24}>
                <Form.Item label="CIN">
                  <Input onChange={this.handleCin} value={this.state.cin} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item label="TAN" rules={[{ required: true, message: "Please confirm your User Name" }]}>
                  <Input onChange={this.handleTan} value={this.state.tan} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button loading={this.state.loading} style={{ width: "100%" }} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </>
    );
  }
}
