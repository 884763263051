import GlobalSettingsContext from "../../utils/global_settings_context";
import React from "react";

export function withGlobalSettingsWrapper(Component) {
  return class extends React.Component {
    render() {
      return (
        <GlobalSettingsContext.Consumer>
          {({ themeItems, appAccess }) => <Component themeItems={themeItems} appAccess={appAccess} {...this.props} />}
        </GlobalSettingsContext.Consumer>
      );
    }
  };
}
