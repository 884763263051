import { Button, Card, Drawer, List, Rate, Tag, Typography } from "antd";
import { getActionByLogin, getSelectedBusinessId, getSelectedRoleByBusinessId } from "../../utils/auth";

import AddLanguage from "../network/language-form";
import AddSkills from "../network/skills-form";
import { PlusCircleFilled } from "@ant-design/icons";
import { RESPONSE_STATUS_CODES } from "../../utils/enum_config";
import React from "react";
import UserApi from "../../apis/user_api";
import { withGlobalSettingsWrapper } from "../common/GlobalSettingsWrapper";

const { Paragraph, Text, Title } = Typography;

const userApi = new UserApi();

class Langaugecard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      visibleLanguage: false,
      visibleSkills: false,
      visibleSeeMoreList: false,
    };
  }

  showDrawerEdit = () => {
    if (this.props.DETAILS.title === "Language") {
      this.setState({
        visibleLanguage: true,
      });
    } else {
      this.setState({
        visibleSkills: true,
      });
    }
  };

  onClose = () => {
    if (this.props.DETAILS.title === "Language") {
      this.setState({
        visibleLanguage: false,
      });
    } else {
      this.setState({
        visibleSkills: false,
      });
    }
    window.location.reload();
  };

  showSeeMore = () => {
    if (this.props.DETAILS.title === "Language") {
      this.setState({
        visibleSeeMoreList: true,
      });
      this.getSeeMoreList(this.props.VIEWID, "2", 1, 100);
    } else {
      this.setState({
        visibleSeeMoreList: true,
      });
      this.getSeeMoreList(this.props.VIEWID, "4", 1, 100);
    }
  };

  onSeeMoreClose = () => {
    if (this.props.DETAILS.title === "Language") {
      this.setState({
        visibleSeeMoreList: false,
      });
    } else {
      this.setState({
        visibleSeeMoreList: false,
      });
    }
  };

  getSeeMoreList = async (ACTIONBYID, EXPRT_CAT, SKIP, OFFSET) => {
    this.setState({
      loading: true,
    });
    const REQUEST = {
      person_id: ACTIONBYID,
      standard_expertise_category: EXPRT_CAT,
      page_number: SKIP,
      page_size: OFFSET,
      given_by_id: ACTIONBYID,
    };
    const response = await userApi.getSeeMoreLanguageSkillsApi(REQUEST);
    if (response) {
      if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
        this.setState({
          data: response.rows,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    }
  };

  getList = (DATA) => {
    if (DATA) {
      return (
        <List
          itemLayout="horizontal"
          loading={this.state.loading}
          dataSource={DATA}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={item.standardExpertiseCategoryTypes}
                description={
                  <Text type="secondary">
                    {item.average_rating}
                    <Rate
                      style={{ color: this.props.themeItems.themeColors.primary }}
                      disabled
                      defaultValue={item.star_rating}
                    />
                  </Text>
                }
              />
              <div>{item.total_count}</div>
            </List.Item>
          )}
        />
      );
    } else {
      return <></>;
    }
  };

  getLanguage(DATA) {
    if (DATA) {
      return DATA.map((item) => (
        <>
          <Tag>
            <Button type="text">{item ? item.language : ""}</Button>
          </Tag>
        </>
      ));
    }
    return <></>;
  }

  getActionOnCard() {
    if (Number(this.props.VIEWID) === getActionByLogin("U").id) {
      return [
        <Button type="text" key="seeMoreBtn" onClick={() => this.showSeeMore()}>
          See More
        </Button>,
      ];
      // <Button type="text" onClick={() => this.showDrawerEdit()} icon={<PlusCircleFilled style={{ fontSize: 20, color: this.props.themeItems.themeColors.btnBlue }} />} />
    } else {
      if (this.props.DETAILS.isShowMore) {
        return [
          <Button type="text" key="detailsSeeMoreBtn" onClick={() => this.showSeeMore()}>
            See More
          </Button>,
        ];
      } else {
        return null;
      }
    }
  }

  render() {
    return (
      <>
        <Card
          title={this.props.DETAILS ? <Title level={5}>{this.props.DETAILS.title}</Title> : ""}
          extra={this.getActionOnCard()}
        >
          <Paragraph>{this.props.DETAILS.subRow ? this.getLanguage(this.props.DETAILS.subRow) : <></>}</Paragraph>
        </Card>
        <Drawer
          title="Add/Edit Language"
          width={720}
          onClose={this.onClose}
          visible={this.state.visibleLanguage}
          bodyStyle={{ paddingBottom: 80 }}
          maskClosable={false}
          destroyOnClose
        >
          <AddLanguage
            ACTION_BY_ID={getActionByLogin("U").id}
            ACTION_BY_TYPE={getActionByLogin("U").type}
            SELECTED_INST_ID={getSelectedBusinessId()}
            SELECED_INST_ROLE_TYPE={
              getSelectedRoleByBusinessId(getSelectedBusinessId())
                ? getSelectedRoleByBusinessId(getSelectedBusinessId()).person_type
                : null
            }
            onSubmitForm={this.onClose}
          />
        </Drawer>

        <Drawer
          title="Add/Edit Skills"
          width={720}
          onClose={this.onClose}
          visible={this.state.visibleSkills}
          bodyStyle={{ paddingBottom: 80 }}
          maskClosable={false}
          destroyOnClose
        >
          <AddSkills
            ACTION_BY_ID={getActionByLogin("U").id}
            ACTION_BY_TYPE={getActionByLogin("U").type}
            SELECTED_INST_ID={getSelectedBusinessId()}
            SELECED_INST_ROLE_TYPE={
              getSelectedRoleByBusinessId(getSelectedBusinessId())
                ? getSelectedRoleByBusinessId(getSelectedBusinessId()).person_type
                : null
            }
            onSubmitForm={this.onClose}
          />
        </Drawer>

        <Drawer
          title={this.props.DETAILS.title === "Language" ? "Language" : "Skills"}
          width={720}
          onClose={this.onSeeMoreClose}
          visible={this.state.visibleSeeMoreList}
          bodyStyle={{ paddingBottom: 80 }}
          maskClosable={false}
          destroyOnClose
        >
          {this.getList(this.state.data.length > 0 ? this.state.data[0].subRow : [])}
        </Drawer>
      </>
    );
  }
}

export default withGlobalSettingsWrapper(Langaugecard);
