import ApiConfig from "../utils/api_config";
import NetworkUtil from "../utils/network_util";
import { isLogin } from "../utils/auth";

const apiConfig = new ApiConfig();
const networkUtil = new NetworkUtil();

class FeedApi {
  async createPostApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createPostUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getPostListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getPostListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getPostListPublicApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getPostListPublicUrl, REQUEST);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getFaqPolicyListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getFaqPolicyListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getPostDetailsApi(REQUEST) {
    let result = null;
    const URL = isLogin() ? apiConfig.getPostDetailsUrl : apiConfig.getPostDetailsPublicUrl;
    try {
      result = await networkUtil.post(URL, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updatePostApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updatePostUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateRecipientApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateRecipientUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async createReportContentApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createReportContentUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async createRatingApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createRatingUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getKeyWordApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getKeyWordUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getMentionApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getMentionUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async createCommentApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createCommentUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async commentListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.commentListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async answerListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.answerListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async createAnswerApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createAnswerUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getRecipienttypeListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getRecipienttypeListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getEventRecipienttypeListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getEventRecipienttypeListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getShareUrlApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getShareUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getSuggestionApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getSuggestionUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async refreshPostListApi() {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.refreshPostListUrl, {}, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getHomePageContentApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getHomePageContentUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getStandardLessonListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getStandardLessonListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getPublicStandardLessonListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getPublicStandardLessonListUrl, REQUEST);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async createChapterApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createChapterUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getStandardChapterListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getStandardChapterListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getPublicChapterListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getPublicChapterListUrl, REQUEST);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getStandardTopicListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getStandardTopicListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getTopicsListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getTopicsListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getTimelineListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getTimelineListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getUserMediaListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getUserMediaListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateAnswer(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateAnswerUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }
}

export default FeedApi;
