import { Layout, Row } from "antd";

import Banner from "../../components/home-page/banner";
import CookieConsent from "react-cookie-consent";
import React from "react";
import { withGlobalSettingsWrapper } from "../../components/common/GlobalSettingsWrapper";

const { Content } = Layout;
class IndexHome extends React.Component {
  render() {
    return (
      <>
        <Layout style={{ backgroundColor: this.props.themeItems.themeColors.white }}>
          <Content className="site-layout">
            <section>
              <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
                <Banner />
              </Row>
            </section>
          </Content>
        </Layout>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          enableDeclineButton
          declineButtonText="Decline"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{
            backgroundColor: this.props.themeItems.themeColors.primary,
            color: this.props.themeItems.themeColors.white,
            fontSize: "13px",
            width: "72px",
            borderRadius: "4px",
          }}
          declineButtonStyle={{
            backgroundColor: this.props.themeItems.themeColors.white,
            color: "#ff4d00",
            fontSize: "13px",
            width: "72px",
            borderRadius: "4px",
          }}
          expires={150}
        >
          This site uses cookies, including third party, in order to improve your experience and our services. Click
          here for more information on our Cookie Policy.
          <p style={{ fontSize: "14px" }}>
            If you decline, your information won’t be tracked when you visit this website. A single cookie will be used
            in your browser to remember your preference not to be tracked.
          </p>
        </CookieConsent>
      </>
    );
  }
}

export default withGlobalSettingsWrapper(IndexHome);
