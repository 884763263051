import Loader from "./loader";
import React from "react";

export default function withLazyWrapper(importFunc, selectorFunc, fallbackLoader = <Loader />) {
  let lazyFactory = importFunc;

  if (selectorFunc) {
    lazyFactory = () => importFunc().then((module) => ({ default: selectorFunc(module) }));
  }

  const LazyComponent = React.lazy(lazyFactory);

  return (props) => (
    <React.Suspense fallback={fallbackLoader}>
      <LazyComponent {...props} />
    </React.Suspense>
  );
}
