import { Button, Card, Col, DatePicker, Form, Input, Row, Select, message } from "antd";

import PropTypes from "prop-types";
import { RESPONSE_STATUS_CODES } from "../../utils/enum_config";
import React from "react";
import UserApi from "../../apis/user_api";
import moment from "moment";

const userApi = new UserApi();

const { TextArea } = Input;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";

export default class EditUserBasic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: this.props.editData.first_name,
      user_name: this.props.editData.user_name,
      middle_name: this.props.editData.middle_name,
      last_name: this.props.editData.last_name,
      gender: this.props.editData.gender,
      date_of_birth: this.props.editData.date_of_birth,
      date_of_anniversary: this.props.editData.date_of_anniversary,
      blood_group: this.props.editData.blood_group,
      slug: this.props.editData.slug,
      bio: this.props.editData.full_bio,
      quote: null,
      designation: this.props.editData.designation,
      department: this.props.editData.department,
      report_manager: this.props.editData.report_manager,
      date_of_joining: this.props.editData.date_of_joining,
    };
  }

  handleFirstName = (e) => {
    this.setState({
      first_name: e.target.value,
    });
  };

  handleSecondName = (e) => {
    this.setState({
      middle_name: e.target.value,
    });
  };

  handleLastName = (e) => {
    this.setState({
      last_name: e.target.value,
    });
  };

  handleUserName = (e) => {
    this.setState({
      user_name: e.target.value,
    });
  };

  handleGender = (e) => {
    this.setState({
      gender: e,
    });
  };

  handleDob = (date, dateString) => {
    this.setState({
      date_of_birth: dateString,
    });
  };

  handleAnniversary = (date, dateString) => {
    this.setState({
      date_of_anniversary: dateString,
    });
  };

  handleBloodGroup = (e) => {
    this.setState({
      blood_group: e,
    });
  };

  handleBio = (e) => {
    this.setState({
      bio: e.target.value,
    });
  };

  handleQuote = (e) => {
    this.setState({
      quote: e.target.value,
    });
  };

  handleDesignation = (e) => {
    this.setState({
      designation: e.target.value,
    });
  };

  handleDepartment = (e) => {
    this.setState({
      department: e.target.value,
    });
  };

  handleReportManager = (e) => {
    this.setState({
      report_manager: e.target.value,
    });
  };

  handleDoj = (date, dateString) => {
    const newDate = new Date(dateString).getTime();
    this.setState({
      date_of_joining: newDate,
    });
  };

  handleSubmit = async () => {
    if (this.state.first_name !== null && this.state.first_name !== undefined && this.state.first_name !== "") {
      this.setState({
        loading: true,
      });
      const REQUEST = {
        first_name: this.state.first_name,
        user_name: this.state.user_name,
        middle_name: this.state.middle_name,
        last_name: this.state.last_name,
        gender: this.state.gender,
        date_of_birth: this.state.date_of_birth,
        date_of_anniversary: this.state.date_of_anniversary,
        blood_group: this.state.blood_group,
        slug: this.state.slug,
        bio: this.state.bio,
        quote: this.state.quote,
        designation: this.state.designation,
        department: this.state.department,
        report_manager: this.state.report_manager,
        date_of_joining: this.state.date_of_joining,
      };
      this.setState({
        loading: false,
      });
      const response = await userApi.updateUserBasicApi(REQUEST);
      if (response) {
        if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
          this.setState({
            loading: false,
          });
          this.props.onSubmitForm();
        } else {
          this.setState({
            loading: false,
          });
          message.error(response.message);
        }
      } else {
        this.setState({
          loading: false,
        });
      }
    } else {
      message.error("Please enter your First Name");
    }
  };

  render() {
    console.log(this.state);
    return (
      <>
        <Form layout="vertical" name="basicform" onFinish={this.handleSubmit} hideRequiredMark>
          <Card title="Basic">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="First name" rules={[{ required: true, message: "Please confirm your first name" }]}>
                  <Input
                    placeholder="Please enter your First Name"
                    onChange={this.handleFirstName}
                    value={this.state.first_name}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Second name">
                  <Input placeholder="Second Name" onChange={this.handleSecondName} value={this.state.middle_name} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item label="Last name">
                  <Input placeholder="Last Name" onChange={this.handleLastName} value={this.state.last_name} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  label="User name"
                  rules={[
                    { required: true, message: "Please confirm your User Name" },
                    {
                      pattern: /^-[a-zA-Z0-9._]*$/,
                      message: "The username can be alphabets, numbers or -.",
                    },
                  ]}
                >
                  <Input placeholder="User Name" onChange={this.handleUserName} value={this.state.user_name} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: "Please select your gender!",
                    },
                  ]}
                >
                  <Select value={this.state.gender} onChange={this.handleGender}>
                    <Option value={1}>Male</Option>
                    <Option value={2}>Female</Option>
                    <Option value={3}>Transgender</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Date of Birth"
                  rules={[
                    {
                      required: true,
                      message: "Please select your Date of Birth!",
                    },
                  ]}
                >
                  <DatePicker
                    allowClear={false}
                    value={this.state.date_of_birth ? moment(this.state.date_of_birth, dateFormat) : null}
                    onChange={this.handleDob}
                    disabledDate={(d) => !d || d.isAfter(moment().subtract(4, "years"))}
                    format="YYYY-MM-DD"
                    defaultPickerValue={moment().subtract(4, "years")}
                    placeholder="yyyy-mm-dd"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Date of Anniversary">
                  <DatePicker
                    onChange={this.handleAnniversary}
                    value={this.state.date_of_anniversary ? moment(this.state.date_of_anniversary, dateFormat) : null}
                    // disabledDate={(d) =>
                    //   !d || d.isAfter(moment().subtract(4, 'years'))
                    // }
                    format="YYYY-MM-DD"
                    defaultPickerValue={moment().subtract(4, "years")}
                    placeholder="yyyy-mm-dd"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Blood Group">
                  <Select
                    placeholder="Please Select Blood Group"
                    value={this.state.blood_group}
                    onChange={this.handleBloodGroup}
                  >
                    <Option value="APOSITIVE">A+</Option>
                    <Option value="ANEGATIVE">A-</Option>
                    <Option value="BPOSITIVE">B+</Option>
                    <Option value="BNEGATIVE">B-</Option>
                    <Option value="OPOSITIVE">o+</Option>
                    <Option value="OPOSITIVE">o-</Option>
                    <Option value="ONEGATIVE">o-</Option>
                    <Option value="ABPOSITIVE">AB+</Option>
                    <Option value="ABNEGATIVE">AB-</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Designation">
                  <Input onChange={this.handleDesignation} value={this.state.designation} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Department">
                  <Input onChange={this.handleDepartment} value={this.state.department} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Report Manager">
                  <Input onChange={this.handleReportManager} value={this.state.report_manager} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Date of Joining">
                  <DatePicker
                    onChange={this.handleDoj}
                    // disabledDate={(d) =>
                    //   !d || d.isAfter(moment().subtract(4, 'years'))
                    // }
                    format="YYYY-MM-DD"
                    defaultPickerValue={moment().subtract(4, "years")}
                    placeholder="yyyy-mm-dd"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card title="Bio">
            <Form.Item label="Write Something About You">
              <TextArea showCount maxLength={100} onChange={this.handleBio} value={this.state.bio} />
            </Form.Item>
          </Card>

          <Card title="Quote">
            <Form.Item label="Write quote for your page">
              <TextArea showCount maxLength={100} onChange={this.handleQuote} value={this.state.quote} />
            </Form.Item>
          </Card>

          <Form.Item>
            <Button loading={this.state.loading} style={{ width: "100%" }} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

EditUserBasic.protoTypes = {
  editData: PropTypes.any,
};
