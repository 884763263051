export const POST_TYPE = {
  GENERAL: "general",
  NOTICE: "notice",
  BLOG: "blog",
  QNA: "qa",
  POLL: "poll",
  NEWS: "news",
  ISBOOKMARK: "bookmark",
  LESSON: "lesson",
  ASSIGNMENT: "assignment",
  FAQ: "faq",
  POLICY: "policy",
  HELPDOC: "helpdoc",
  CAMPAIGN: "campaign",
};

export const POST_STATUS = {
  READ: "read",
  UNREAD: "unread",
  HIDDEN: "hidden",
};

export const STEP_TYPE = {
  SELECT_INST: "selectInst",
  UPLOAD_INST_LOGO: "instLogo",
  DEFAULT_DOMAIN: "default_domain",
  CONTACT_DETAILS: "contactDetails",
  LOCATION: "location",
  VERIFY_PROFILE: "verifyProfile",
  SCRATCH_CARD: "scratchcard",
  ROLE: "role",
  PROGRAMME: "programme",
  DEPARTMENT: "department",
  CLASSES: "classes",
  SECTION: "section",
  SUBJECT: "subject",
  IMAGE: "image",
  CHILD: "child",
  SUBMIT: "submit",
};

export const ROLE_TYPE = {
  PARENT: "P",
  STAFF: "T",
  STUDENT: "S",
  ALUMNI: "L",
};

export const RESOLUTION_TYPE = {
  SIXFOUR: 64,
  ONETWOEIGHT: 128,
  TWOFIVESIX: 256,
  FIVEONETWO: 512,
};

export const SERVICE_TYPE = {
  PERSON: "person",
  BUSINESS: "business",
  BUSINESS: "business",
  COMPANY: "company",
  POST: "post",
  ROOM: "room",
  CLASS: "class",
  SUBJECT: "subject",
  LANGUAGE: "language",
  SKILL: "skill",
  EVENT: "event",
};

export const ROOM_PRIVACY_TYPE = {
  ALL: "all",
  PRIVATE: "private",
  PUBLIC: "public",
  SOCIAL: "social",
  COWORKERS: "coworkers",
  PARTNER: "partner",
};

export const MEMBERSHIP_STATUS = {
  ACTIVE: "A",
  TERMINATED: "T",
  SUSPENDED: "S",
  APPLIED: "P",
  REJECTED: "R",
  REMOVED: "M",
  INVITED: "I",
};

export const ADD_METHOD = {
  DIRECT: "D",
  AUTOMATIC_ADD: "A",
  JOIN: "J",
  INVITED: "I",
};

export const CONTENT_CARD_NAME = {
  SCROLL_CARD: "HorizontalScrollCard",
  PROFILE_CARD: "ProfileProgressCard",
  // QUOTE_CARD: 'QuotesCard',
  INVITE_CARD: "CommonInviteCard",
  NOTICE_CARD: "NoticeCard",
  QUESTION_CARD: "QuestionCard",
  NEWS_CARD: "NewsCard",
  SUGGESTION_CARD: "SuggestionCard",
  BUDDY_APPROVAL_CARD: "Buddy_Approval",
};

export const RESPONSE_STATUS_CODES = {
  SUCCESS_CODE: "S10001",
};

export const SEARCH_ENTITY_TYPE = {
  ALL: "all",
  PERSON: "person",
  BUSINESS: "business",
  ROOM: "room",
  EVENT: "event",
  POST: "post",
};

export const SOCKET_EVENT = {
  CONNECT: "connect",
  DISCONNECT: "disconnect",
  JOIN: "join",
  CHAT_MESSAGE: "chat_message",
  ADD_CONVERSATION: "add_conversation",
  MESSAGE_STATUS: "message_status",
  TYPING: "typing",
  USER_STATUS: "user_status",
  FORWARD_MESSAGE: "forward_message",
  REPLY_MESSAGE: "reply_message",
  MESSAGE_ERROR: "message_error",
  UPDATE_MESSAGE: "update_message",

  TICKET_UPDATE: "ticket_update",
  TICKET_ESCALATE: "ticket_escalate",
  TICKET_ERROR: "ticket_message_error",
  TICKET_MESSAGE: "chat_ticket_message",
  TICKET_JOIN: "join_ticket",
  TICKET_BUSINESS_JOIN: "join_business",
  TICKET_DETAILS: "ticket_details",
  TICKET_COMMENT_UPDATE: "update_ticket_comment_status",

  ON_EVENT_LIVE: "event_live",
  ON_EVENT_MEMBER_STATUS: "event_member_status",
  ON_EVENT_CREATE: "event_create",
  ON_UPDATE_MODERATOR: "update_moderator",
  ON_CONVERSATION: "add_conversation_id",
  ON_GET_PARTICIPANTS: "get_participants",
  ON_EVENT_ERROR: "event_error",
  ON_JOIN_SUCCESS: "join_success",
  ON_MIC_STATUS: "speaker_mic_status",
  ON_HAND_RAISE_COUNT: "get_requests_count",
  ON_HANDS_RAISE: "hand_raise",
  ON_JOIN: "join",
  ON_LEAVE: "leave",
  ON_GET_REQUEST: "get_requests",
  ON_HAND_RAISE_UPDATE: "hand_raise_update",
  ON_HAND_DOWN: "hand_down",
  ON_UPDATE_ROLE: "update_role",
  ON_REMOVE: "remove_user",
  ON_EVENT_ENDED: "event_ended",
  ON_NOTIFICATION: "notification",
  ON_INVITE_USER: "invite_user",
  ON_GET_SPEAKERS: "get_speakers",
  ON_VIDEO_ON_OFF: "video_status",

  EMIT_GET_SPEAKERS: "get_speakers",
  EMIT_GET_PARTICIPANTS: "get_participants",
  EMIT_JOIN: "join",
  EMIT_LEAVE: "leave",
  EMIT_MIC_STATUS: "speaker_mic_status",
  EMIT_HANDS_RAISE: "hand_raise",
  EMIT_GET_REQUEST: "get_requests",
  EMIT_HAND_RAISE_COUNT: "get_requests_count",
  EMIT_HAND_RAISE_UPDATE: "hand_raise_update",
  EMIT_HAND_DOWN: "hand_down",
  EMIT_ON_UPDATE_MODERATOR: "update_moderator",
  EMIT_ON_UPDATE_ROLE: "update_role",
  EMIT_ON_REPORT: "report_disruptive",
  EMIT_ON_REMOVE: "remove_user",
  EMIT_ON_INVITE_USER: "invite_user",
  EMIT_ON_UPDATE_INVITE: "invite_user_update",
  EMIT_ON_EVENT_RATING: "event_rating",
};

export const MESSAGE_KEY = {
  SOCKET_NOT_CONNECTED: "Socket Not Connected",
  DELETE: "DELETE",
  REPLY: "REPLY",
  FORWARD: "FORWARD",
  COPY: "COPY",
  IMAGE: "image",
  VIDEO: "video",
  AUDIO: "audio",
  FILE: "file",
};

/**
 * Chat Module Enum
 */
export const MESSAGESTATUS = {
  SENT: "sent",
  DELIVERED: "delivered",
  READ: "read",
  DELETE: "deleted",
  PENDING: "pending",
};

export const EVENT_STATUS = {
  TENTATIVE: "TENTATIVE",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  ENDED: "ENDED",
  NOTSTARTED: "NOTSTARTED",
  DELETED: "DELETED",
  CANCELLED: "CANCELLED",
  RESCHEDULED: "RESCHEDULED",
  LIVE: "LIVE",
};

export const WORK_TALK_ROLE_TYPE = {
  LISTENER: "listener",
  HOST: "host",
  COHOST: "cohost",
  SPEAKER: "speaker",
};

export const HELPDESK = {
  STATUS: "status",
  PRIORITY: "priority",
  CATEGORY: "category",
  SKILL: "skill",
  TIER: "tier",

  UNASSIGNED: "Unassigned",
  ASSIGNED: "Assigned",
  ONHOLD: "On Hold",
  RESOLVED: "Resolved",
  REOPEN: "Reopen",
  CLOSED: "Closed",
};

export const HELPDESK_COLORS = {
  UNASSIGNED: "#FFF",
  ASSIGNED: "#E2E3E5",
  ONHOLD: "#FFF3CD",
  RESOLVED: "#D1ECF1",
  REOPEN: "#F8D7DA",
  CLOSED: "#D4EDDA",
};

export const PRODUCTS_ENUM = {
  all: "ALL",
  category: "CATEGORY",
  brand: "BRAND",
  catalog: "CATALOG",
};

export const RECIPIENTS_TYPE_LIST = [
  {
    type: "customemployee",
  },
  {
    type: "custompartner",
  },
  {
    type: "myself",
  },
  {
    type: "business",
  },
  {
    type: "myteam",
  },
  {
    type: "partner",
  },
  {
    type: "mypartner",
  },
  {
    type: "myteampartner",
  },
  {
    type: "community",
  },
  {
    type: "partnerbusiness",
  },
  {
    type: "person",
  },
];

export const RECIPIENTS_TYPE = {
  customemployee: "customemployee",
  custompartner: "custompartner",
  myself: "myself",
  business: "business",
  myteam: "myteam",
  partner: "partner",
  mypartner: "mypartner",
  myteampartner: "myteampartner",
  community: "community",
  partnerbusiness: "partnerbusiness",
  person: "person",
};

export const USER_TYPES = {
  employee: "Employee",
  partner: "Partner",
  partneremployee: "Partner Employee",
};

export const CAMPAIGN_STATUS = {
  NOT_STARTED: "notstarted",
  IN_PROGRESS: "inprogress",
  PAUSED: "paused",
  ENDED: "ended",
};

export const CAMPAIGN_STATUS_TEXT = {
  notstarted: "Not Started",
  inprogress: "In Progress",
  paused: "Paused",
  ended: "Ended",
};

export const CAMPAIGN_STATUS_COLORS = {
  notstarted: "#cce5ff",
  inprogress: "#D4EDDA",
  paused: "#fff3cd",
  ended: "#f8d7da",
};

export const CAMPAIGN_POST_READ = {
  VIEWED: "viewed",
  INTERESTED: "interested",
  NOT_INTERESTED: "notinterested",
  ALL: "all",
  MAYBE: "maybe",
};

export const APP_CODES = {
  CALENDAR: "calendar",
  MEETING: "talks",
  REWARDS: "reward",
  FAQ: "faq",
  COMPLIANCE: "compliance",
  LESSON: "lesson",
  ROOM: "room",
  POLICY: "policy",
  HELPDESK: "helpdesk",
  ACCOUNTING: "accounting",
  DRIVE: "drive",
  SHOPPING: "shopping",
  CAMPAIGNS: "goals",
  CHANNELPARTNER: "channelpartner",
  BRANCH: "branch",
  DEPARTMENT: "department",
  COWORKER: "coworker",
  JOBS: "jobs",
  CONTRACT: "contract",
  LOAN: "loan",
  REPORT: "report",
  CIRCLE: "circle",
  CHAT: "chat",
  CERTIFICATE: "certificate",
  SETTINGS: "settings",
  LOGOUT: "logout",
};
