import { Button, Card, Col, Drawer, List, Row, Tooltip, Typography } from "antd";
import {
  EnvironmentOutlined,
  GlobalOutlined,
  IdcardOutlined,
  MobileOutlined,
  PhoneOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";

import EditAddress from "../network/edit-address";
import { MAIL_DOMAIN } from "../../utils/api_config";
import React from "react";
import { getActionByLogin } from "../../utils/auth";
import { withGlobalSettingsWrapper } from "../common/GlobalSettingsWrapper";

const { Title } = Typography;

class ContactInfoCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleEdit: false,
      data: [],
    };
  }

  onClose = () => {
    this.setState({
      visibleEdit: false,
    });
    // window.location.reload()
  };

  getActionOnCard(ITEM) {
    if (Number(this.props.VIEWID) === getActionByLogin("U").id) {
      return (
        <Button
          type="text"
          onClick={() => this.setState({ visibleEdit: true })}
          icon={<PlusCircleFilled style={{ fontSize: 20, color: this.props.themeItems.themeColors.btnBlue }} />}
        />
      );
    } else {
      return [];
    }
  }

  render() {
    return (
      <>
        {/* <Card
                    title={this.props.DETAILS ? <Title level={5}>{this.props.DETAILS.title}</Title> : ''}
                    extra={
                        this.getActionOnCard(this.props.DETAILS)}> */}
        <Card title={this.props.DETAILS ? <Title level={5}>{this.props.DETAILS.title}</Title> : ""}>
          {this.props.DETAILS && this.props.DETAILS.textTwo && (
            <Row gutter={12} style={{ justifyContent: "center" }}>
              <Col style={{ textAlign: "center" }}>
                <>
                  <EnvironmentOutlined />
                  &nbsp;
                  {this.props.DETAILS.textTwo}
                </>
              </Col>
            </Row>
          )}

          <Row gutter={12} style={{ justifyContent: "center" }}>
            {this.props.DETAILS && this.props.DETAILS.textFour && this.props.DETAILS.textFour > 0 && (
              <Col style={{ textAlign: "center" }}>
                <>
                  <PhoneOutlined />
                  &nbsp;
                  {this.props.DETAILS.textFour > 0 || "-"}
                </>
              </Col>
            )}
            {this.props.DETAILS && this.props.DETAILS.textTen && this.props.DETAILS.textTen > 0 && (
              <Col style={{ textAlign: "center" }}>
                <MobileOutlined />
                &nbsp;
                {this.props.DETAILS.textTen > 0 || "-"}
              </Col>
            )}
            {this.props.DETAILS && this.props.DETAILS.textSix && (
              <Col style={{ textAlign: "center" }}>
                <a
                  href={this.props.DETAILS.textSix && MAIL_DOMAIN.split("/api/v1")[0]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IdcardOutlined /> {this.props.DETAILS.textSix || "-"}
                </a>
              </Col>
            )}
            {this.props.DETAILS && this.props.DETAILS.textEight && (
              <Col style={{ textAlign: "center" }}>
                <a href={this.props.DETAILS.textEight} target="_blank" rel="noopener noreferrer">
                  <GlobalOutlined /> {this.props.DETAILS.textEight || "-"}
                </a>
              </Col>
            )}
          </Row>
        </Card>
        <Drawer
          title="Edit address"
          width={720}
          onClose={this.onClose}
          visible={this.state.visibleEdit}
          bodyStyle={{ paddingBottom: 80 }}
          maskClosable={false}
          destroyOnClose
        >
          <EditAddress onSubmitForm={this.onClose} editData={this.state.editData} />
        </Drawer>
      </>
    );
  }
}

export default withGlobalSettingsWrapper(ContactInfoCard);
