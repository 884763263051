import { Button, notification } from "antd";
import { build as appBuild, version as appVer } from "../../package.json";

import React from "react";

export const DEVICE_CODE = "FW_DEVICE_CODE";
export const TOKEN_KEY = "USER_TOKEN";
export const VIRTUAL_TOKEN_KEY = "VIRTUAL_TOKEN";
export const USER_DATA = "USER_DATA";
export const FCM_ID = "fcmId";
export const BUSINESS_ID = "busnId";
export const VERIFY_EMAIL = "ve";
export const VERIFY_CREDENTIALS = "verifyFoopCredentials";
export const VAPITKEY = "BKeOqvBlf6F2oKIHgSiwRQo5vumyqoGYjVzXaC0DEAHv8qW4W8Qu31nWaojUbTE554CwiOhx8xxMKsiR2TLZzoU";
export const SELECTED_BUSINESS = "sBusn";

export const clearCacheAndHardReload = () => {
  const verVal = localStorage.getItem("lastVersion");
  if (!verVal || verVal !== appVer + appBuild) {
    console.log("Clearing cache and hard reloading!");
    localStorage.setItem("lastVersion", appVer + appBuild);
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    window.location.reload(true);
  }
};

export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(DEVICE_CODE);
  localStorage.removeItem("deviceDetails");
  localStorage.removeItem(USER_DATA);
  localStorage.removeItem(FCM_ID);
  localStorage.removeItem(BUSINESS_ID);
  localStorage.removeItem(SELECTED_BUSINESS);
};

export const getLoginKey = () => {
  const value = localStorage.getItem(TOKEN_KEY);
  return value;
};

export const isLogin = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    return true;
  }

  return false;
};

export const setVirtualToken = (VTK) => {
  localStorage.setItem(VIRTUAL_TOKEN_KEY, VTK);
};

export const getVirtualToken = () => {
  return localStorage.getItem(VIRTUAL_TOKEN_KEY);
};

export const isUserRestricted = (roles) => false;

export const setBusinessId = (ID) => {
  if (ID !== "null" && ID !== null && ID !== undefined) {
    localStorage.setItem(BUSINESS_ID, ID);
  } else {
    localStorage.setItem(BUSINESS_ID, null);
  }
};

export const setUser = (userDetails) => {
  setBusinessId(userDetails.business_id || (userDetails.businesses[0] && userDetails.businesses[0].id));
  localStorage.setItem(USER_DATA, JSON.stringify(userDetails));
};

export const getUser = () => (localStorage.getItem(USER_DATA) ? JSON.parse(localStorage.getItem(USER_DATA)) : null);

export const getBusinessId = () =>
  localStorage.getItem(BUSINESS_ID) !== null && localStorage.getItem(BUSINESS_ID) !== "null"
    ? Number(localStorage.getItem(BUSINESS_ID))
    : null;

/**
 *
 * @param {type could be (U - Login User ,  I - Business )} type
 */
export const getActionByLogin = (type) => {
  if (type === "U") {
    return { id: getUser() ? getUser().id : null, type: "person" };
  }
  if (type === "B") {
    return {
      id: getBusinessId(),
      type: "business",
    };
  }
  if (type === "E") {
    return {
      id: getUser() && getUser().businesses[0] && parseInt(getUser().businesses[0].id),
      type: "employee",
    };
  }
  return null;
};

export const isUserAdmin = getUser() && getUser().permissions[0] && getUser().permissions[0].role_code === "ADMIN";
export const getUserRole = getUser() && getUser().permissions[0] && getUser().permissions[0].role_code;

export const isUserData = () => {
  if (JSON.parse(localStorage.getItem(USER_DATA))) {
    return true;
  }
  return false;
};

export const setSelectedBusinessId = (ID) => {
  if (ID !== "null" && ID !== null && ID !== undefined) {
    setBusinessId(ID);
    localStorage.setItem(SELECTED_BUSINESS, ID);
  } else {
    localStorage.setItem(SELECTED_BUSINESS, null);
  }
};

export const getSelectedBusinessId = () => {
  const ID =
    localStorage.getItem(SELECTED_BUSINESS) !== null &&
    localStorage.getItem(SELECTED_BUSINESS) !== "null" &&
    localStorage.getItem(SELECTED_BUSINESS) !== undefined
      ? Number(localStorage.getItem(SELECTED_BUSINESS))
      : null;
  return ID;
};

export const getSelectedRoleByBusinessId = (ID) => {
  let ROLE = null;
  if (isUserData) {
    const USER_DATA = getUser() ? getUser().businesses : null;
    if (USER_DATA) {
      ROLE = USER_DATA.find((item) => item.id === ID);
    }
  }
  return ROLE;
};

export const openNotification = () => {
  const btn = (
    <Button type="primary" size="small">
      ok
    </Button>
  );
  notification.open({
    message: "Please Login",
    description: "You need to login to doing any action",
    btn,
  });
};
