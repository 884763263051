import { DOMAIN } from "../../utils/api_config";
import Helmet from "react-helmet";
import React from "react";
import { withGlobalSettingsWrapper } from "./GlobalSettingsWrapper";

const HeaderHelmet = ({ title, ...props }) => {
  const displayTitle = title
    ? `${props.themeItems?.companyAppName} - ${title}`
    : `${props.themeItems?.companyAppName} - Channel partners collaboration & training APP`;

  return (
    <Helmet>
      <title>{displayTitle}</title>
      <meta name="og:title" content={displayTitle} />
      <meta name="og:description" content="One-stop solution for all your brand needs." />
      <meta
        name="og:image"
        content={props.themeItems?.themesSet ? DOMAIN + props.themeItems?.appImage : props.themeItems?.appImage}
      />
    </Helmet>
  );
};

export default withGlobalSettingsWrapper(HeaderHelmet);
