import { Card, Col, List, Row, Typography } from "antd";

import React from "react";

const { Title, Text } = Typography;

export default class AboutCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  render() {
    console.log(this.props.DETAILS);
    return (
      <>
        <Card title={this.props.DETAILS ? <Title level={5}>{this.props.DETAILS.title}</Title> : ""}>
          <Row gutter={12}>
            <Col span={12}>
              <List
                dataSource={[this.props.DETAILS]}
                renderItem={(item, i) => (
                  <List.Item key={i}>
                    <List.Item.Meta title={item.textOne} description={item.textTwo || "-"} />
                  </List.Item>
                )}
              />
            </Col>
            <Col span={12}>
              <List
                dataSource={[this.props.DETAILS]}
                renderItem={(item, i) => (
                  <List.Item key={i}>
                    <List.Item.Meta title={item.textThree} description={item.textFour || "-"} />
                  </List.Item>
                )}
              />
            </Col>
          </Row>

          <Row gutter={12}>
            {this.props.DETAILS.textFive && (
              <Col span={12}>
                <List
                  dataSource={[this.props.DETAILS]}
                  renderItem={(item, i) => (
                    <List.Item key={i}>
                      <List.Item.Meta title={item.textFive} description={item.textSix || "-"} />
                    </List.Item>
                  )}
                />
              </Col>
            )}
            {this.props.DETAILS.textSeven && (
              <Col span={12}>
                <List
                  dataSource={[this.props.DETAILS]}
                  renderItem={(item, i) => (
                    <List.Item key={i}>
                      <List.Item.Meta title={item.textSeven} description={item.textEight || "-"} />
                    </List.Item>
                  )}
                />
              </Col>
            )}
          </Row>

          <Row gutter={12}>
            <Col span={24}>
              <Text>{this.props.DETAILS ? this.props.DETAILS.textNine : "-"}</Text>
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}
