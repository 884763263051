import ApiConfig, { BASIC_URL } from "../utils/api_config";

import NetworkUtil from "../utils/network_util";
import { SERVICE_TYPE } from "../utils/enum_config";
import { getBusinessId } from "../utils/auth";

const networkUtil = new NetworkUtil();
const apiConfig = new ApiConfig();

class CompanyApi {
  async getCustomUiApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.customUiUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async basicDetails() {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.businessBasicDetails, { business_id: getBusinessId() }, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async mediumsList() {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.mediumsUrl, { business_id: getBusinessId() }, false);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async contactDetails(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.businessContactDetails, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async hourDetails() {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.businessHourDetails, { business_id: getBusinessId() }, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async locationDetails(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.businessLocationDetails, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getFilteredLocationApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(
        apiConfig.getFilteredLocationUrl,
        { business_id: getBusinessId(), ...REQUEST },
        true
      );
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async basicRegistration(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.registerBusinessBasic, data, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async contactRegistration(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.registerBusinessContact, data, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async locationRegistration(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.registerBusinessLocation, data, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async hourRegistration(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.registerBusinessHour, data, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async basicEdit(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.editBusinessBasic, data, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async locationEdit(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.editBusinessLocation, data, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async contactEdit(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.editBusinessContact, data, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async hourEdit(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.editBusinessHour, data, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async personaliseRegistration(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.registerBusinessPersonalise, data, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async businessMission(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.businessMission, data, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async businessHistory(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.businessHistory, data, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async addLessonApi(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.addLessonUrl, data, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async addBusinessAuthority(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.addBusinessAuthority, data, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async principalDetail(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.businessPrincipalDetail, data, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async editBusinessAuthority(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.editBusinessAuthority, data, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async mediumRegister(data) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.businessMediumRegister, data, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  // mediumDetail
  async mediumDetail() {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.mediumDetail, { business_id: getBusinessId() }, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  /**
   * Shah-Waz
   */

  async createBusinessCourse(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createBusinessCourseUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateBusinessCourse(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateBusinessCourseUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getBusinessCourseList(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getBusinessCourseList, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getCourseNameMasterApi() {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getCourseNameMasterUrl, {}, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getProgramNameApi() {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getProgramNameUrl, {}, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getStatusListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getStatusListApi, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getCourseTypeMasterApi() {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getCourseTypeMasterUrl, {}, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getDepartmentMasterApi() {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getDepartmentMasterUrl, {}, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getSubjectMasterApi() {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getSubjectMasterUrl, {}, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getClassMasterApi() {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getClassMasterUrl, {}, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getCertificationMasterApi() {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getCertificationMasterUrl, {}, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getGuruInchargeMasterListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getGuruInchargeMasterListApi, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getMediumOfEducationMasterApi() {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getMediumOfEducationMasterUrl, {}, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getBusinessClassListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getBusinessClassListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getBusinessCourseSubjectListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getBusinessCourseSubjectListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getClassTypeApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getClassTypeUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getCourseListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getCourseListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async createBusinessClassApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createBusinessClassUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateBusinessClassApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateBusinessClassApi, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getSubjectCategoriesListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getSubjectCategoriesListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async createBusinessSubjectApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createBusinessSubjectUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateBusinessSubjectApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateBusinessSubjectUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getCourseListYearListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getCourseListYearListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getBusinessAffiliationListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getBusinessAffiliationListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getRecognitionTypeApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getRecognitionTypeUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getOrganizationTypeApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getOrganizationTypeUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async createBusinessAccreditationApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createBusinessAccreditationUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateBusinessAccreditationApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateBusinessAccreditationUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getClubsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getClubsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getClubCategoryListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getClubCategoryListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getClubSubCategroyListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getClubSubCategroyListApi, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getGuruInchargeListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getClubSubCategroyListApi, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getMemberShipFeeListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getStatusListApi, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async createBusinessClubApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createBusinessClubApi, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateBusinessClubApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateBusinessClubUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getFacilitiesTypeApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getFacilitiesTypeApi, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async createBusinessWorkFacilityApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createBusinessWorkFacilityUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getBusinessWorkFacilityApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getBusinessWorkFacilityUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateBusinessWorkFacilityApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateBusinessWorkFacilityUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getSportsListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getSportsListApi, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getSportsCategoryListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getSportsCategoryListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getSubSportsCategroyListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getSubSportsCategoryListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async createBusinessSportsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createBusinessSportsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateBusinessSportsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateBusinessSportsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getBusinessAccountSettingsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getBusinessAccountSettingsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getBusinessPrivacySettingsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getBusinessPrivacySettingsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateBusinessAccountSettingsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateBusinessAccountSettingsApi, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateBusinessPrivacySettingsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateBusinessPrivacySettingsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getOhoLanguageMasterApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getOhoLanguageMasterUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getDateFormatMasterApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getDateFormatMasterUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getCurrencyMasterApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getCurrencyMasterUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getReviewListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getReviewListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getRatingSummaryApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getRatingSummaryUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  /**
   * Rooms Module Cretae
   */
  async createRoomApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createRoomUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async searchMembersApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.searchMembersUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async addMembersApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.addMembersUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async addMembersApiV2(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.addMembersUrlV2, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getRoomListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getRoomListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getRoomDetailsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getRoomDetailUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateRoomApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateRoomUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateMemberStatusApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateMemberStatusUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateRoomModeratorApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateRoomModeratorUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getMemberListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getMemberListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getStandardList(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getStandardUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getTeamHierarchyList(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getTeamHierarchyUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async selectTeamHierarchyList(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.selectTeamHierarchyUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async addTeamHierarchyApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.addTeamHierarchyUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateEmployeePermissionApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateEmployeePermissionUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getViewProfileApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getViewProfileUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getCountsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getCountsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async actionOnApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.actionOnUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getFollowerListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getFollowerUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getFolloweringListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getFolloweringUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getDashboardDetailsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getDashboardDetailsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getDomainDetailsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getDomainDetailsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async createDomainDetailsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createDomainDetailsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateDomainDetailsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateDomainDetailsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getAbuseReportApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getAbuseReportUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateReportStatusApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateReportStatusUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getProgrammeListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getProgrammeListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getPartnerListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getPartnerListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getPartnerEmployeesApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getPartnerEmployeesUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateBusinessProgrammeApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateBusinessProgrammeUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getDepartmentListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getDepartmentListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateBusinessDepartmentApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateBusinessDepartmentUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getSubjectListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getSubjectListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateSubjectApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateSubjectUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateBusinessSectionApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateBusinessSectionUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getClassesListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getClassSectionListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getSectionListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getSectionListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getSectionStandardListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getSectionStandardListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async AppyforBusinessAdminApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.AppyforBusinessAdminUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getReferralDetailApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getReferralDetailUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async createReferralDetailApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createReferralDetailUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async scratchCardAllocateApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.scratchCardAllocateUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async openScratchCardApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.openScratchCardUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getUserRoleListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getUserRoleListApi, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  // Loyalty Settings start
  async getPartnerLoyaltyListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getPartnerLoyaltyListApi, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updatePartnerLoyaltyApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updatePartnerLoyaltyUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updatePartnerLoyaltyListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updatePartnerLoyaltyListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getLoyaltyProgramTypesApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getLoyaltyProgramTypesUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getLoyaltyRedemptionSettingsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getLoyaltyRedemptionSettingsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateLoyaltyRedemptionSettingsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateLoyaltyRedemptionSettingsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async createLoyaltyProgramApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createLoyaltyProgramUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async editLoyaltyProgramApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.editLoyaltyProgramUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getLoyaltyProgramsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getLoyaltyProgramsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async manageLoyaltyProgramApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.manageLoyaltyProgramUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  // Loyalty Settings end

  async getRechargeLedgerApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getRechargeLedgerUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  // Loyalty Settings end

  async getBalanceDetailsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getBalanceDetailsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getMinimumRechargeApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getMinimumRechargeUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getSubscriptionDetailsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getSubscriptionDetailsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getSocialMessageAmtApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getSocialMessageAmtUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getApplicationSettingsListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getApplicationSettingsListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getAccessDetailsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getAccessDetailsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async listAppAccessApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.listAppAccessUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async manageAppMainApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.manageAppMainUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async listCustomUsersApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.listCustomUsersUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async addCustomUsersApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.addCustomUsersUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateAppRoleApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateAppRoleUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async removeAppPermissionApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.removeAppPermissionUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getAcademicYearsListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getAcademicYearsListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async userRegisterApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.userRegisterUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  getUrlForImage(url, resolution, service_type) {
    let PREPARE_URL = null;
    if (url) {
      let imageName;
      if (url != null) {
        const s = url.split("/");
        imageName = s[s.length - 1];
      }
      PREPARE_URL =
        BASIC_URL +
        "/file/media/read?" +
        `name=${imageName}` +
        `&resolution=${resolution}` +
        `&service_type=${service_type}`;
    }
    return PREPARE_URL;
  }

  async getBusinessListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getBusinessListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getRatedListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getRatedListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async reviewListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.commentListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getPrivacyTypeListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getPrivacyTypeListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getRoomTopicsListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getRoomTopicsListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async createChildDetailsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.createChildDetailsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  navigateToProfile(ID, NAME, TYPE, history) {
    if (ID) {
      if (TYPE === SERVICE_TYPE.PERSON) {
        history.push(`/en-in/network/${ID}`);
      } else {
        history.push(`/company/${NAME}/profile/${ID}`);
      }
    }
  }

  async updatePartnerRoleApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updatePartnerRoleUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getPartnerManagerApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getPartnerManagerUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateAssignPartnerManagerApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateAssignPartnerManagerUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getPartnerHierarchyApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getPartnerHierarchyUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getOtherHierarchyApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getOtherHierarchyUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async changeHierarchyApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.changeHierarchyUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async addLowerPartnersApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.addLowerPartnersUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updatePartnerIdApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updatePartnerIdUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async removePartnerApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.removePartnerUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async listTeamPartnerManagers(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.listTeamPartnerManagersUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async listTeamPartners(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.listTeamPartnersUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async assignTeamPartnerApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.assignTeamPartnerUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async removeEmployeeApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.removeEmployeeUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getEmployeeIdApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getEmployeeIdUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async updateEmployeeIdApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.updateEmployeeIdUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  // Helpdesk

  async getHelpdeskSettingsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getHelpdeskSettingsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async setHelpdeskSettingsApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.setHelpdeskSettingsUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getHelpdeskAgentsListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getHelpdeskAgentsListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async getHelpdeskAgentCodeApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getHelpdeskAgentCodeUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async addHelpdeskAgentApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.addHelpdeskAgentUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async removeHelpdeskAgentApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.removeHelpdeskAgentUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  // Branches
  async getBranchListApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.getBranchListUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async registerBranchApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.registerBranchUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async editBranchApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.editBranchUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async setHeadOfficeApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.setHeadOfficeUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async setBranchHeadApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.setBranchHeadUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async manageBranchEmployeesApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.manageBranchEmployeesUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async manageBranchPartnersApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.manageBranchPartnersUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async changeEmployeeBranchApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.changeEmployeeBranchUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }

  async changePartnerBranchApi(REQUEST) {
    let result = null;
    try {
      result = await networkUtil.post(apiConfig.changePartnerBranchUrl, REQUEST, true);
    } catch (error) {
      console.error(error);
    }
    return result;
  }
}
export default CompanyApi;
