import { Button, Popconfirm, Rate, Tooltip, message } from "antd";
import { StarFilled, StarOutlined } from "@ant-design/icons";

import { EventEmitter } from "../../events";
import FeedApi from "../../apis/feed_api";
import PropTypes from "prop-types";
import { RESPONSE_STATUS_CODES } from "../../utils/enum_config";
import React from "react";
import { getActionByLogin } from "../../utils/auth";
import { withGlobalSettingsWrapper } from "../common/GlobalSettingsWrapper";

const feedApi = new FeedApi();

class RatingCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: null,
      IS_BASIC_UI: this.props.IS_BASIC_UI ? this.props.IS_BASIC_UI : false,
      NOTE_ID: this.props.NOTE_ID,
      SUBJECT_ID: this.props.SUBJECT_ID,
      SUBJECT_TYPE: this.props.SUBJECT_TYPE,
      CONTEXT_ID: this.props.CONTEXT_ID,
      CONTEXT_TYPE: this.props.CONTEXT_TYPE,
      ACTION_BY_ID: this.props.ACTION_BY_ID,
      ACTION_BY_TYPE: this.props.ACTION_BY_TYPE,
      DEFAULT_RATING: this.props.DEFAULT_RATING,
      IS_RATING_STATUS: this.props.IS_RATING_STATUS,
    };
  }

  ratingChange = (value) => {
    this.setState({ rating: value });
    if (this.state.IS_BASIC_UI) {
      this.createRating(value);
    }
  };

  confirm = () => {
    if (this.state.rating) {
      this.createRating(this.state.rating);
    }
    if (this.state.SUBJECT_TYPE === "post") {
      EventEmitter.dispatch("refreshFeed", { id: this.state.SUBJECT_ID });
    }
  };

  createRating = async (RATE) => {
    const REQUEST = {
      rating_note_id: this.state.NOTE_ID ? this.state.NOTE_ID : null,
      rating_subject_type: this.state.SUBJECT_TYPE ? this.state.SUBJECT_TYPE : null,
      rating_subject_id: this.state.SUBJECT_ID ? this.state.SUBJECT_ID : null,
      rating_context_type: this.state.CONTEXT_TYPE ? this.state.CONTEXT_TYPE : null,
      rating_context_id: this.state.CONTEXT_ID ? this.state.CONTEXT_ID : null,
      rating_given_by_id: getActionByLogin("U").id,
      rating_given: RATE,
    };
    const response = await feedApi.createRatingApi(REQUEST);
    if (response) {
      if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
        if (!this.state.IS_RATING_STATUS) {
          this.props.onSubmitRating(true);
        } else {
          this.props.onSubmitRating(false);
        }
        this.setState({
          IS_RATING_STATUS: true,
        });
      } else {
        message.error(response.message);
      }
    }
  };

  cancel() {
    console.log("Cancel");
  }

  render() {
    return (
      <>
        {this.state.IS_BASIC_UI ? (
          <Rate onChange={this.ratingChange} defaultValue={this.state.DEFAULT_RATING} />
        ) : (
          <Popconfirm
            title={<Rate onChange={this.ratingChange} />}
            icon={null}
            onConfirm={this.confirm}
            onCancel={this.cancel}
            okText="Submit"
            cancelText="Cancel"
          >
            <Tooltip title="Rate">
              {this.state.DEFAULT_RATING ? (
                <span key="comment-nested-reply-to">{this.state.DEFAULT_RATING}</span>
              ) : (
                <></>
              )}
              <Button
                type="text"
                shape="circle"
                icon={
                  this.state.IS_RATING_STATUS ? (
                    <StarFilled style={{ color: this.props.themeItems.themeColors.primary }} />
                  ) : (
                    <StarOutlined />
                  )
                }
              />
            </Tooltip>
          </Popconfirm>
        )}
      </>
    );
  }
}

RatingCard.protoTypes = {
  NOTE_ID: PropTypes.any,
  SUBJECT_ID: PropTypes.any,
  SUBJECT_TYPE: PropTypes.any,
  CONTEXT_ID: PropTypes.any,
  CONTEXT_TYPE: PropTypes.any,
  ACTION_BY_ID: PropTypes.any,
  ACTION_BY_TYPE: PropTypes.any,
  DEFAULT_RATING: PropTypes.any,
  IS_RATING_STATUS: PropTypes.any,
};

export default withGlobalSettingsWrapper(RatingCard);
