import { Button, Card, Drawer, List, Typography } from "antd";
import { EditOutlined, PlusCircleFilled } from "@ant-design/icons";

import EducationForm from "../../components/network/education-form";
import { RESPONSE_STATUS_CODES } from "../../utils/enum_config";
import React from "react";
import UserApi from "../../apis/user_api";
import { getActionByLogin } from "../../utils/auth";
import { withGlobalSettingsWrapper } from "../common/GlobalSettingsWrapper";

const userApi = new UserApi();

const { Paragraph } = Typography;

const { Title } = Typography;

class Educationcard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      visibleAdd: false,
      visibleSeeMoreList: false,
      editData: null,
    };
  }

  showDrawerEdit = (element) => {
    if (element) {
      this.setState({
        visibleAdd: true,
        editData: this.prepareEdit(element),
      });
    } else {
      this.setState({
        visibleAdd: true,
      });
    }
  };

  prepareEdit(ITEM) {
    const REQUEST = {
      id: ITEM.id,
      business_id: ITEM.textSeven,
      business_name: ITEM.textThree,
      is_current: ITEM.is_current,
      start_date: ITEM.textFive,
      end_date: ITEM.textSix,
      class_name: ITEM.textOne,
      grade: ITEM.textEleven,
      description: ITEM.textTewlve,
      person_id: ITEM.textEight,
      add_type: "education",
      industry_type: null,
      class_id: null,
      location: null,
      employment_type: null,
      school_name: null,
      field_of_study: null,
      designation: null,
    };
    return REQUEST;
  }

  onClose = () => {
    this.setState({
      visibleAdd: false,
    });
    window.location.reload();
  };

  showSeeMore = () => {
    this.setState({
      visibleSeeMoreList: true,
    });
    this.getSeeMoreList(this.props.VIEWID, this.props.VIEWTYPE, this.props.DETAILS.textSeven, 1, 100);
  };

  onSeeMoreClose = () => {
    this.setState({
      visibleSeeMoreList: false,
    });
  };

  getSeeMoreList = async (ACTIONBYID, ACTIONTYPE, INST_ID, SKIP, OFFSET) => {
    this.setState({
      loading: true,
    });
    const REQUEST = {
      person_id: ACTIONBYID,
      type: ACTIONTYPE,
      all_businesss_id: INST_ID,
      given_by_id: ACTIONBYID,
      page_number: SKIP,
      page_size: OFFSET,
    };
    const response = await userApi.getEducationSeeMoreApi(REQUEST);
    if (response) {
      if (response.statusCode === RESPONSE_STATUS_CODES.SUCCESS_CODE) {
        this.setState({
          data: response.rows,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    }
  };

  getList = (DATA) => {
    if (DATA) {
      return (
        <List
          itemLayout="horizontal"
          loading={this.state.loading}
          dataSource={DATA}
          renderItem={(item) => (
            <List.Item
              actions={
                [
                  // Number(this.props.VIEWID) === getActionByLogin('U').id ? <EditOutlined onClick={() => this.showDrawerEdit(item)} /> : <></>
                ]
              }
            >
              <List.Item.Meta title={item.textOne} description={item.textTwo} />
              <div>{item.textThree}</div>
            </List.Item>
          )}
        />
      );
    } else {
      return <></>;
    }
  };

  getActionOnCard() {
    if (Number(this.props.VIEWID) === getActionByLogin("U").id) {
      return [
        <Button type="text" key="seeMoreBtn" onClick={() => this.showSeeMore()}>
          See More
        </Button>,
      ];
      // <Button type="text" onClick={() => this.showDrawerEdit(null)} icon={<PlusCircleFilled style={{ fontSize: 20, color: this.props.themeItems.themeColors.btnBlue }} />} />
    } else {
      if (this.props.DETAILS.isShowMore) {
        return [
          <Button type="text" key="detailsSeeMoreBtn" onClick={() => this.showSeeMore()}>
            See More
          </Button>,
        ];
      } else {
        return null;
      }
    }
  }

  render() {
    return (
      <>
        <Card
          title={this.props.DETAILS ? <Title level={5}>{this.props.DETAILS.title}</Title> : ""}
          extra={this.getActionOnCard()}
        >
          <Title level={5}>{this.props.DETAILS ? this.props.DETAILS.textOne : "-"}</Title>
          <Paragraph style={{ lineHeight: "10px" }}>{this.props.DETAILS ? this.props.DETAILS.textTwo : ""}</Paragraph>
          <Title style={{ lineHeight: "10px" }} level={5}>
            {this.props.DETAILS ? this.props.DETAILS.textThree : ""}
          </Title>
          <Paragraph>{this.props.DETAILS ? this.props.DETAILS.textFour : "-"}</Paragraph>
          <br />
        </Card>

        <Drawer
          title="Add/Edit education"
          width={720}
          onClose={this.onClose}
          visible={this.state.visibleAdd}
          bodyStyle={{ paddingBottom: 80 }}
          maskClosable={false}
          destroyOnClose
        >
          <EducationForm onSubmitForm={this.onClose} editData={this.state.editData} />
        </Drawer>

        <Drawer
          title="Education"
          width={720}
          onClose={this.onSeeMoreClose}
          visible={this.state.visibleSeeMoreList}
          bodyStyle={{ paddingBottom: 80 }}
          maskClosable={false}
          destroyOnClose
        >
          {this.getList(this.state.data)}
        </Drawer>
      </>
    );
  }
}
export default withGlobalSettingsWrapper(Educationcard);
